<div>
  <div class="form-header">
    <fa-icon
      [icon]="icons.user"
      class="icon-user"
      size="5x"
    >
    </fa-icon>

    <fa-icon
      [icon]="icons.timesCircle"
      class="icon-close"
      size="2x"
      (click)="close()"
    >
    </fa-icon>
  </div>
  <div class="form-content" *ngIf="form">
    <form method="post" [formGroup]="form" (submit)="onSubmit()">   
      <div class="form-content-input">
        <span class="form-content-span">
          Nome
        </span>
        <input
          id="fullname"
          name="Nome"
          class="form-control"
          formControlName="fullname"
        >
      </div>

      <div class="form-content-input-alert">
        <p *ngIf="form.get('fullname')?.errors && form.get('fullname')?.touched">
          Nome é obrigatório
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Usuário
        </span>
        <input
          id="username"
          name="Nome"
          class="form-control"
          formControlName="username"
        >
      </div>

      <div class="form-content-input-alert">
        <p *ngIf="form.get('username')?.errors && form.get('username')?.touched">
          Username é obrigatório
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">
          Email
        </span>
        <input
          id="email"
          name="email"
          class="form-control"
          formControlName="email"
        >
      </div>

      <div class="form-content-input-alert">
        <p *ngIf="form.get('email')?.errors && form.get('email')?.touched">
          Email é obrigatório
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">Estado</span>
        <ng-select
          [items]="stateOptions"
          bindLabel="name"
          bindValue="value"
          [multiple]="false"
          formControlName="state"
        >
        </ng-select>  
      </div>
      <div class="form-content-input-alert">
        <p *ngIf="form.get('state')?.errors && form.get('state')?.touched">
          <i class="fa fa-times-circle"></i> Escolha um estado
        </p>
      </div>

      <div class="form-content-input">
        <span class="form-content-span">Permissões</span>
        <ng-select
          [items]="permissionOptions"
          bindLabel="name"
          bindValue="value"
          [multiple]="true"
          formControlName="permissions">
        </ng-select>  
      </div>

      <div class="form-content-input-alert">
        <p *ngIf="form.get('permissions')?.errors && form.get('permissions')?.touched">
          Escolha pelo menos uma permissão
        </p>
      </div>
   
      <div class="form-content-submit">
        <button 
          type="submit"
          class="btn btn-primary mx-auto col-lg-5 col-md-5 col-5"
          [disabled]="!form.valid"
        >
          Salvar
        </button>
      </div>
    </form>
  </div>
</div>