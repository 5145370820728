import { 
  ChangeDetectorRef,
  Component, 
  OnInit, 
  ViewChild,
  ViewContainerRef, 
  ComponentFactoryResolver,
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VandalismAnalytical } from 'src/app/interfaces';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { AnalyticalService } from 'src/app/services/analytical.service';
import { GeneralVisionComponent } from './components/general-vision/general-vision.component';
import { YearToDateVisionComponent } from './components/year-to-date-vision/year-to-date-vision.component';
import { RegionalComparisonVisionComponent } from './components/regional-comparison-vision/regional-comparison-vision.component';
import { HistogramVisionComponent } from './components/histogram-vision/histogram-vision.component';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { icons } from 'src/app/utils/icons';
import { mapCauseOption } from 'src/app/utils/mappers/causeOptions';
import { CauseOption } from 'src/app/interfaces/enums/CauseOption';
import { getDateUtils, getDefaultFilters } from 'src/app/utils/interfaces/constants';

const { currentYear, lastYear } = getDateUtils();

export const defaultActiveFilters = getDefaultFilters({
  year: `${lastYear},${currentYear}`,
  causeGroup: `${CauseOption.VANDALISMO}`
});

@Component({
  selector: 'app-analitico',
  templateUrl: './analitico.component.html',
  styleUrls: [],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible'
      })),
      state('out', style({
        'max-height': '0',
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('in => out', [animate('800ms ease-in-out')]),
      transition('out => in', [animate('800ms ease-in-out')])
    ])
  ]
})
export class AnaliticoComponent implements OnInit {

  private static FULLSCREEN_URL: string = '/vandalismo/mapa'

  NAMESPACE: Namespace = 'analytical';
  @ViewChild('container', { read: ViewContainerRef, static: false }) container: ViewContainerRef;

  private subscriptions = new Subscription();  
  private resizeObserver: ResizeObserver;
  public currentRoute: string;
  currentComponent: string = '';

  data: BehaviorSubject<VandalismAnalytical.Output> = new BehaviorSubject(new VandalismAnalytical.Output());
  undoDisabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  activeFilters: VandalismAnalytical.InputParams = defaultActiveFilters
  historyActiveFilters: Array<VandalismAnalytical.InputParams> = [];
 
  isDataLoaded = false;
  isDivVisible = false;
  isAnalytic = true;

  isMobile: boolean;
  heightImg = 70;  
  headerPage: number;  
  heightPage: number;
  heightContainer: number;
  heightRows: number;
  heightCard: number;
  heightCardFull: number;
  icons=icons

  filterValues = VandalismAnalytical;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,    
    private filterService: FilterService,
    private analyticalService: AnalyticalService,
    private router: Router
  ) {

    this.subscriptions.add(this.data);
    this.subscriptions.add(this.undoDisabled);

    this.filterService.setDefaultFilters<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((af) => this.updateData(af));
  }

  ngOnInit(): void {}
  
  ngAfterViewInit() { 
    this.loading();

    const isLoadedSub = this.analyticalService
      .isLoaded
      .asObservable()
      .subscribe((isLoaded) => {
        this.isDataLoaded = isLoaded;
        this.updateData(this.activeFilters);
        if (isLoaded) {
          if (this.currentComponent === 'loading') {
            this.generalVision();
          }
        }
    });

    this.subscriptions.add(isLoadedSub);
  }

  updateContainerHeight(): void{   
    this.heightContainer = this.heightPage - this.heightImg;
    this.heightRows = this.heightContainer / 2;
    const marginCards = this.heightRows * 0.10;
    this.heightCard = this.heightRows - marginCards;
    this.heightCardFull = this.heightContainer - marginCards;
  }

  updateDimensions(): void{
    const pageHeder = 60;
    const spacing = 30;
    this.heightPage = document.documentElement.clientHeight - (pageHeder + spacing);       
  }

  openFullscreen() {
    this.router.navigate([AnaliticoComponent.FULLSCREEN_URL])
  }

  updateData(ac: VandalismAnalytical.InputParams) {
    this.activeFilters = ac;

    const filteredTickets = this.analyticalService.filterTickets(this.activeFilters);
    const { dropdownList, selectedList } = this.analyticalService.getFilters(this.activeFilters, filteredTickets);
    
    this.data.next(new VandalismAnalytical.Output({
      activeFilters: this.activeFilters,
      dropdownList: dropdownList,
      selectedList: selectedList,
      tickets: [],
      peads: []
    }));
  }

  loading() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
    this.container.clear();
    this.container.createComponent(componentFactory);
    this.currentComponent = 'loading';
    this.changeDetectorRef.detectChanges();
  }

  generalVision() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GeneralVisionComponent);
    this.container.clear();
    const componentRef = this.container.createComponent(componentFactory);
    componentRef.instance.onChange = this.onChange.bind(this);
    this.currentComponent = 'general';
    this.changeDetectorRef.detectChanges(); 
  }

  yearToDateVision() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(YearToDateVisionComponent);
    this.container.clear();
    const componentRef = this.container.createComponent(componentFactory);
    componentRef.instance.onChange = this.onChange.bind(this);
    this.currentComponent = 'year-to-date';
    this.changeDetectorRef.detectChanges();
  }

  regionalComparisonVision() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RegionalComparisonVisionComponent);
    this.container.clear();
    const componentRef = this.container.createComponent(componentFactory);
    componentRef.instance.onChange = this.onChange.bind(this);
    this.currentComponent = 'regional-comparison';
    this.changeDetectorRef.detectChanges();
  }

  histogramVision() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(HistogramVisionComponent);
    this.container.clear();
    const componentRef = this.container.createComponent(componentFactory);
    componentRef.instance.onChange = this.onChange.bind(this);
    this.currentComponent = 'histogram';
    this.changeDetectorRef.detectChanges();
  }

  onReset() {
    this.filterService.updateMultipleFilters({
      namespace: this.NAMESPACE,
      newFilters: defaultActiveFilters,
    });
  }

  onUndo() {
    const prevValue = this.historyActiveFilters.pop();
    if (prevValue !== undefined) {
      this.filterService.updateMultipleFilters<VandalismAnalytical.InputParams>({
        namespace: this.NAMESPACE,
        newFilters: prevValue
      });
      const isLastElement: boolean = this.historyActiveFilters.length === 0;
      this.undoDisabled.next(isLastElement);
    }
  }

  onVandalism() {
    this.onChange('causeGroup:VANDALISMO');
  }

  openHeaderFilter() {
    this.isDivVisible = !this.isDivVisible;    
  }

  ngOnDestroy() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
    this.filterService.clearNamespace({ namespace: this.NAMESPACE });
    this.currentComponent = '';
    this.subscriptions.unsubscribe();
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    this.historyActiveFilters.push(this.activeFilters);
    const isLastElement: boolean = this.historyActiveFilters.length === 0;
    this.undoDisabled.next(isLastElement);
    this.filterService.updateFilters<VandalismAnalytical.InputParams>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }
}
