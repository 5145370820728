import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  saveEncrypted(key: string, data: any, encryptionKey: string) {
    const jsonData = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(jsonData, encryptionKey).toString();
    localStorage.setItem(key, encryptedData);
  }

  loadEncrypted(key: string, encryptionKey: string): any {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    }
    return null;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
