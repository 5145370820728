import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { icons } from 'src/app/utils/icons';

@Component({
  selector: 'app-create-password-error-pop-up',
  templateUrl: './create-password-error-pop-up.component.html',
  styleUrls: []
})
export class CreatePasswordErrorPopUpComponent implements OnInit {

  title: string;
  faClosedCaptioning = icons.closedCaptioning;
  
  constructor(public activeModal: NgbActiveModal) { }

  // Função para fechar o modal com um resultado
  close(result: any) {
    this.activeModal.close(result);
  }

  ngOnInit(): void {
  }
}
