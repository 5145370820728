import { Component, Input, OnInit } from '@angular/core';
import { INotification } from 'src/app/interfaces';

@Component({
  selector: 'app-timeline-default',
  templateUrl: './timeline-default.component.html',
  styleUrls: []
})
export class TimelineDefaultComponent implements OnInit {

  @Input() title: string;
  @Input() notifications: INotification[];

  constructor() { }

  ngOnInit(): void {
  }

}
