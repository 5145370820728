<div class="card-chart ">
  <div class="card-chart-header">
    <app-title-default [content]="title">
    </app-title-default>
  </div>

  <div class="card-chart-body table-responsive">
    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center text-nowrap">Ponto</th>
          <th class="text-center text-nowrap">Endereço</th>
          <th class="text-center text-nowrap">Horário Sugerido</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let route of data">
          <td class="text-center">{{ generateLetterPoint(route.point) }}</td>
          <td class="text-center text-nowrap">{{ route.address }}</td>
          <td class="text-center">{{ route.hours }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
