<agm-map
  style="height: 100%;"
  [latitude]="mapConfig.center.lat"
  [longitude]="mapConfig.center.lng"
  [zoom]="mapConfig.zoom"
  [disableDefaultUI]="false"
  [zoomControl]="mapConfig.zoomControl"
  [streetViewControl]="mapConfig.streetViewControl"
  [fullscreenControl]="mapConfig.fullscreenControl"
  (mapReady)="onMapReady($event)"
>
  <agm-marker
    *ngFor="let m of markers; let i = index"
    [latitude]="m.lat"
    [longitude]="m.lng"
    [label]="m.label"
    [iconUrl]="mapConfig.iconUrl"
    (dragEnd)="markerDragEnd(m, $event)"
    (markerClick)="options.withPopup ? openTicketPopUp(m) : null"
    [title]="options.withPopup ? treatTooltip(m) : null"
    >
  </agm-marker>
</agm-map>
