<div class="container-ticket-pop-up">
  <div class="popup-header">
    <div class="popup-title">
      <!-- INCIDENT ID -->
      <h2>Incidente {{ treatLegacyTicket(modalData, 'incidentId') }}</h2>
    </div>
    <div class="popup-btn">
      <button type="button" class="btn-close-default" (click)="closeModal()">
        <span class="fa fa-times-circle"></span>
      </button>
    </div>
  </div>

  <div class="popup-body">

    <!--  ROWS INFO TICKET -->
    <div  class="container-row-pop-up">
      <div *ngFor="let data of displayData">
        <app-ticket-pop-up-row
          [label]="data.label"
          [value]="data.value"
        >
        </app-ticket-pop-up-row>
      </div>      
    </div>

    <div class="container-carousel" *ngIf="!loading">
      <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig3">
        <div ngxSlickItem *ngFor="let photo of photos; let i = index">

          <!-- CONTAINER BOTÕES DE VALIDAÇÃO -->
          <div class="validation-container">            
            <div class="validation-buttons" *ngIf="isAdmin()">
              <input 
                type="radio"
                id="sharp-{{i}}"
                name="validation-{{i}}"
                (change)="sendPhotoManualReport(i, true)"
                [(ngModel)]="sharpnessValidations[i]"
                [value]="true"
                [checked]="sharpnessValidations[i] === true"
              >
              <label for="sharp-{{i}}" class="sharp-label">Nítida</label>

              <input 
                type="radio"
                id="not-sharp-{{i}}"
                name="validation-{{i}}"
                (change)="sendPhotoManualReport(i, false)"
                [(ngModel)]="sharpnessValidations[i]"
                [value]="false"
                [checked]="sharpnessValidations[i] === false"
              >
              <label for="not-sharp-{{i}}" class="not-sharp-label">Não Nítida</label>
            </div>
          </div>

          <div class="img-popup">
            <img 
              [src]="photo" 
              (click)="openZoomImageModal(photo)" 
              alt="incident"
              class="carousel-image" 
            />
          </div>
        </div>
      </ngx-slick-carousel>
    </div>    
  </div>

  <div class="popup-image col-10 col-xxl-10 m-auto">
    <div *ngIf="loading" class="loading-container">
      <div class="loader">
        <div class="ball-pulse">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    
    
  </div>
  <div>
    <button
      (click)="generatePdf()"
      class="popup-generate-pdf"
      [disabled]="!(this.photos.length === 5)"
    >Gerar PDF
    </button>
  </div>
</div>
