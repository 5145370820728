import { ControlPosition } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITicket } from 'src/app/interfaces';
import { FullscreenMapComponent } from '../fullscreen-map/fullscreen-map.component';
import { TicketPopUpComponent } from '../ticket-pop-up/ticket-pop-up.component';
import { FilterPopUpComponent } from '../../components/filter-pop-up/filter-pop-up.component';
import { Formatter } from 'src/app/utils/formatter.util';
import { Namespace } from 'src/app/services/filter.service';

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: []
})
export class HeatMapComponent implements OnInit {

  @Input() data = { title: "MAPA DE CALOR POR ABERTURA DE CHAMADO" }
  @Input() markers: ITicket[] = [];
  @Input() namespace: Namespace;

  currentMarker: any;

  @Input() options = {
    withPopup: true,
    withFilters: true
  }

  mapConfig = {
    zoom: 4,
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    iconUrl: '/assets/maps/red-circle.svg',
    center: {
      lat: -15.8517,
      lng: -48.5799
    }
  }

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {}

  openFullScreenModal() {
    const modalRef = this.modalService.open(FullscreenMapComponent);
    modalRef.componentInstance.markers = this.markers;
    modalRef.componentInstance.options = this.options;
    modalRef.componentInstance.namespace = this.namespace;
  }

  openTicketPopUp(ticket: ITicket) {
    const modalRef = this.modalService.open(TicketPopUpComponent, { size: 'lg' });
    modalRef.componentInstance.modalData = ticket;
  }

  openFiltersPopUp() {
    this.modalService.open(FilterPopUpComponent, { size: 'lg' });
  }

  treatTooltip(marker: ITicket) {
    const id = marker.ticketId;
    const date = Formatter.formatReportedDate(marker.reportedDate);
    const operation = marker.operational ?? ' ';
    return `ID: ${id}\nData: ${date}z\nOperação: ${operation}`
  }

  createCustomFullScreen() {
    const svgImage = document.createElement("img");
    svgImage.src = "assets/maps/fullscreen.svg";
    svgImage.width = 22;
    svgImage.height = 22;

    const controlButton = document.createElement("button");
    controlButton.type = "button"
    controlButton.title = "Ativar o fullscreen do mapa";
    controlButton.setAttribute('class', 'control-button');
    controlButton.appendChild(svgImage);
    controlButton.addEventListener('click', () => this.openFullScreenModal());

    return controlButton;
  }

  onMapReady(map: { controls: HTMLDivElement[][]; }) {
    const customControlDiv = document.createElement('div');
    const customFullScreenControl = this.createCustomFullScreen();
    customControlDiv.appendChild(customFullScreenControl);
    map.controls[ControlPosition.TOP_RIGHT].push(customControlDiv);
  }
}
