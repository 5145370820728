<div>
  <!-- * HEADER FILTER PAGE -->
  <div     
    class="container-header-page"
    [class.bg-transition]="isDivVisible"
    #headerFilter
  >

    <div class="header-img-filter col-2">
      <img         
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()"
        alt="Logo da claro"
      >    
      <div 
        class="buttons-visions row m-1"
        [style.display]="isDivVisible ? 'flex' : 'none'"        
      >

        <button
          (click)="generalVision()"
          [ngClass]="{'btn-actives': currentComponent === 'general' }"                          
          class="btn-vision-analytics"
        >
          Visão Geral
        </button>

        <button
          (click)="yearToDateVision()"
          [ngClass]="{'btn-actives': currentComponent === 'year-to-date' }"                          
          class="btn-vision-analytics"
        >
          Year-to-date
        </button>

        <button
          (click)="regionalComparisonVision()"  
          [ngClass]="{'btn-actives': currentComponent === 'regional-comparison'}"                          
          class="btn-vision-analytics"
        >
          Comparativo Reg
        </button>

        <button
          (click)="histogramVision()"
          [ngClass]="{'btn-actives': currentComponent === 'histogram' }"                          
          class="btn-vision-analytics"
        >
          Histograma
        </button>
      </div>   
    </div>

    <div
      class="container-header-filters col-10"
      [@slideInOut]="isDivVisible ? 'in' : 'out'"
    >
      <div class="container-filters col-12 p-0" style="height: 17vh">
        <app-filter-default
          key="regional"
          filterTitle="Regional"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.regional"
          [selectedList]="data.value.selectedList.value.regional"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="group"
          filterTitle="Grupo"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.group"
          [selectedList]="data.value.selectedList.value.group"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="state"
            title="Estados"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.state"
            [selectedItems]="data.value.selectedList.value.state"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <app-select-default 
            key="city"
            title="Município"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.city"
            [selectedItems]="data.value.selectedList.value.city"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="cluster"
            title="Cluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.cluster"
            [selectedItems]="data.value.selectedList.value.cluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
            >
          </app-select-default>

          <app-select-default 
            key="causeGroup"
            title="Causa"
            [searchFilter]="true"      
            [isSinglePermanent]="true"
            [dropdownList]="data.value.dropdownList.causeGroup"
            [selectedItems]="data.value.selectedList.value.causeGroup"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="subcluster"
            title="Subcluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.subcluster"
            [selectedItems]="data.value.selectedList.value.subcluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <div style="display: flex; align-items: center; margin-top: 3vh;">
            <button class="btn-action-filter" (click)="onReset()" title="Resetar seleção">
              <fa-icon [icon]="icons.trash"></fa-icon>
            </button>
            <button class="btn-action-filter" [disabled]="undoDisabled.value" (click)="onUndo()" title="Voltar seleção">
              <fa-icon [icon]="icons.undo"></fa-icon>
            </button>
            <button class="btn-action-filter" (click)="onVandalism()" title="Filtrar por vandalismo">
              <fa-icon [icon]="icons.exclamationTriangle"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <!-- *CONTAINER TOP HEADER -->

      <div class="container-filters col-12 p-0" style="height: 15vh"> 
        <app-filter-default 
          key="year"
          filterTitle="ano"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.year"
          [selectedList]="data.value.selectedList.value.year"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="month"
          filterTitle="mês"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.month"
          [selectedList]="data.value.selectedList.value.month"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default 
          key="family"
          filterTitle="família"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.family"
          [selectedList]="data.value.selectedList.value.family"
          (event)="onChange($event)"
        >
        </app-filter-default>
        
        <app-filter-default 
          key="net"
          filterTitle="rede"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.net"
          [selectedList]="data.value.selectedList.value.net"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <div class="btn-full-screen">
          <button (click)="openFullscreen()">
            <img
              src="../../../../assets/images/brasil-mini-map.png"
              alt=""
              style="height: 8vh"
            >
          </button>
        </div>
      </div>
      <!-- *CONTAINER BOTTOM HEADER -->

    </div>
  </div>
  
  <div class="row mx-auto" #container>    
  </div>
</div>