import { AbstractControl, ValidationErrors } from "@angular/forms";

// Validador de primeira letra maiúscula
export function caseValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.value;
  if (password && !/[A-Z]/.test(password)) {
    return { caseError: true };
  }
  return null;
}

// Validador de caracteres especiais
export function specialCharacterValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.value;
  if (password && !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return { specialCharacterError: true };
  }
  return null;
}

// Validador de números
export function numberValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.value;
  if (password && !/\d/.test(password)) {
    return { numberError: true };
  }
  return null;
}