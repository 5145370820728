<div class="container-filter">
  <div class="content-filter">
    <div class="filter-title">
      <span>{{ filterTitle }}</span>
      <fa-icon class="icons-filter" [icon]="icon"></fa-icon>
    </div>
    <!-- <div class="filter-icon">
      
    </div> -->
    <div class="container-button">
      <button 
        class="btn-filter"    
        *ngFor="let value of dropdownList"
        [ngClass]="{'active': isSelected(value.value)}"
        (click)="isSingle ? toggleSelectionSingle(value.value) : toggleSelection(value.value)"
      >
        {{ value.show }}
      </button>                         
    </div>
  </div>
</div>
