import { Component, Input, OnInit } from '@angular/core';
import { ITheme } from 'src/app/interfaces';
import { icons } from 'src/app/utils/icons';

@Component({
  selector: 'app-card-theme',
  templateUrl: './card-theme.component.html',
  styleUrls: []
})
export class CardThemeComponent implements OnInit {

  @Input() theme: ITheme;
  faBrush = icons.brush;
  faTrash = icons.trash;

  constructor() { }

  ngOnInit(): void {
  }
  
  updateTheme(theme: ITheme) {
    console.log('Updated theme!!!');
  }

  deleteTheme(theme: ITheme) {
    console.log('Delete theme!!!');
  }
}
