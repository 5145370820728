<div
  [ngClass]="class"
  *ngIf="isLoading; else custom"
  style="
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
  "
>
  <div class="loading-container">
    <div class="loader">
      <div class="ball-pulse">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<ng-template #custom>
  <div
    [ngClass]="class"
    *ngIf="isCustomContent; else content"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 90%;
    "
    >
    <div style="
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;"
    >
    <i
      class="fa fa-check"
      aria-hidden="true"
      style="font-size: 4em; color: black;"
    >
    </i>
    <p>{{ customContent }}</p>
    </div>
  </div>
</ng-template>
<ng-template #content>
  <div
    [ngClass]="class"
    *ngIf="isError; else childContent"
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 90%;
    "
  >
    <div style="
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;"
    >
      <i
        class="fa fa-exclamation-circle"
        aria-hidden="true"
        style="font-size: 2.5em; color: red;"
      >
      </i>
      <p style="font-size: 0.65rem;">Houve um problema ao processar a informação</p>
    </div>
  </div>
  <ng-template #childContent>
    <ng-content></ng-content>
  </ng-template>
</ng-template>
