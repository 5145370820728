import { initialAnalyticalChart } from "src/app/utils/constants";
import { AnalyticalChart } from "../models/IAnalyticalChart";

export namespace AnalyticalYearToDate {

  export type ViewScreen = 'year-to-date';

  export interface InputParams {
    years: string,
    months: string,
    days: string,
    causes: string,
    regionals: string,
    directors: string,
    states: string,
    subClusters: string,
    clusters: string,
    cities: string,
    families: string,
    nets: string
  }

  export interface Response {
    analyticalVolumeRalOutageByMonthAndYears: AnalyticalChart,
    analyticalVolumeRalOutageByMonth: AnalyticalChart,
    analyticalPercentagesRalOutageByMonth: AnalyticalChart
  }
  
  export class Output {
    
    public analyticalVolumeRalOutageByMonthAndYears = initialAnalyticalChart
    public analyticalVolumeRalOutageByMonth = initialAnalyticalChart
    public analyticalPercentagesRalOutageByMonth = initialAnalyticalChart
  
    constructor(response?: Response) {
      if (response) {
        this.analyticalVolumeRalOutageByMonthAndYears = response.analyticalVolumeRalOutageByMonthAndYears;
        this.analyticalVolumeRalOutageByMonth = response.analyticalVolumeRalOutageByMonth;
        const datasets = response.analyticalPercentagesRalOutageByMonth.datasets.map((dataset) => dataset.map((d) => [Number(d) + 1])) as any;
        this.analyticalPercentagesRalOutageByMonth = { ...response.analyticalPercentagesRalOutageByMonth, datasets };
      }
    }
  }
}