import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser, getPermissionOptions } from 'src/app/interfaces';
import { UserService } from 'src/app/services/user.service';
import { states } from 'src/app/utils/constants';
import { icons } from 'src/app/utils/icons';

@Component({
  selector: 'app-update-user-modal',
  templateUrl: './update-user-modal.component.html',
  styleUrls: []
})
export class UpdateUserModalComponent implements OnInit {
  
  @Input() user: IUser;
  form: FormGroup;
  icons=icons;
  permissionOptions = getPermissionOptions();
  stateOptions=states;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private userService: UserService,
  ) 
  { }

  ngOnInit(): void {
    if (this.user) {
      const { permissions, fullName, username, email, state } = this.user;
      // TODO: Adicionar ao service de permissions.
      const mappedPermission = this.permissionOptions
        .filter(({ value }) => permissions.includes(value))
        .map(({ value }) => value);

      this.form = this.formBuilder.group({
        fullname: [fullName, [Validators.required, Validators.minLength(3)]],
        username: [username, [Validators.required, Validators.minLength(4)]],
        email: [email, [Validators.required, Validators.email]],
        permissions: [mappedPermission, Validators.required],
        state: [[state], [Validators.required]],
        role: ['USER', Validators.required]    
      });
    }
  }
  
  getFormControls() {
    return this.form.controls;
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    const { id } = this.user;
    const { fullname, username, email, role, permissions, state } = this.getFormControls();
    const input = {
      fullName: fullname.value,
      username: username.value,
      email: email.value,
      role: role.value ?? 'USER',
      state: state.value,
      permissions: role.value === 'ADMINISTRADOR' ? [...permissions.value, 0] : permissions.value,
    }
    this.userService.update({ id, input });
    this.activeModal.close('updated');
  }
}
