<!-- TODO: Buscar uma solução escálavel e mais limpa -->
<!-- <div *ngIf="hasRole('ADMINISTRATOR') || hasRole('MODERATOR')" > -->
  <!-- <div class="row" style="height: 85vh;">
    <div class="col-md-6" style="height: 100%;">
      <app-timeline-default
        title="TIMELINE DE NOTIFICAÇÕES"
        [notifications]="notifications"
      >
      </app-timeline-default>
    </div>

    <div class="col-md-6" style="height: 100%;">
      <app-alert-table
        title="TABELA DE ALERTAS"
        [data]="alerts"
      >
      </app-alert-table>
    </div>
  <div>
</div> -->

<div>
  <div class="row" style="height: 85vh">
    <div
      class="col-md-12"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
      "
    >
      <img src="/assets/images/virtsel-logo-1.png" />
    </div>
  </div>
</div>
