import { Component, OnInit } from '@angular/core';
import { faBell, faHeadset, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { ThemeService } from 'src/app/services/theme.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IColors, VandalismAlert } from 'src/app/interfaces';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { VandalismService } from 'src/app/services/vandalism.service';
import { Formatter } from 'src/app/utils/formatter.util';
import { GenerateDate } from 'src/app/utils/generateDate.util';

@Component({
  selector: 'app-alerta-chamados',
  templateUrl: './alerta-chamados.component.html',
  styleUrls: []
})
export class AlertaChamadosComponent implements OnInit {

  NAMESPACE: Namespace = 'alert';

  requestWrapperConfig = {
    cardStyle: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary card',
    chartSmallStyle: 'card-chart chart-small-geral',
    chartFullStyle: 'card-chart chart-full'
  }

  data: BehaviorSubject<VandalismAlert.Output> = new BehaviorSubject<VandalismAlert.Output>(null);
  selectedItems: BehaviorSubject<Partial<VandalismAlert.Filters>> = new BehaviorSubject({ states: [] })
  loading: boolean = true;
  error: boolean = false;
  colors: IColors;
  faHeadset=faHeadset;
  faBell=faBell;
  faCalendarDay=faCalendarDay;  
  slideConfig = {
    slidesToShow: 1,
    dots: true,
  };

  constructor(
    private vandalismService: VandalismService, 
    private filterService: FilterService,
    private themeService: ThemeService
  ) {
    this.colors = this.themeService.getColors();

    const state = history.state?.UF;

    const { startDate, endDate } = GenerateDate.getDates(); 

    const defaultActiveFilters: VandalismAlert.Params = {  
      startDate: Formatter.formatDataPickerDate(startDate),
      endDate: Formatter.formatDataPickerDate(endDate),
      causes: 'VANDALISMO',
      states: state !== undefined ? state : '',
    }

    this.selectedItems.next(state !== undefined ? { states: [state] }: { states: [] });

    this.filterService.setDefaultFilters<VandalismAlert.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<VandalismAlert.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.fetchData(activeFilters)
      });
  }

  ngOnInit(): void {}

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getDates();
    return {
      startDate,
      endDate,
    }
  }

  ngOnDestroy(): void {
    this.filterService.clearNamespace({ namespace: this.NAMESPACE });
    this.data.unsubscribe();
    this.selectedItems.unsubscribe();
  }

  fetchData(activeFilters: VandalismAlert.Params) {
    return this.vandalismService.getVandalismAlertData(activeFilters)
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        this.data.next(new VandalismAlert.Output(response));
      }, (error) => {
        this.loading = false;
        this.error = true;
      });
  }

  onChange(event) {
    const [key, values] = event.split(':');
    this.filterService.updateFilters<VandalismAlert.Params>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }
}
