<div class="container-not-found">
  <div class="wrapper">
    <h1>Hmm.</h1>
    <p>
      A página que você está procurando não está aqui, volte para a página inicial.
    </p>
    <div class="buttons">      
      <a href="#">home</a>
      <br>
      <span>Virtsel Tecnología LTDA</span>
    </div>
  </div>
  <div class="space">
    <div class="blackhole"></div>
    <div class="ship"></div>
  </div>
</div>
