export const mapConfigStyle = [ 
  {
    featureType: 'all',
    stylers: [
      { saturation: -60 }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      { hue: '#00ffee' },
      { saturation: 100 }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' }
    ]
  },
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      { color: '#523735' }
    ]
  },
];

