<div class="card-chart route-map">
  <div class="card-chart-header">
    <app-title-default [content]="title">
    </app-title-default>
  </div>

  <div class="container-legends">
    <div class="legend-container">
      <span
        class="symbol-legend"
        [ngStyle]="{'background-color': options.colors.expected}"
      >
      </span>
      <span class="label-charts">Esperada</span>
    </div>

    <div class="legend-container">
      <span
        class="symbol-legend"
        [ngStyle]="{'background-color': options.colors.realized}"
      >
      </span>
      <span class="label-charts">Realizada</span>
    </div>
  </div>

  <div class="card-chart-body" style="height: 93%">
    <agm-map
      style="height: 100%;"
      [latitude]="mapConfig.center.lat"
      [longitude]="mapConfig.center.lng"
      [zoomControl]="mapConfig.zoomControl"
      [streetViewControl]="mapConfig.streetViewControl"
      [fullscreenControl]="mapConfig.fullscreenControl"
      [zoom]="mapConfig.zoom"
    >
      <agm-direction
        [origin]="expected.origin"
        [destination]="expected.destination"
        [optimizeWaypoints]="mapConfig.optimizeWaypoints"
        [waypoints]="expected.waypoints"
        [renderOptions]="expectedRenderOptions"
        [visible]="true"
      >
      </agm-direction>

      <agm-direction
        [destination]="realized.destination"
        [optimizeWaypoints]="mapConfig.optimizeWaypoints"
        [waypoints]="realized.waypoints"
        [origin]="realized.origin"
        [renderOptions]="realizedRenderOptions"
        [visible]="true"
      >
      </agm-direction>
    </agm-map>
  </div>
</div>
