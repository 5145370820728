<div 
  class="card-chart chart-full col-12 col-md-12 col-xxl-12"
>
  <div class="card-chart-header chart-full-header">
    <app-title-default [content]="title"></app-title-default>
  </div>
  <hr>
  <div class="card-chart-body chart-full-body " style="width: 100%; height: 90%;">
    <div 
      class="d-flex"
      style="
        width: 100%;
        height: 95%;
        position: relative;
      "
    >
      <canvas
        baseChart
        height="100"
        width="100"
        [datasets]="barChartData.datasets"
        [labels]="barChartData.labels"
        [options]="options"
        [legend]="false"
        [chartType]="type"
      >
      </canvas>
    </div>
    
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
      "
      >
      <span
        style="
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;
          margin-left: 5px;
        "
        [ngStyle]="{ 'background-color': colors.primaryColor }"
      >
      </span>
      <span
        style="font-size: 0.9em; flex-wrap: nowrap;"
      >
        qtde de chamados
      </span>

      <span
        style="
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;
          margin-left: 5px;
        "
        [ngStyle]="{ 'background-color': colors.secondaryColor }"
      >
      </span>
      <span
        style="font-size: 0.9em; flex-wrap: nowrap;"
      >
        qtde de peads
      </span>
    </div>
  </div>
</div>
