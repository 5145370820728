<div 
  class="container-modal-zoom-image" 
  *ngIf="isOpen" 
  (click)="close()"
>
  <div 
    class="container-modal-content" 
    (click)="$event.stopPropagation()"
  >
    <div class="modal-zoom-header">
      <h4 class="modal-title">Imagem em Alta Resolução</h4>
      <button 
        type="button" class="close-button" aria-label="Close" 
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-image">
      <img [src]="imageUrl" class="img-fluid">
    </div>
    <!-- <div class="container-buttons-report">      
      <button class="new-btn-default">Reportar imagem</button>
    </div> -->
  </div>
</div>