import { causeGroups, families, groups, initialAnalyticalChart, regionals } from "src/app/utils/constants";
import { AnalyticalChart } from "../models/IAnalyticalChart";

export const initialImageAnalyticalData = {
  causes: causeGroups,
  families: families,
  regionals: regionals,
  directors: groups
}

export const initialDataImageAnalytical = {
  filters: {
    dropdown: initialImageAnalyticalData,
    selected: {
      causes: [],
      families: [],
      regionals: [],
      directors: []
    }
  },
  analyticalTicketsByGroup: initialAnalyticalChart,
  analyticalPhotosByValidation: initialAnalyticalChart,
  analyticalPhotosByTechnical: initialAnalyticalChart,
  analyticalTicketsByYear: initialAnalyticalChart
}

export namespace ImageAnalytical {

  export type ViewGraphContent = 'analyticalTicketsByGroup' | 'analyticalPhotosByTechnical' | 'analyticalPhotosByValidation' | 'analyticalTicketsByYear';

  export interface Filters {
    causes: string[],
    families: string[],
    regionals: string[],
    directors: string[]
  }

  export interface Params {
    startDate: string,
    endDate: string,
    causes: string
    families: string,
    directors: string,
    regionals: string,
  }

  export interface Response {
    filters: { dropdown: Record<string, string[]>, selected: Record<string, string[]> },
    analyticalTicketsByGroup: AnalyticalChart,
    analyticalPhotosByValidation: AnalyticalChart,
    analyticalPhotosByTechnical: AnalyticalChart,
    analyticalTicketsByYear: AnalyticalChart
  }

  export class Output {
    data: Response = initialDataImageAnalytical
    
    constructor(response?: Response) {
      if (response) {
        this.data = response;
      }
    }
  }
}
