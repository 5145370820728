import { Component, OnInit } from '@angular/core';
import { AnalyticalFullscreenMapComponent } from '../analytical-fullscreen-map.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-analytical-fullscreen-proxy',
  template: `<div></div>`
})
export class AnalyticalFullscreenProxyComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.openMapModal()
  }

  openMapModal() {
    this.modalService.open(AnalyticalFullscreenMapComponent)
  }
}
