export enum LegendType {
  CIRCLE='CIRCLE',
  TRIANGLE='TRIANGLE'
}
export interface LegendItem<T> {
  name: T;
  color: string;
  isVisible: boolean;
  type: LegendType
}
