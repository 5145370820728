<div class="fullscreen-modal">

  <div *ngIf="loading" class="loading-screen">
    <div class="loading-animation">
      <div class="spinner-container">        
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>

  <!-- * HEADER FILTER PAGE -->
  <div 
    class="container-header-page full-screen" 
    [class.bg-transition]="isDivVisible" 
    #headerFilter
  >

    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()"
        alt="logo da claro"
      >
    </div>

    <div 
      class="container-header-filters col-11" 
      [@slideInOut]="isDivVisible ? 'in' : 'out'"
    >
      <div class="container-filters col-12 p-0" style="height: 18vh">
        <app-filter-default
          key="regional"
          filterTitle="regional"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.regional"
          [selectedList]="data.value.selectedList.value.regional"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="group"
          filterTitle="grupo"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.group"
          [selectedList]="data.value.selectedList.value.group"
          (event)="onChange($event)"
        >
        </app-filter-default>
        
        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="state"
            title="Estados"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.state"
            [selectedItems]="data.value.selectedList.value.state"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <app-select-default 
            key="city"
            title="Município"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.city"
            [selectedItems]="data.value.selectedList.value.city"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="cluster"
            title="Cluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.cluster"
            [selectedItems]="data.value.selectedList.value.cluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
            >
          </app-select-default>

          <app-select-default 
            key="causeGroup"
            title="Causa"
            [searchFilter]="true"      
            [isSinglePermanent]="true"
            [dropdownList]="data.value.dropdownList.causeGroup"
            [selectedItems]="data.value.selectedList.value.causeGroup"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="subcluster"
            title="Subcluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.subcluster"
            [selectedItems]="data.value.selectedList.value.subcluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <div>
            <!-- Linha de cima -->
            <div>
              <button class="btn-action-filter" (click)="onReset()" title="Resetar seleção">
                <fa-icon [icon]="icons.trash"></fa-icon>
              </button>
  
              <button class="btn-action-filter" [disabled]="undoDisabled.value" (click)="onUndo()" title="Voltar seleção">
                <fa-icon [icon]="icons.undo"></fa-icon>
              </button>
  
              <button class="btn-action-filter" (click)="onVandalism()" title="Filtrar por vandalismo">
                <fa-icon [icon]="icons.exclamationTriangle"></fa-icon>
              </button>

              <button class="btn-action-filter" (click)="toggleSearchButton()" title="Pesquisar atividade">
                <fa-icon [icon]="icons.search"></fa-icon>
              </button>
            </div>

             <!-- Linha de cima -->
            <div *ngIf="isSearchVisible" class="search-container">
              <input 
                (input)="searchInput.next($event.target.value)"
                type="search"
                class="search-input"
                placeholder="Buscar atividade"
              >
            </div>
          </div>
        </div>

        <div class="col-3 col-md-3 col-xxl-2">
          <app-card-default
            [icon]="icons.headset"
            [cardTitle]="'N° de atividades'"
            [cardValue]="activitiesNumber">
          </app-card-default>

          <div style="display: flex; align-items: center; flex-direction: row; margin-top: -3vh;">
            <div style="margin-right: 10px;">
              <label style="color: white; padding-top: 20px; display: inline-block;">
                <input 
                  type="checkbox" 
                  name="peadsActivation" 
                  title="Mostrar PEADS"
                  (change)="onActivatePeadsChange($event.target.checked)" 
                  [checked]="this.stringToBoolean(this.data.value.selectedList.value.isPead)"
                  style="width: 20px; height: 20px; margin-right: 5px; vertical-align: middle;"
                >PEADS
              </label>
            </div>
            
            <div>
              <label style="color: white; padding-top: 20px; display: inline-block">
                <input 
                  type="checkbox" 
                  name="accumulatedActivation"
                  title="Mostrar PEADS acumulados"
                  (change)="onAccumulatedPeadsChange($event.target.checked)" 
                  [checked]="this.stringToBoolean(this.data.value.selectedList.value.isAccumulated)"
                  style="width: 20px; height: 20px; margin-right: 5px; vertical-align: middle;"
                > ACUMULADO
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- *CONTAINER TOP HEADER -->

      <div class="container-filters col-12 p-0" style="height: 16vh">
        <app-filter-default 
          key="year"
          [icon]="icons.calendar"
          [filterTitle]="'ano'"
          [dropdownList]="data.value.dropdownList.year"
          [selectedList]="data.value.selectedList.value.year"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="month"
          [filterTitle]="'mês'"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.month"
          [selectedList]="data.value.selectedList.value.month"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
            key="day"
            filterTitle="dia"
            [icon]="icons.calendar"
            [dropdownList]="data.value.dropdownList.day"
            [selectedList]="data.value.selectedList.value.day"
            (event)="onChange($event)"
          >
        </app-filter-default>

        <app-filter-default 
          key="family"
          [filterTitle]="'família'"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.family"
          [selectedList]="data.value.selectedList.value.family"
          (event)="onChange($event)"
        >
        </app-filter-default>
        
        <app-filter-default 
          key="net"
          [filterTitle]="'rede'"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.net"
          [selectedList]="data.value.selectedList.value.net"
          (event)="onChange($event)"
        >
        </app-filter-default>
      </div>
      <!-- *CONTAINER BOTTOM HEADER -->
    </div>
  </div>

  <app-ticket-preview
    [tickets]="filteredMarkers.value"
    [activeYears]="activeYears"
  >
  </app-ticket-preview>

  <agm-map 
    #agmMap  
    style="width: 100%; height: 97%;"
    [latitude]="mapConfig.center.lat"
    [longitude]="mapConfig.center.lng"
    [zoom]="mapConfig.zoom"
    [disableDefaultUI]="false"
    [zoomControl]="mapConfig.zoomControl"
    [streetViewControl]="mapConfig.streetViewControl"
    [fullscreenControl]="mapConfig.fullscreenControl"
    (boundsChange)="updateMarkers($event)"
    (mapReady)="onMapReady($event)"
  >
    <agm-marker 
      *ngFor="let ticket of this.data.value.tickets let i = index"
      [latitude]="ticket.lat"
      [longitude]="ticket.lng"
      [label]="ticket.label"
      [iconUrl]="iconUrl(ticket.icon)"
      [title]="treatTooltip(ticket)"
      (markerClick)="openTicketPopUp(ticket)"
    >
    </agm-marker>

    <agm-polyline *ngFor="let pead of this.data.value.peads"
      [strokeColor]="'#006400'"
      [strokeWeight]="10"
      [clickable]="true"
      [geodesic]="true"
    >
      <agm-polyline-point
        [latitude]="pead.latStart"
        [longitude]="pead.lngStart"
      >
      </agm-polyline-point>

      <agm-polyline-point
        [latitude]="pead.latEnd"
        [longitude]="pead.lngEnd"
      >
      </agm-polyline-point>
    </agm-polyline>

    <agm-polyline *ngFor="let peads of this.data.value.peads">
      
      <!-- <ng-container *ngIf="pead.connections && pead.connections.length > 0; else usePeadCoordinates">        
        <agm-polyline
          [strokeColor]="'#000'"
          [strokeWeight]="10"
          [clickable]="true"
          [geodesic]="true"
        >
          
          <agm-polyline-point
            [latitude]="pead.connections[0].latStart"
            [longitude]="pead.connections[0].lngStart"
          >
          </agm-polyline-point>
    
          
          <agm-polyline-point
            [latitude]="pead.connections[pead.connections.length - 1].latEnd"
            [longitude]="pead.connections[pead.connections.length - 1].lngEnd"
          >
          </agm-polyline-point>
        </agm-polyline>
      </ng-container> -->
    
      <!-- Caso connections esteja vazio, use as coordenadas do próprio objeto pead -->
      <!-- <ng-template #usePeadCoordinates>
        <agm-polyline
          [strokeColor]="'#137113'"
          [strokeWeight]="6"
          [clickable]="true"
          [geodesic]="true"
        >          
          <agm-polyline-point
            [latitude]="pead.latStart"
            [longitude]="pead.lngStart"
          >
          </agm-polyline-point>    
          
          <agm-polyline-point
            [latitude]="pead.latEnd"
            [longitude]="pead.lngEnd"
          >
          </agm-polyline-point>
        </agm-polyline>
      </ng-template> -->
    </agm-polyline>    
  </agm-map>

  <div class="fullscreen-modal-footer" style="background-color: white; height: 3%">
    <div class="container-legends">
      <app-legend-item 
        *ngFor="let item of legends | async"
        [legend]="item"
      >
      </app-legend-item>
    </div>
  </div>
</div>
