<div class="card-chart chart-small-alert col-12">
  <div class="card-chart-header chart-small-header-alert">
    <app-title-default [content]="title"></app-title-default>
  </div>
  <hr>
  <div class="card-chart-body chart-small-body-alert">
    <div class="table-div table-responsive">
      <table class="table">
        <tr>
          <th class="th-style th-fixed">UF</th>
          <th  class="th-style" style="font-size: 0.6rem;" *ngFor="let datas of data?.dates">{{ datas }}</th>
          <th  class="th-style th-total">Total</th>
        </tr>
        <tr *ngFor="let state of data?.states">
          <td  class="th-style th-fixed">{{state.state}}</td>
          <td  
            class="th-style" 
            *ngFor="let alerta of state.quantity;let j = index "            
            [ngClass]="state.alert[j] ? 'blink-red' : ''"
          >      
            <div class="alert-item"
            >
              <span
                [ngClass]="state.alert[j] ? 'fa fa-angle-up' : ''"
              >
            </span>
              {{ alerta }}                              
            </div>
          </td>
          <td  class="th-style th-total">{{state.total}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
