import { callControlByState, ChartInput } from "../types";

export namespace VandalismAlert {
  export interface Cards {
    ticketsQuantity: number,
    monitoredDays: number,
    alertsQuantity: number
  }

  export interface Filters {
    states: string[];
  }

  export interface Params {
    startDate: string;
    endDate: string;
    causes: string;
    states: string;
  }

  export interface Response {
    filters: Filters; 
    cards: Cards;
    callControlPerDay: ChartInput;
    callControlByState: callControlByState;  
  }

  export const filterInitialState = {
    regionals: [],
    directors: [],
    states: [],
  }

  export class Output {
    filters: Filters = {
      states: []
    }
  
    cards: Cards = {
      ticketsQuantity: 0,
      monitoredDays: 0,
      alertsQuantity: 0
    }
  
    callControlByState: callControlByState = {
      dates: [],
      states: []
    }
  
    callControlPerDay: ChartInput = {
      dataset: [],
      labels: []
    }
  
    constructor(response?: Response){
      if(response) {
        this.cards = response.cards;
        this.filters = response.filters;
        this.callControlByState = response.callControlByState;
        this.callControlPerDay = response.callControlPerDay;
      }
    }
  
    getCards() {
      return this.cards;    
    }
  
    getFilters() {
      return this.filters;
    }
  
    getCallControlStates() {
      return this.callControlByState;
    }
  
    getCallControlPerDay() {
      return this.callControlPerDay;    
    }
  } 
}