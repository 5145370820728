import { initialAnalyticalChart } from "src/app/utils/constants";
import { AnalyticalChart } from "../models/IAnalyticalChart";

export namespace AnalyticalGeneral {

  export type ViewScreen = 'general';

  export interface InputParams {
    years: string,
    months: string,
    days: string,
    causes: string,
    regionals: string,
    directors: string,
    states: string,
    subClusters: string,
    clusters: string,
    cities: string,
    families: string,
    nets: string
  }

  export interface Response {
    analyticalActivityByMonthEmp: AnalyticalChart,
    analyticalActivityByMonthRes: AnalyticalChart
    analyticalOffendersByGroup: AnalyticalChart,
    analyticalOffendersByState: AnalyticalChart,
    analyticalOffendersByCity: AnalyticalChart,
    analyticalProjectionCurrentMonth: AnalyticalChart
  }

  export class Output {
    
    public analyticalActivityByMonthEmp = initialAnalyticalChart
    public analyticalActivityByMonthRes = initialAnalyticalChart
    public analyticalOffendersByGroup = initialAnalyticalChart
    public analyticalOffendersByState = initialAnalyticalChart
    public analyticalOffendersByCity = initialAnalyticalChart
    public analyticalProjectionCurrentMonth = initialAnalyticalChart
    
    constructor(response?: Response) {
      if (response) {
        this.analyticalActivityByMonthEmp = response.analyticalActivityByMonthEmp
        this.analyticalActivityByMonthRes = response.analyticalActivityByMonthRes;
        this.analyticalOffendersByGroup = response.analyticalOffendersByGroup;
        this.analyticalOffendersByState = response.analyticalOffendersByState;
        this.analyticalOffendersByCity = response.analyticalOffendersByCity;
        this.analyticalProjectionCurrentMonth = response.analyticalProjectionCurrentMonth;
      }
    }
  }
}