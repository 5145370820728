import { format } from "date-fns";

export class Formatter {
  static formatDataPickerDate(date: Date) {
    const formattedDate = new Date(date);
    const day = this.addZero(formattedDate.getDate());
    const month = this.addZero(formattedDate.getMonth() + 1);
    const year = formattedDate.getFullYear();
    return `${day}/${month}/${year}`
  }

  static formatReportedDate(reportedDate: string, withHour: boolean = true) {
    if (!reportedDate) return '';
    const date = new Date(reportedDate);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    const mask = withHour ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
    const formattedDate = format(date, mask);
    return formattedDate;
  }

  static addZero(data: number): string {
    return data <= 9 ? `0${data}` : `${data}`;
  }
}
