<div class="container-forgot-password h-100 col-12 col-md-12 col-lg-12">
  <div class="forgot-password m-auto col-10 col-md-8 col-lg-4">
    <div class="icon-back">
      <a href="/login" class="pe-7s-angle-left-circle"></a>
    </div>
    <div class="forgot-text m-auto col-8 col-lg-8 col-xxl-8">
      <div class="forgot-icon pe-7s-lock"></div>
      <h1 style="font-size: 1rem;">Problemas com login?</h1>
      <p style="font-size: 0.8rem;">
        Insira o seu e-mail e a nova senha que
        enviaremos um link para você voltar a 
        acessar a sua conta.
      </p>        
    </div>
    <div class="form-default col-10 col-md-10 col-xxl-8 mx-auto">
      <form method="post" [formGroup]="form" (submit)="onSubmit($event)">
        <!-- ABERTURA - E-mail Input -->
        <div class="input-default">
          <div class="input-icon-default lnr-user"></div>
          <input
            id="user"
            name="email"
            type="text"
            placeholder="E-mail"
            class="form-control"
            formControlName="email"
          >
        </div>
        <div  
        class="alert" *ngIf="form.get('email')?.errors && form.get('email')?.touched">
          E-mail é obrigatório
        </div>
        <!-- FECHAMENTO - E-mail Input -->

        <!-- ABERTURA - New Password Input -->
        <div class="input-default" style="position: relative;">
          <div class="input-icon-default lnr-lock"></div>
          <input
            id="newPassword"
            name="newPassword"
            [(ngModel)]="newPassword"
            [type]="showNewPassword ? 'text' : 'password'"
            type="password"
            placeholder="Nova Senha"
            class="form-control"
            formControlName="newPassword"
          >
          <i
            *ngIf="newPassword"
            class="eye-position"
            [ngClass]="showNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
            (click)="toggleShowPassword()"
          >
          </i>
        </div>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.required && form.get('newPassword')?.touched">
          <i class="fa fa-times-circle"></i> A nova senha é obrigatória.
        </p>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.caseError && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.required">
          <i class="fa fa-times-circle"></i> Deve conter pelo menos uma letra maiúscula.
        </p>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.specialCharacterError && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.required && !form.get('newPassword')?.errors?.caseError">
          <i class="fa fa-times-circle"></i> Deve conter pelo menos um caractere especial.
        </p>   

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.numberError && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.required && !form.get('newPassword')?.errors?.caseError && !form.get('newPassword')?.errors?.specialCharacterError">
          <i class="fa fa-times-circle"></i> Deve conter números.
        </p>

        <p class="alert" *ngIf="form.get('newPassword')?.errors?.minlength && form.get('newPassword')?.touched && !form.get('newPassword')?.errors?.numberError && !form.get('newPassword')?.errors?.required && !form.get('newPassword')?.errors?.caseError && !form.get('newPassword')?.errors?.specialCharacterError">
          <i class="fa fa-times-circle"></i> Deve ter pelo menos 8 caracteres.
        </p>
        <!-- FECHAMENTO - New Password Input -->

        <!-- ABERTURA - New Password Input -->
        <div class="input-default" style="position: relative;">
          <div class="input-icon-default lnr-lock"></div>
          <input
            id="confirmationPassword"
            name="confirmationPassword"
            [(ngModel)]="confirmationPassword"
            [type]="showConfirmationPassword ? 'text' : 'password'"
            type="password"
            placeholder="Confirme a senha"
            class="form-control"
            formControlName="confirmationPassword"
          >
          <i
            *ngIf="confirmationPassword"
            class="eye-position"
            [ngClass]="showConfirmationPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
            (click)="toggleShowConfirmationPassword()"
          >
          </i>
        </div>
        <p 
          class="alert" 
          *ngIf="form.get('confirmationPassword')?.errors && !passwordsMatchValidator(form) && form.get('confirmationPassword')?.touched"
        >
          <i class="fa fa-times-circle"></i> A senha de confirmação é obrigatória
        </p>

        <p 
          class="alert"
          *ngIf="passwordsMatchValidator(form) && form.get('confirmationPassword')?.touched"
        >
          <i class="fa fa-times-circle"></i> A senhas não coincidem.
        </p>

        <div style="display: flex; align-items: center;">
          <button style="font-size: 0.8rem; justify-content: center;" type="submit" class="btn-default mx-auto col-lg-6 col-md-6 col-6">
            ENVIAR
          </button>
        </div>      
      </form>
    </div>
  </div>
</div>