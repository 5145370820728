<div
  class="row mx-auto"
  style="margin-bottom: 2vh;"
>
  <div class="col-12">
    <app-analytic-bar-default 
      [key]="'month'"    
      [data]="data.value.analyticalVolumeRalOutageByMonthAndYears"
      [primaryColor]="'#949494'"
      [secondaryColor]="'#404040'"
      [loading]="loading"
      [error]="error"
      (filterClick)="onChange($event)"
    >
    </app-analytic-bar-default>
  </div> 
</div>

<div
  class="row mx-auto"
  style="margin-bottom: 2vh;"
>
  <div class="col-12">
    <app-analytic-bar-default
      [key]="'month'"    
      [data]="data.value.analyticalVolumeRalOutageByMonth"
      [loading]="loading"
      [error]="error"
      (filterClick)="onChange($event)"
    >
    </app-analytic-bar-default>
  </div>
</div>


<div
  class="row mx-auto"
  style="margin-bottom: 2vh;"
>
  <div class="col-12">
    <app-analytic-bar-default 
      [key]="'month'"
      [data]="data.value.analyticalPercentagesRalOutageByMonth"
      [isPorcentageDatalabel]="true"
      [loading]="loading"
      [error]="error"
      (filterClick)="onChange($event)"
    >
    </app-analytic-bar-default>
  </div>
</div>
