import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { VandalismAnalytical } from 'src/app/interfaces';
import { AnalyticalChart } from 'src/app/interfaces/models/IAnalyticalChart';
import { ThemeService } from 'src/app/services/theme.service';
import { InverseGroupsMapped, inverseMontsNames } from 'src/app/utils/mapped';

@Component({
  selector: 'app-analytic-bar-horizontal',
  templateUrl: './analytic-bar-horizontal.component.html',
  styleUrls: []
})
export class AnalyticBarHorizontalComponent implements OnInit {  

  @Input() data: AnalyticalChart;
  @Input() activeFilters: VandalismAnalytical.InputParams;
  @Input() key: string;
  @Input() isPorcentageDatalabel = false;
  @Input() primaryColor = 'ff0000';
  @Input() secondaryColor = '#000000';
  @Input() height: number = 35;
  @Input() loading: boolean = true;
  @Input() error: boolean = false;
  @Input() format: (str: string) => string = null;
  @Output() filterClick = new EventEmitter<string>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
 
  barAnalytic = { datasets: [], labels: [], legends: [], title: '' };
  barHeightProportion = '100%';
  type: ChartType = 'horizontalBar';
  colors: string[] = [];
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, activeElements: Array<any>) => {
      if (activeElements.length > 0) {
        let firstElement = activeElements[0];
        let elementIndex = firstElement._index;
        let label = this.data.labels[elementIndex];
        let labelUpper = (label as string).toUpperCase();
        let newValue: any = label;

        if (this.key === 'group') {
          newValue = InverseGroupsMapped[labelUpper].toUpperCase();
        }

        if (this.key === 'month') {
          newValue = inverseMontsNames[labelUpper];
        }

        const filtersActiveByKey = this.activeFilters[this.key].split(',');

        if (filtersActiveByKey.includes(newValue)) {
          this.onChange(filtersActiveByKey.filter((v) => v !== '' && v !== newValue));
          return;
        }

        this.onChange(newValue);
    }},  
    scales: {  
      xAxes: [{        
        ticks: {
          display: false      
        },
        gridLines: {
          display: false    
        }   
      }],
      yAxes: [{        
        ticks: {
          callback: (value: string) => {
            let newValue = value ?? '';
            newValue = newValue.replace('Cluster', ''); 
            return this.format !== null ? this.format(newValue) : newValue.toUpperCase()
          },
          fontSize: 13
        }, 
        gridLines: {
          display: false,
        }
      }] 
    },       
    layout: {
      padding: {
        right: 40,
        left: 30
      },
    },
    plugins: {
      datalabels: {   
        align: 'end',
        anchor: 'end',
        color: '#555',
        font: {
          weight: 'bold',
          size: 8
        },
        formatter: (value) => {
          return this.isPorcentageDatalabel ? `${value}%` : value
        }
      }
    }
  }; 

  constructor(private themeService: ThemeService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    const datasets = this.getTreatedDatasets();
    this.updateColors(datasets);
    this.updateBarProportion(datasets);
    this.createBarAnalytic(datasets);
  }

  isUppercaseOrUnderscore(str: string, isUpper: boolean) {
    const isUppercase = str === str.toUpperCase();
    const containsUnderscore = /_/.test(str);
    return isUppercase || containsUnderscore;
  }

  getTreatedDatasets() {
    return this.data.datasets.reduce((acc, curr) => {
      curr.forEach((value, index) => {
        if (!acc[index]) {
          acc[index] = [];
        }
        
        if (value !== null) {
          acc[index].push(value[0]);
        }
      });
      return acc;
    }, [[], []]);
  }

  mountChartConfig(datasets) {        
    return {
      dataset: datasets.map((data: number[], i: number) => ({
        type: 'horizontalBar',
        backgroundColor: this.colors[i],
        data: data,
        datalabels: {        
          align: 'end',
          padding: 0,
          anchor: 'end',
          color: this.colors[i],
          font: {
            weight: 'bold',
            size: 9
          },
          formatter: (value) => {
            const response = this.isPorcentageDatalabel ? `${value}%` : value;
            return value > 0 ? response : "";
          }   
        }
      })),
      labels: this.data.labels,
      legends: this.data.legends,
      title: this.data.title
    }
  }

  createBarAnalytic(datasets) {
    const data = this.mountChartConfig(datasets);
    if (data) {
      this.barAnalytic = {
        datasets: data.dataset,
        labels: data.labels,
        legends: data.legends,
        title: data.title
      }
    }
  }

  updateColors(datasets) {
    const barQuantity = datasets.length;
    this.colors = this.themeService.generateColorGradient(this.primaryColor, this.secondaryColor, barQuantity);
  }

  updateBarProportion(datasets) {
    const barQuantity = datasets.reduce((acc, current) => current.length + acc, 0)
    const proportion = barQuantity * this.height;
    this.barHeightProportion = `${proportion}%`
    this.updateChartSize();
  }

  updateChartSize() {
    if (this.chart && this.chart.chart) {
      setTimeout(() => {
        this.chart.chart.resize();
        this.chart.chart.update();
      }, 300);
    }
  }

  onChange(labelIndex: string) {      
    this.filterClick.emit(`${this.key}:${labelIndex}`);    
  }
}
