import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ResponsePopUpComponent } from './components/response-pop-up/response-pop-up.component';
import { caseValidator, numberValidator, specialCharacterValidator } from 'src/app/utils/inputValidators';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: []
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;

  loading: boolean = true;
  submitted: boolean;
  error: boolean = true;

  username: string;
  companyCode: number;

  showNewPassword: boolean = false;
  newPassword: string = '';
  showConfirmationPassword: boolean = false;
  confirmationPassword: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      activationCode: ['', [Validators.required, Validators.minLength(4)]],
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        caseValidator,
        specialCharacterValidator,
        numberValidator,
        Validators.minLength(8),
      ])),
      confirmationPassword: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit(): void {
    const state = history.state;
    if (state?.username && state?.companyCode) {
      this.username = state.username;
      this.companyCode = state.companyCode;
    }
  }

  toggleShowPassword() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleShowConfirmationPassword() {
    this.showConfirmationPassword = !this.showConfirmationPassword;
  }

  passwordsMatchValidator(control: AbstractControl) {
    const newPassword = control.get('newPassword').value;
    const confirmationPassword = control.get('confirmationPassword').value;
    if (newPassword !== confirmationPassword) {
      return true
    }
    return false
  }

  getUsername() {
    return this.form.controls.username.value
  }

  getNewPassword() {
    return this.form.controls.newPassword.value
  }

  getConfirmationPassword() {
    return this.form.controls.confirmationPassword.value
  }

  getActividationCode() {
    return this.form.controls.activationCode.value
  }

  open() {
    const modalRef = this.modalService.open(ResponsePopUpComponent, {
      windowClass: 'test'
    });
    modalRef.componentInstance.isSuccess = this.error;
  }

  onSubmit(e): void {
    e.preventDefault();
  
    this.submitted = true;

    if (this.form.invalid) {
      this.error = true;
      return;
    }

    this.loading = true;

    this.authService.resetPassword({
      username: this.username,
      password: this.getNewPassword(),
      companyCode: this.companyCode,
      code: this.getActividationCode(),
    })
      .subscribe(
        (data) => {
          this.error = false;
          this.loading = false;
          this.router.navigate(['/login']);
          this.open();
        },
        (error) => {
          this.error = true;
          this.loading = false;
          // this.router.navigate(['/login']);
          this.open();
        });
  }

}
