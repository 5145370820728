<div class="row">
	<!-- * HEADER FILTER PAGE -->
		<div     
    class="container-header-page"
    [class.bg-transition]="isDivVisible"
    #headerFilter
  >
    <div class="header-img-filter col-1">
      <img         
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()"
        alt="Logo da claro"
      >    
    </div>
  
    <div
      class="container-header-filters col-11"
      [@slideInOut]="isDivVisible ? 'in' : 'out'"
    >
  
      <div class="container-filters col-12 p-0" style="height: 17vh">
        <app-filter-default
          key="regional"
          [filterTitle]="filters.regional"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.regional"
          [selectedList]="data.value.selectedList.value.regional"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="group"
          [filterTitle]="filters.group"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.group"
          [selectedList]="data.value.selectedList.value.group"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="state"
            title="Estados"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.state"
            [selectedItems]="data.value.selectedList.value.state"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <app-select-default 
            key="city"
            title="Município"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.city"
            [selectedItems]="data.value.selectedList.value.city"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="cluster"
            title="Cluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.cluster"
            [selectedItems]="data.value.selectedList.value.cluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
            >
          </app-select-default>

          <app-select-default 
            key="causeGroup"
            title="Causa"
            [searchFilter]="true"      
            [isSinglePermanent]="true"
            [limit]="3"
            [dropdownList]="data.value.dropdownList.causeGroup"
            [selectedItems]="data.value.selectedList.value.causeGroup"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>
        </div>

        <div class="col-2 col-xxl-2" style="padding: 0.5">
          <app-select-default 
            key="subcluster"
            title="Subcluster"
            [searchFilter]="true"
            [dropdownList]="data.value.dropdownList.subcluster"
            [selectedItems]="data.value.selectedList.value.subcluster"
            (event)="onChange($event)"
            [itemsShowLimit]="1"
          >
          </app-select-default>

          <div
            style="
              display: flex;
              align-items: center;
              margin-top: 3vh;
            "
          >
            <button class="btn-action-filter" (click)="onReset()">
              <fa-icon [icon]="icons.trash"></fa-icon>
            </button>
            <button class="btn-action-filter" [disabled]="undoDisabled.value" (click)="onUndo()">
              <fa-icon [icon]="icons.undo"></fa-icon>
            </button>
          </div>
        </div>

        <div style="display: flex; align-items: center; flex-direction: row; margin-top: -2vh;">
          <div style="margin-right: 10px;">
            <label style="color: white; padding-top: 20px; display: inline-block;">
              <input 
                type="checkbox" 
                name="peadsActivation" 
                (change)="onActivatePeadsChange($event.target.checked)" 
                [checked]="isPeadsActivated.value"
                style="width: 20px; height: 20px; margin-right: 5px; vertical-align: middle;"
              >PEADS    
            </label>
          </div>

          <div>
            <label style="color: white; padding-top: 20px; display: inline-block;" >
              <input 
                type="checkbox" 
                name="accumulatedActivation" 
                (change)="onAccumulatedPeadsChange($event.target.checked)" 
                [checked]="isAccumulatedPeadsActivated.value"
                style="width: 20px; height: 20px; margin-right: 5px; vertical-align: middle;"
              >ACUMULADO
            </label>
          </div>
        </div>
      </div>
      <!-- *CONTAINER TOP HEADER -->

      <div class="container-filters col-12 p-0" style="height: 15vh"> 
        <app-filter-default 
          key="year"
          [icon]="icons.calendar"
          [filterTitle]="filters.year"
          [dropdownList]="data.value.dropdownList.year"
          [selectedList]="data.value.selectedList.value.year"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default
          key="month"
          [filterTitle]="filters.month"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.month"
          [selectedList]="data.value.selectedList.value.month"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <app-filter-default 
          key="family"
          [filterTitle]="filters.family"
          [icon]="icons.building"
          [dropdownList]="data.value.dropdownList.family"
          [selectedList]="data.value.selectedList.value.family"
          (event)="onChange($event)"
        >
        </app-filter-default>
        
        <app-filter-default 
          key="net"
          [filterTitle]="filters.net"
          [icon]="icons.calendar"
          [dropdownList]="data.value.dropdownList.net"
          [selectedList]="data.value.selectedList.value.net"
          (event)="onChange($event)"
        >
        </app-filter-default>

        <div class="btn-full-screen">
          <button (click)="openFullscreen()">
            <img
              src="../../../../assets/images/brasil-mini-map.png"
              alt=""
              style="height: 8vh"
            >
          </button>
        </div>
      </div>
      <!-- *CONTAINER BOTTOM HEADER -->

    </div>
  </div>
  
	<div class="container-detalhamento row mx-auto px-0">
		<!-- Primeira Coluna: Mapa 1 -->
		<div class="col-12 col-md-6 col-xxl-6">
				<app-request-wrapper
						[isLoading]="loading"
						[isError]="error"
						[class]="requestWrapperConfig.chartFullStyle"
				>
						<app-call-comparison-by-state-bar-chart
								[data]="this.data.value?.comparisonCallsByState"
								[colors]="colors"
						>
						</app-call-comparison-by-state-bar-chart>			
				</app-request-wrapper>	
		</div>
		
		<!-- Segunda Coluna: Mapa 2 -->
		<div class="col-12 col-md-6 col-xxl-6">
				<app-request-wrapper
						[isLoading]="loading"
						[isError]="error"
						[class]="requestWrapperConfig.chartFullStyle"
				>
						<app-call-comparision-bar-line-chart
								[data]="this.data.value?.comparisonCallsByMonth"
								[colors]="colors"
						>
						</app-call-comparision-bar-line-chart>	
				</app-request-wrapper>			
		</div>
	</div>
</div>
