import { initialValueChartInput } from "src/app/utils/interfaces/constants";
import { ITicket } from "../models";
import { ChartInput, connectionTypeView, datetimeTypeView } from "../types";

export namespace VandalismGeneral {

  export interface Cards {
    ticketsQuantity: number,
    avgNumberCallsPerDay: number,
    avgSLAinHours: number,
  }

  export interface Filters {
    regionals: string[];
    directors: string[];
    states: string[];
  }

  export interface Params {
    startDate: string;
    endDate: string;
    causes: string;
    states: string;
    directors: string;
    regionals: string;
  }

  export interface Response {
    filters: Filters;
    cards: Cards;
    vandalismOccurrences: ChartInput;
    operationalPercentages: Record<connectionTypeView, ChartInput>;
    callsByDayAndHour: Record<datetimeTypeView, any>;
    ticketsForMap: ITicket[];
  }

  export const filterInitialState = {
    regionals: [],
    directors: [],
    states: [],
  }
  
  export class Output {
    filters: Filters = filterInitialState
    cards: Cards = { ticketsQuantity: 0, avgNumberCallsPerDay: 0, avgSLAinHours: 0 }
    vandalismOccurrences: ChartInput = initialValueChartInput;
    operationalPercentages: Record<connectionTypeView, ChartInput> = {
      familia: initialValueChartInput,
      origem: initialValueChartInput,
      rede: initialValueChartInput
    }
    callsByDayAndHour: Record<datetimeTypeView, any> = {
      dayOfWeek: initialValueChartInput,
      hourOfDay: initialValueChartInput,
      dayOfWeekAndHour: undefined
    }
    ticketsForMap: ITicket[] = [];
  
    constructor(response?: Response) {
      if (response) {
        this.cards = response.cards;
        this.filters = response.filters;
        this.vandalismOccurrences = response.vandalismOccurrences;
        this.operationalPercentages = response.operationalPercentages;
        this.callsByDayAndHour = response.callsByDayAndHour;
        this.ticketsForMap = response.ticketsForMap;
      }
    }
  
    getCards() {
      return this.cards;
    }
  
    getFilters() {
      return this.filters;
    }
  
    getVandalismOccurrences() {
      return this.vandalismOccurrences;
    }
  
    getOperationalPercentages() {
      return this.operationalPercentages;
    }
  
    getCallsByDayAndHour() {
      return this.callsByDayAndHour;
    }
  
    getTicketsForMap() {
      return this.ticketsForMap;
    }
  } 
}