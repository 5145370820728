import { Component, OnDestroy, OnInit } from '@angular/core';
import { faClock, faHeadset, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterPopUpComponent } from 'src/app/components/filter-pop-up/filter-pop-up.component';
import { VandalismService } from 'src/app/services/vandalism.service';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { Formatter } from 'src/app/utils/formatter.util';
import { ThemeService } from 'src/app/services/theme.service';
import { GenerateDate } from 'src/app/utils/generateDate.util';
import { IColors, VandalismGeneral } from 'src/app/interfaces';
import { Router } from '@angular/router';
import { icons } from 'src/app/utils/icons';

@Component({
  selector: 'app-geral',
  templateUrl: './geral.component.html',
  styleUrls: []
})
export class GeralComponent implements OnInit, OnDestroy {

  NAMESPACE: Namespace = 'geral';

  data: BehaviorSubject<VandalismGeneral.Output> = new BehaviorSubject(new VandalismGeneral.Output());
  loading: boolean = true;
  error: boolean = false;
  colors: IColors;

  icons=icons

  slideConfig = {
    slidesToShow: 1,
    dots: true,
  };

  requestWrapperConfig = {
    cardStyle: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary card',
    chartSmallStyle: 'card-chart chart-small-geral'
  }

  constructor(
    private modalService: NgbModal,
    private vandalismService: VandalismService,
    private filterService: FilterService,
    private themeService: ThemeService,
    private router: Router,
  ) {
    this.colors = this.themeService.getColors();

    const { startDate, endDate } = GenerateDate.getDates();

    const defaultActiveFilters: VandalismGeneral.Params = {
      startDate: Formatter.formatDataPickerDate(startDate),
      endDate: Formatter.formatDataPickerDate(endDate),
      causes: 'VANDALISMO',
      states: '',
      directors: '',
      regionals: ''
    }

    this.filterService.setDefaultFilters<VandalismGeneral.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<VandalismGeneral.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.fetchData(activeFilters)
      });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.filterService.clearNamespace({ namespace: this.NAMESPACE });
    this.data.unsubscribe();
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getDates();
    return {
      startDate,
      endDate,
    }
  }

  fetchData(activeFilters: VandalismGeneral.Params) {
    return this.vandalismService.getVandalismGeneralData(activeFilters)
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        this.data.next(new VandalismGeneral.Output(response));
      }, (error) => {
        this.loading = false;
        this.error = true;
      });
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    this.filterService.updateFilters<VandalismGeneral.Params>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }

  onRedirect(state: string) {
    this.router.navigate(['/vandalismo/alerta'], { state: { UF: state }})
  }

  openFiltersPopUp() {
    this.modalService.open(FilterPopUpComponent, { size: 'lg' });
  }
}
