import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartType, ChartOptions } from 'chart.js';
import { IColors, datetimeTypeView } from '../../interfaces';

type callQuantityBarData = {
  labels: string[];
  sublabels?: string[]
  dataset: number[] | callQuantityBarData[],
}

type Data = Record<datetimeTypeView, callQuantityBarData>;

const days = {
  'SUNDAY': 'DOMINGO',
  'MONDAY': 'SEGUNDA',
  'TUESDAY': 'TERÇA',
  'WEDNESDAY': 'QUARTA',
  'THURSDAY': 'QUINTA',
  'FRIDAY': 'SEXTA',
  'SATURDAY': 'SÁBADO',
}

@Component({
  selector: 'app-call-quantity-bar-chart',
  templateUrl: './call-quantity-bar-chart.component.html',
  styleUrls: []
})
export class CallQuantityBarChartComponent implements OnInit, OnChanges {
  titles: Record<datetimeTypeView, string> = {
    dayOfWeek: 'QUANTIDADE CHAMADOS POR DIA DA SEMANA',
    hourOfDay: 'QUANTIDADE DE CHAMADOS POR HORA DO DIA',
    dayOfWeekAndHour:'QUANTIDADE DE CHAMADOS POR HORA DO DIA'
  }

  @Input() data: Data;
  @Input() colors: IColors;

  selectedView: datetimeTypeView;
  selectedDataView: callQuantityBarData;
  selectedDayOfWeek: number;

  backgroundColors: string[];
  type: ChartType = 'bar';
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
      padding: {
        top: -5,    // Margem superior
        left: -5,   // Margem esquerda
        right: -5,  // Margem direita
        bottom: -5  // Margem inferior
      }
    },
    scales: 
    { 
      xAxes: [{
        gridLines: {
          display: false,
        }
      }], 
      yAxes: [{
        ticks: {
          maxTicksLimit: 3,
          beginAtZero: true,
          display:false
        },
        gridLines: {
          display: false
        },
      }] 
    },    
    tooltips:{      
      callbacks:{
        label: function(tooltipItem, data){            
          let label = data.labels[tooltipItem.index];
          let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return 'Qtd de chamados: ' + value;
        }
      },
    } ,    
    plugins: 
    {
      datalabels: {
        display: true,
        color: 'white',
        font: {
          weight: 'bold'
        },
        align: 'start',
        anchor: 'end',
        offset: 2,
        formatter: (value, context) => {
          return value === 0 ? '' : value;
        }
      },
    }, 
  };

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedView = 'dayOfWeek';
    const dataset = this.data[this.selectedView].dataset;
    const labels = this.selectedView === 'dayOfWeek' 
      ? this.data[this.selectedView].labels.map((label) => days[label]) 
      : (this.data[this.selectedView] as any).labels
    this.selectedDataView = { dataset, labels };
    this.selectedDayOfWeek = 0;
    this.backgroundColors =  Array(15).fill({ 
      backgroundColor: this.colors.primaryColor,
      hoverBackgroundColor: this.colors.hoverPrimaryColor 
    });
  }

  setView(view: datetimeTypeView) {
    const viewComp: datetimeTypeView = 'dayOfWeekAndHour';
    if (view === viewComp) {
      this.selectedView = viewComp;

      const { labels, dataset } = this.data[viewComp];

      const {
        dataset: subdataset,
        labels: sublabels
      } = (dataset[this.selectedDayOfWeek] as callQuantityBarData);

      let treatSublabels = sublabels.map((label) => days[label])

      this.selectedDataView = {
        labels,
        dataset: subdataset,
        sublabels: treatSublabels
      }
    } else {
      this.selectedView = view;

      const dataset = this.data[this.selectedView].dataset;
      const labels = this.selectedView === 'dayOfWeek' 
        ? this.data[this.selectedView].labels.map((label) => days[label]) 
        : (this.data[this.selectedView] as any).labels
  
      this.selectedDataView = {
        dataset,
        labels
      };

      // this.selectedDataView = this.data[this.selectedView];
    }
  }

  isDayOfWeekView() {
    return this.selectedView === 'dayOfWeekAndHour';
  }

  increaseDay() {
    this.selectedDayOfWeek = (this.selectedDayOfWeek + 1) % 7;
    this.setView('dayOfWeekAndHour');
  }

  decreaseDay() {
    this.selectedDayOfWeek = (this.selectedDayOfWeek - 1 + 7) % 7;
    this.setView('dayOfWeekAndHour');
  }
}
