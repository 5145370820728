import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserField, UserFieldUpdate, UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RequestResult } from 'src/app/interfaces/RequestContext';

@Component({
  selector: 'app-user-info-pop-up',
  templateUrl: './user-info-pop-up.component.html',
  styleUrls: ['./user-info-pop-up.component.scss']
})
export class UserInfoPopUpComponent implements OnInit {
  
  userId: number;
  selectedValues: { [key: string]: string } = {};
  userInfoForm: FormGroup;
  @Input() fields: UserField[];

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {
    this.userId = this.authService.getCurrentUser().id;
    this.userInfoForm = this.fb.group({});
    this.fields = [];
  }

  ngOnInit(): void {
    this.initForms();
  }

  initForms() {
    this.fields.forEach((field) => {
      this.userInfoForm.addControl(
        field.columnName,
        this.fb.control('', Validators.required)
      );
      this.selectedValues[field.columnName] = '';
    });
  }

  onSave(): void {
    if (this.userInfoForm.invalid) {
      return;
    }

    const formData: UserFieldUpdate[] = this.fields.map(field => ({
      columnName: field.columnName,
      value: this.userInfoForm.get(field.columnName)?.value
    }));

    this.userService.updateUserInfo(this.userId, formData).subscribe(
      (_response) => {
        const result: RequestResult = {
          status: 200,
          message: 'Informações salvas com sucesso! Seus dados foram atualizados.',
          isError: false,
          styleClass: 'success-bar-container'
        };
        this.openPopUpResponse(result);
        this.closeModal();
      },
      (error) => {
        const result: RequestResult = {
          status: error.status,
          message: 'Erro ao salvar as informações. Por favor, tente novamente mais tarde.',
          isError: true,
          styleClass: 'failure-bar-container'
        };
        this.openPopUpResponse(result);
      }
    );
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
