<div style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;">
  <div class="loading-container">
    <div class="loader">
      <div class="ball-pulse">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>