<div class="card-chart chart-full col-md-12 col-xxl-12">
  <div class="card-chart-header chart-full-header">
    <app-title-default [content]="title"></app-title-default>
  </div>
  <hr>
  <div class="card-chart-body chart-full-body">
    <div
      style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2vh;
        "
      >
        <span
          style="
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 5px;
            margin-left: 5px;
          "
          [ngStyle]="{ 'background-color': colors.primaryColor }"
        >
        </span>
        <span
          style="font-size: 0.8em; flex-wrap: nowrap;"
        >
        qtde de chamados
        </span>

        <span
          style="
            width: 10px;
            height: 2px;
            margin-right: 5px;
            margin-left: 5px;
          "
          [ngStyle]="{ 'background-color': colors.secondaryColor }"
        >
        </span>
        <span
          style="font-size: 0.8em; flex-wrap: nowrap;"
        >
        qtde de pead´s
        </span>
    </div>

    <div 
      class="d-flex"
      style="width: 100%; height: 100%; position: relative;"
    >
      <canvas
        baseChart
        [datasets]="barChartData.datasets"
        [labels]="barChartData.labels"
        [chartType]="type"
        [options]="options"
      >
      </canvas>
    </div>
  </div>
</div>
