import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-default',
  template: `<div class="title-default">{{ content }}</div>`
})
export class TitleDefaultComponent implements OnInit {

  @Input() content: string;

  constructor() { }

  ngOnInit(): void {
  }

}
