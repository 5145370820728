import { Component, Input } from "@angular/core";

export interface IVerification {
  type: string,
  symbol: string
}

@Component({
  selector: 'app-verification-span',
  templateUrl: './verification-span.component.html',
  styleUrls: ['./verification-span.component.scss']
})
export class VerificationSpanComponent {

  @Input() data: IVerification;

  constructor() {}

  ngOnInit() {}

}