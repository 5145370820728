import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { ChartInput} from 'src/app/interfaces';
import { ChartOptions, ChartType } from 'chart.js';
import { IColors } from 'src/app/interfaces';

@Component({
  selector: 'app-call-control-per-day',
  templateUrl: './call-control-per-day.component.html',
  styleUrls: []
})
export class CallControlPerDayComponent implements OnInit, OnChanges {  
  title="CARTA DE CONTROLE DE CHAMADOS POR DIA";
  @Input() data: ChartInput;
  @Input() colors: IColors;
  
  barlineData;
  // backgroundColor: string[];  
  type: ChartType = 'line';
  lineChartLegend = false;    
  public lineOptions: (ChartOptions) = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips:{
      mode:'index',
      intersect: false,      
      callbacks:{
        label: function(tooltipItem, data){                      
          let label = '';

          if (tooltipItem.datasetIndex === 0) {
            // Para o primeiro dataset
            label = `Qtde de chamados: ${data.datasets[0].data[tooltipItem.index]}`;
          }
          else if (tooltipItem.datasetIndex === 1) {
            // Para o terceiro dataset
            label = `Média móvel: ${data.datasets[1].data[tooltipItem.index]}`;
          }
          else if (tooltipItem.datasetIndex === 2) {
            // Para o terceiro dataset
            label = `Volatidade IC 95%: ${data.datasets[2].data[tooltipItem.index]}`;
          }
          // Nenhuma ação é tomada para o dataset de índice 1 (ou qualquer outro índice não especificado)
      
          return label;
        }
      },
    },
    events:['mousemove', 'mouseout'],    
    elements:{
      line:{
        borderWidth: 2,
        fill: false,
        tension: 0       
      },
      point:{
        radius:0
      }
    },   
    scales: {      
      xAxes: [{
        gridLines:{
          display:false
        },             
      }],
      yAxes: [
        {          
          position: 'left',
          ticks:{
            maxTicksLimit:6
          },
          gridLines:{
            display: false,
          }
        },     
      ]
    },     
    plugins:{
      datalabels:{
        display: false
      },
    } 
  };   

  constructor() { 
  }
  
  ngOnInit(): void{    
  }
  
  ngOnChanges(changes: SimpleChanges): void{
    this.createLineChart()       
  }
  
  createLineChart(){
    const colorPrimary = this.colors.primaryColor;
    const colorSecondary ='#ffd200' ;
    const colorTertiary = this.colors.secondaryColor;

    this.barlineData = {
      datasets:[
        {
          data: this.data?.dataset[0] ?? [],  
          type: 'line',
          order: 1,
          backgroundColor: colorPrimary,
          borderColor: colorPrimary,
          pointBackgroundColor: colorPrimary,
          pointBorderColor: colorPrimary,
          pointHoverBackgroundColor: colorPrimary,
          pointHoverBorderColor: colorPrimary,          
        },
        {
          data: this.data?.dataset[1] ?? [],
          type: 'line',
          order: 2,          
          backgroundColor: colorSecondary,
          borderColor: colorSecondary,
          pointBackgroundColor: colorSecondary,
          pointBorderColor: colorSecondary,
          pointHoverBackgroundColor: colorSecondary,
          pointHoverBorderColor: colorSecondary,          
        },        
        {
          data: this.data?.dataset[2] ?? [],
          type: 'line',
          order: 3,
          backgroundColor: colorTertiary,
          borderColor: colorTertiary,
          pointBackgroundColor: colorTertiary,
          pointBorderColor: colorTertiary,
          pointHoverBackgroundColor: colorTertiary,
          pointHoverBorderColor: colorTertiary,          
        },        
      ],
      labels: this.data?.labels
    }    
  };   
}
