<div class="modal-container">
  <!-- Header -->
  <div class="modal-header">
    <h2 class="modal-title">Atualizar Informações</h2>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div style="display: flex; align-items: center; justify-content: center; margin-top: 1vh;">
    <p>por favor, atualize a unidade onde você está localizado</p>
  </div>

  <!-- Body -->
  <div class="modal-body">
    <form [formGroup]="userInfoForm" (ngSubmit)="onSave()">
      <div *ngFor="let field of fields;" class="form-group">
        <label class="form-label">{{ field.fieldName }}</label>
        <ng-select
          [items]="field.values"

          class="ng-select"
          [formControlName]="field.columnName"
        >
        </ng-select>
      </div>
      <!-- Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancelar</button>
        <button type="submit" class="btn btn-primary" [disabled]="userInfoForm.invalid">Salvar</button>
      </div>
    </form>
  </div>
</div>
