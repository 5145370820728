<div class="container-forgot-password h-100 col-12 col-md-12 col-lg-12">
  <div class="forgot-password m-auto col-10 col-md-8 col-lg-4">

    <div class="icon-back">
      <a href="/login" class="pe-7s-angle-left-circle"></a>
    </div>

    <div class="forgot-text m-auto col-10 col-lg-10 col-xxl-10 text-center">

      <!-- Indicador de Carregamento -->
      <div *ngIf="isLoading" class="d-flex flex-column align-items-center justify-content-center">
        <div class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem;">
          <span class="visually-hidden">Carregando...</span>
        </div>
        <p class="mt-3" style="font-size: 1.1rem;">Por favor, aguarde enquanto processamos sua solicitação...</p>
      </div>

      <!-- Ícone de Sucesso -->
      <div class="forgot-icon" *ngIf="!isLoading && confirmationSuccess">
        <svg xmlns="http://www.w3.org/2000/svg" width="5rem" height="5rem" fill="#28a745" class="bi bi-check-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zM8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0z"/>
          <path d="M10.97 4.97a.235.235 0 0 1 .04.327L7.477 9.417a.75.75 0 0 1-1.08.02L5.324 7.72a.25.25 0 0 1 .28-.4l1.294 1.176 3.518-3.992a.25.25 0 0 1 .353-.037z"/>
        </svg>
      </div>

      <!-- Ícone de Erro -->
      <div class="forgot-icon" *ngIf="!isLoading && !confirmationSuccess">
        <svg xmlns="http://www.w3.org/2000/svg" width="5rem" height="5rem" fill="#dc3545" class="bi bi-x-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zM8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0z"/>
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>

      <!-- Mensagens de Confirmação -->
      <h1 *ngIf="!isLoading" style="font-size: 1.3rem; margin-bottom: 1vh;">{{ confirmationMessage }}</h1>
      <p *ngIf="!isLoading" style="font-size: 0.8rem;">{{ confirmationSubmessage }}</p>
    </div>

    <!-- Botão de Redirecionamento -->
    <div class="form-default col-12 col-md-10 col-xxl-8 mx-auto text-center" *ngIf="!isLoading && confirmationSuccess">
      <a href="/login" class="btn btn-confirmation-blue mt-3">Ir para o Login</a>
    </div>
  </div>
</div>
