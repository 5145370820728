export const technicalIcons = {
  iconTecInservice: '/assets/maps/svg-mapa-operacoes/marker-tecnico-inservice.svg',
  iconTecAssistence: '/assets/maps/svg-mapa-operacoes/marker-tecnico-assistence.svg',
  iconTecOutservice: '/assets/maps/svg-mapa-operacoes/marker-tecnico-outservice.svg',
  iconTecRoute: '/assets/maps/svg-mapa-operacoes/marker-tecnico-enroute.svg',
  iconTecPosition: '/assets/maps/svg-mapa-operacoes/marker-tecnico-last-position.svg',
  iconTecAlertInroute: '/assets/maps/svg-mapa-operacoes/alerts/marker-tecnico-alert-inroute.svg',
  iconTecRefe: '/assets/maps/svg-mapa-operacoes/marker-tecnico-refe.svg',
  iconSemGps: '/assets/maps/svg-mapa-operacoes/marker-tecnico-nogps.svg',
  iconSemGpsAssistance: '/assets/maps/svg-mapa-operacoes/alerts/marker-tecnico-nogps-assistance.svg',
  iconSemGpsRef: '/assets/maps/svg-mapa-operacoes/alerts/marker-tecnico-nogps-ref.svg',
  iconAlertRef: '/assets/maps/svg-mapa-operacoes/alerts/marker-tecnico-alert-ref.svg',
  iconTecAlerta: '/assets/maps/svg-mapa-operacoes/marker-tecnico-alert.svg',
  iconTecAlertaAssistance: '/assets/maps/svg-mapa-operacoes/alerts/marker-tecnico-alert-assistance.svg',
  iconTecAssistance: '/assets/maps/svg-mapa-operacoes/marker-tecnico-route-assistance.svg',
  iconFullScreen: '/assets/maps/fullscreen_desativado.svg',
};

export const activityIcons = {
  iconActivitiesNot: '/assets/maps/svg-mapa-operacoes/marker-activities-not.svg',
  iconEmpStart: '/assets/maps/svg-mapa-operacoes/marker-empresarial-start.svg',
  iconEmpStop: '/assets/maps/svg-mapa-operacoes/marker-empresarial-stop.svg',
  iconEmpRoute: '/assets/maps/svg-mapa-operacoes/marker-empresarial-enroute.svg',
  iconResStart: '/assets/maps/svg-mapa-operacoes/marker-residencial-start.svg',
  iconResStop: '/assets/maps/svg-mapa-operacoes/marker-residencial-stop.svg',
  iconResRoute: '/assets/maps/svg-mapa-operacoes/marker-residencial-enroute.svg',
}

export const inventoryIcons = {
  iconInventory: '/assets/maps/svg-mapa-inventario/marker-activitie-inventory.svg',
  iconPhotoInventory: '/assets/maps/svg-mapa-inventario/marker-photo-inventory.svg'
}