<div class="card-chart chart-small-geral col-12 col-xxl-12">
  <div class="card-chart-header chart-small-header-geral col-12 col-xxl-12" style="flex-direction: row !important;">
    <button 
      *ngFor="let view of views"
      class="btn-chart col-3 col-xl-4 col-xxl-4"
      [ngStyle]="{ 'background-color': colors.secondaryColor }"
      (click)="setView(view)"
    >
      {{ view }}
    </button>  
  </div>
  <hr>
  <div class="card-chart-body chart-small-body-geral">
    <div class="chart-doughnut">
      <canvas
        baseChart
        width="100%"               
        height="100%"
        [legend]="false"
        [chartType]="type"
        [options]="options"
        [data]="selectedViewData.dataset"
        [labels]="selectedViewData.labels"
        [colors]="backgroundColor"
      >
      </canvas>    
    </div>
    <div class="container-legends">
      <!-- TODO: Componentizar -->
      <div
        class="legend-container"
        *ngFor="let label of selectedViewData.labels; let i = index"
      >
        <span class="symbol-legend"
          [ngStyle]="{ 'background-color': getColor(i) }"
        >
        </span>
        <span class="label-charts">
          {{ label }}
        </span>
      </div>
    </div>
</div>
