export enum CauseOption {
  "VANDALISMO" = "VANDALISMO",
  "ACIDENTE DE TRANSITO" = "ACIDENTE DE TRANSITO",
  "ATIVACAO DE LINK" = "ATIVACAO DE LINK",
  "BACKBONE" = "BACKBONE",
  "CABO" = "CABO",
  "CARGA ALTA" = "CARGA ALTA",
  "COM AGUA" = "COM AGUA",
  "CURTO-CIRCUITO" = "CURTO-CIRCUITO",
  "DANO PARCIAL" = "DANO PARCIAL",
  "DESVIO DE FIBRA" = "DESVIO DE FIBRA",
  "EXIGENCIAS LEGAIS" = "EXIGENCIAS LEGAIS",
  "FALHA DA CONTRATADA" = "FALHA DA CONTRATADA",
  "FIBRA" = "FIBRA",
  "FONTE" = "FONTE",
  "IMPLANTACAO" = "IMPLANTACAO",
  "INCENDIO" = "INCENDIO",
  "INSPECAO VISUAL" = "INSPECAO VISUAL",
  "LEAKAGE" = "LEAKAGE",
  "LIMPEZA MASSIVA DE RUIDO" = "LIMPEZA MASSIVA DE RUIDO",
  "LINHA DE PIPA" = "LINHA DE PIPA",
  "MAU CONTATO" = "MAU CONTATO",
  "MODULO" = "MODULO",
  "NIVEIS FORA DO PADRAO" = "NIVEIS FORA DO PADRAO",
  "OBRAS" = "OBRAS",
  "OUTRAS OPERADORAS" = "OUTRAS OPERADORAS",
  "PASSIVO OPTICO" = "PASSIVO OPTICO",
  "PEAD" = "PEAD",
  "PODA DE ARVORE" = "PODA DE ARVORE",
  "QUEBRADO" = "QUEBRADO",
  "QUEDA DE ARVORE" = "QUEDA DE ARVORE",
  "QUEDA DE ENERGIA" = "QUEDA DE ENERGIA",
  "QUEIMADO" = "QUEIMADO",
  "REDE BAIXA" = "REDE BAIXA",
  "REDE ENERGIZADA" = "REDE ENERGIZADA",
  "ROEDOR-INSETO" = "ROEDOR-INSETO",
  "ROMPIDO" = "ROMPIDO",
  "SWEEP-ALINHAMENTO" = "SWEEP-ALINHAMENTO",
  "TROCA DE POSTE" = "TROCA DE POSTE",
  "TAP" = "TAP"
}
