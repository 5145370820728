<div class="row">
  <div class="header-page d-none d-lg-block">
		<div class="row h-20 d-flex flex-row-reverse">      
      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="states"
          title="Estados"							
          [dropdownList]="data.value?.getFilters().states"
          [selectedItems]="selectedItems.value.states"
          [itemsShowLimit]="3"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div> 

      <div class="col-lg-2 col-md-2">
        <app-select-default		        
          key="causes"
          title="Causas"		
          [dropdownList]="['Vandalismo']"
          [selectedItems]="['Vandalismo']"
          [itemsShowLimit]="2"
          isDisabled="true"
          (event)="onChange($event)"	
        >
        </app-select-default>
      </div>    

      <div class="col-lg-4 col-md-4">
        <app-data-picker-default				 
          [dates]="getRangeDate()" 
          (event)="onChange($event)"	
        >
        </app-data-picker-default>
      </div>

		</div> <!-- selects filters -->
	
		<div class="row px-0 d-flex flex-row-reverse">
			<div class="col-lg-3 col-xxl-2">
        <app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
          <app-card-default
            [icon]="faHeadset"
            [cardTitle]="'Número de chamados'"
            [cardValue]="data.value?.getCards().ticketsQuantity"
          >
          </app-card-default>
        </app-request-wrapper>
			</div>

			<div class="col-lg-3 col-xxl-2">
        <app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
        >
          <app-card-default
            [icon]="faCalendarDay"
            [cardTitle]="'Qtd dias monitorados'"
            [cardValue]="data.value?.getCards().monitoredDays"
          >
          </app-card-default>
        </app-request-wrapper>
			</div>

			<div class="col-lg-3 col-xxl-2">
        <app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
        >
          <app-card-default
            [icon]="faBell"
            [cardTitle]="'Numero de alertas'"
            [cardValue]="data.value?.getCards().alertsQuantity"
          >
          </app-card-default>
        </app-request-wrapper>
			</div>
		</div> <!-- cards -->
	</div> <!-- * HEADER PAGE DESKTOP -->

  <div class="header-page-mobile d-block d-sm-none">
		<div class="filter-mobile">
			<button 
				class="btn-default"				
			>
				Filtros
				<span class="fa fa-sliders"></span>
			</button>
		</div>
		<div>
			<div>				
				<ngx-slick-carousel 
					class="slick-slider slick-dotted" 
					#slickModal="slick-carousel" 
					[config]="slideConfig"
				>
					<div ngxSlickItem >
						<div class="col-10 mx-auto">
              <app-request-wrapper
                [class]="requestWrapperConfig.cardStyle"
                [isLoading]="loading"
                [isError]="error"
              >
                <app-card-default
                  [icon]="faHeadset"
                  [cardTitle]="'Número de chamados'"
                  [cardValue]="data.value?.getCards().ticketsQuantity"
                >
                </app-card-default>
              </app-request-wrapper>
						</div>			
          </div>
					<div ngxSlickItem >
						<div class="col-10 mx-auto">
              <app-request-wrapper
                [class]="requestWrapperConfig.cardStyle"
                [isLoading]="loading"
                [isError]="error"
              >
                <app-card-default
                  [icon]="faCalendarDay"
                  [cardTitle]="'Qtd dias monitorados'"
                  [cardValue]="data.value?.getCards().monitoredDays"
                >
                </app-card-default>
              </app-request-wrapper>
						</div>	
          </div>		
					<div ngxSlickItem >
						<div class="col-10 mx-auto">
              <app-request-wrapper
                [class]="requestWrapperConfig.cardStyle"
                [isLoading]="loading"
                [isError]="error"
              >
                <app-card-default
                  [icon]="faBell"
                  [cardTitle]="'Numero de alertas'"
                  [cardValue]="data.value?.getCards().alertsQuantity"
                >
                </app-card-default>
              </app-request-wrapper>
						</div>			
					</div>
				</ngx-slick-carousel>
			</div>			
		</div>
	</div> <!-- * HEADER PAGE MOBILE -->

  <div class="container-alert row mx-auto px-0">
    <div class="col-12 p-1" 
      style="height: 50%;"
    >
      <app-request-wrapper
        [class]="requestWrapperConfig.cardStyle"
        [isLoading]="loading"
        [isError]="error"
      >
        <app-call-control-per-day
          [data]="data.value?.getCallControlPerDay()" 
          [colors]="colors"       
        ></app-call-control-per-day>
      </app-request-wrapper>
    </div>
    <div class="col-12 p-1" 
      style="height: 50%;"
    >
      <app-table-default
        [data]="data.value?.getCallControlStates()"
      ></app-table-default>
    </div>
  </div>
</div>