import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IColors } from 'src/app/interfaces';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-card-default',
  templateUrl: './card-default.component.html',
  styleUrls: []
})
export class CardDefaultComponent implements OnInit {

  @Input() icon: IconDefinition;
  @Input() cardTitle: string;
  @Input() cardValue: number;
  @Input() isLargeFont: boolean = true;

  colors: IColors;

  constructor() {}

  ngOnInit(): void {}

}
