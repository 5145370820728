<div class="d-flex">
  <div class="header-btn-lg pe-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left">
          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-link p-0 me-2" ngbDropdownToggle>
              <span>
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                  <img width="42" src="./assets/images/avatars/userProfile.png" alt="" class="rounded-circle">
                </div>
              </span>
            </button>
            <div class="dropdown-menu-lg" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner bg-info">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-start">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left me-3">
                          <img width="42" src="./assets/images/avatars/userProfile.png" alt="" class="rounded-circle">
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{ fullname }}</div>
                          <div class="widget-subheading opacity-8">{{ email }}</div>
                        </div>
                        <div class="widget-content-right me-2">
                          <button
                            class="btn-pill btn-shadow btn-shine btn btn-focus"
                            (click)="logout()"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="scroll-area-xs">
                <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column">
                    <li class="nav-item-header nav-item">Activity</li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Chat
                        <div class="ms-auto badge rounded-pill bg-info">8</div>
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Recover Password</a></li>
                    <li class="nav-item-header nav-item">My Account</li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Settings
                        <div class="ms-auto badge bg-success">New</div>
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Messages
                        <div class="ms-auto badge bg-warning">512</div>
                      </a></li>
                    <li class="nav-item"><a [routerLink]="" class="nav-link">Logs</a></li>
                  </ul>
                </perfect-scrollbar>
              </div>
              <ul class="nav flex-column">
                <li class="nav-item-divider mb-0 nav-item"></li>
              </ul>
              <div class="grid-menu grid-menu-2col overflow-hidden">
                <div class="g-0 row">
                  <div class="col-sm-6">
                    <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning">
                      <i class="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i>
                      Message Inbox
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                      <i class="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i>
                      <b>Suppor Tickets</b>
                    </button>
                  </div>
                </div>
              </div>
              <ul class="nav flex-column">
                <li class="nav-item-divider nav-item"></li>
                <li class="nav-item-btn text-center nav-item">
                  <button class="btn-wide btn btn-primary btn-sm">Open Messages</button>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
        <!-- <div class="widget-content-left ms-3 header-user-info">
          <div class="widget-heading">Robert Murdoch</div>
          <div class="widget-subheading">Angular Developer</div>
        </div>
        <div class="widget-content-right header-user-info ms-3">
          <button type="button" class="btn btn-shadow p-1 btn-info btn-sm" placement="bottom"
            ngbTooltip="Tooltip Example!">
            <fa-icon [icon]="faCalendar" class="me-1 ms-1"></fa-icon>
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="header-btn-lg">
    <button type="button" class="hamburger hamburger--elastic" [ngClass]="{'is-active' : globals.toggleDrawer}"
      (click)="toggleDrawer()">
      <span class="hamburger-box"><span class="hamburger-inner"></span></span>
    </button>
  </div> -->
</div>