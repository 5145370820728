import { Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AccessControl, DetailLog } from "src/app/interfaces/dtos/AccessControl";
import { initialDataDefault } from "../utils/constants";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";
import { catchError } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: 'app-logs-control',
  templateUrl: './logs-popup.component.html',
  styleUrls: []
})
export class LogsPopupComponent implements OnInit {

  @Input() userInfo: { userId: number, username: string };
  @Input() activeFilters: AccessControl.Params;
  data: BehaviorSubject<AccessControl.Response<DetailLog[]>> = new BehaviorSubject(initialDataDefault);
  loading: boolean = true;
  error: boolean = false;
  hasLogs: boolean = true;

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.loadLogs();
  }

  loadLogs() {
    this.userService.getLogsByUser(this.userInfo.userId, this.activeFilters)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return [];
        })
      )
      .subscribe((response) => {
        const data = response.data.map((d) => ({ date: d[0], operation: d[1], toPage: d[2], fromPage: d[3] }));
        this.loading = false
        this.error = false;
        this.hasLogs = response.data.length > 0;
        this.data.next({ ...response, data });
      });
  }

  close() {
    this.activeModal.close();
  }
}