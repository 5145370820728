export enum Permission {
  ADMIN=0,

  VANDALISMO=1,
  VANDALISMO_GERAL=101,
  VANDALISMO_DETALHAMENTO=102,
  VANDALISMO_ALERTA=103,
  VANDALISMO_ROTA=104,

  BATERIA=2,
  BATERIA_GERAL=201,

  ANALITICO=3,
  ANALITICO_FULLSCREEN=301,

  IMAGE=4,
  IMAGE_VERIFICATION=401,

  OPERATION=5,  
  INVENTORY=6
}

export const stringToPermission = (perm: string): number => {
  const formattedPerm = perm.toUpperCase();
  return Permission[formattedPerm as keyof typeof Permission];
}

export const permissionToString = (permValue: number): string => {
  const permissionKeys = Object.keys(Permission).filter(key => !isNaN(Number(Permission[key])));
  const matchedKey = permissionKeys.find(key => Permission[key] === permValue);
  return matchedKey ? matchedKey : '';
}

const options = [
  { name: 'Vandalismo', value: 1 },
]

export const getPermissionOptions = () => {
  return options;
}