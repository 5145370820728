import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { IColors } from '../../interfaces';

@Component({
  selector: 'app-call-comparison-by-state-bar-chart',
  templateUrl: './call-comparison-by-state-bar-chart.component.html',
  styleUrls: []
})
export class CallComparisonByStateBarChartComponent implements OnInit, OnChanges {
 
  title = 'ABERTURA DE CHAMADOS vs QTDE PEADs POR ESTADO'  
  @Input() data: { labels: string[], dataset: number[][] };
  @Input() colors: IColors;

  barChartData;
  type: ChartType = 'horizontalBar';
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
      padding: {
        top: -5,    // Margem superior
        left: -5,   // Margem esquerda
        right: 5,  // Margem direita
        bottom: -5  // Margem inferior
      }
    },
    scales: 
    { 
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        }
      }], 
      yAxes: [{
        ticks: {
         // maxTicksLimit: 4,
          beginAtZero: true
        },
        gridLines: {
          display: false
        },
      }] 
    },    
    tooltips:{      
      callbacks:{
        label: function(tooltipItem, data){                      
          let labelsArray = [];
          let dataset1 = data.datasets[0];
          let dataset2 = data.datasets[1];
        
          if (tooltipItem.datasetIndex === 0) {
            // Quando o mouse está sobre o dataset 1, mostra a quantidade de chamados
            labelsArray.push(`qtde de chamados: ${dataset1.data[tooltipItem.index]}`);
          } else {
            // Quando o mouse está sobre o dataset 2, mostra a quantidade de pad's
            labelsArray.push(`qtde de pead's: ${dataset2.data[tooltipItem.index]}`);
          }
          
          return labelsArray  
        }
      },
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'right',
        color: 'black',
        font: {
          weight: 'bold',
          size: 11
        },
        formatter: (value) => {
          return value > 50 ? value : '';
        }
      },
    }
  }; 

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createHorizontalBarChart()
  }

  createHorizontalBarChart() {
    this.barChartData = {
      datasets: [
        {
          data: this.data.dataset[0] ?? [],
          type: 'horizontalBar',
          fill: Array(20).fill(this.colors.primaryColor),
          backgroundColor: Array(20).fill(this.colors.primaryColor),
          hoverBackgroundColor: Array(20).fill(this.colors.hoverPrimaryColor),
          borderColor: this.colors.primaryColor
        }, {
          data: this.data.dataset[1] ?? [],
          type: 'horizontalBar',
          backgroundColor: Array(20).fill(this.colors.secondaryColor),
          hoverBackgroundColor: Array(20).fill(this.colors.secondaryColor),
          borderColor: this.colors.secondaryColor
      }],
      labels: this.data.labels
    }
  };
}
