import { Injectable, SecurityContext } from '@angular/core';
import { jsPDF } from 'jspdf';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {

  constructor(private sanitizer: DomSanitizer) { }

  async generatePdf(data: any, images: SafeUrl[], ref: number) {
    const pdf = new jsPDF();

    pdf.setFontSize(16);
    pdf.text("Relátorio de imagem", 105, 15, { align: 'center' });
    pdf.setFontSize(10); 
    this.drawTable(pdf, data);

    const promises = images.map((imgUrl, index) => {
      return this.addImageToPdf(pdf, imgUrl, index, images.length - 1);
    });

    await Promise.all(promises);

    pdf.save(`ticket-info-${ref}.pdf`);
  }

  async generatePdfInventory(data: any, images: SafeUrl[], ref: number) {
    const pdf = new jsPDF();

    pdf.setFontSize(16);
    pdf.text("Relátorio de Atividade Preventiva", 105, 15, { align: 'center' });
    pdf.setFontSize(10); 
    this.drawTable(pdf, data);

    const promises = images.map((imgUrl, index) => {
      return this.addImageToPdf(pdf, imgUrl, index, images.length - 1);
    });

    await Promise.all(promises);

    pdf.save(`ticket-info-${ref}.pdf`);
  }

  private drawTable(pdf: jsPDF, data: { label: string, value: string | number }[]) {
    const startX = 10;
    const startY = 30; // Ajustar para baixo para dar espaço ao título
    const rowHeight = 12; // Mais espaço entre linhas
    const colWidth = 90;

    // Cabeçalho da tabela
    pdf.setFillColor(200, 200, 200); // Cor cinza claro para o cabeçalho
    pdf.rect(startX, startY, colWidth * 2, rowHeight, 'F'); // Fundo do cabeçalho
    pdf.text("Campos", startX + 5, startY + 8);
    pdf.text("Valores", startX + colWidth + 5, startY + 8);

    // Dados da tabela
    data.forEach((item, index) => {
      const y = startY + ((index + 1) * rowHeight); // Ajustar índice para começar após o cabeçalho
      pdf.text(item.label, startX + 5, y + 8);
      pdf.text(String(item.value), startX + colWidth + 5, y + 8);
    });
  }

  private async addImageToPdf(pdf: jsPDF, imgUrl: SafeUrl, index: number, limit: number) {
    // Utilize o DomSanitizer para converter SafeUrl em uma URL comum
    const regularUrl = this.sanitizer.sanitize(SecurityContext.URL, imgUrl) as string;

    // Fetch e leitura da imagem
    const response = await fetch(regularUrl);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise<void>((resolve, reject) => {
      reader.onload = () => {
        const dataUrl = reader.result as string;
        if (index === 0) { // Se for a primeira imagem, adicionar uma nova página
          pdf.addPage();
        }

        const imageHeight = 50; // Altura ajustada
        const imageWidth = 50; // Largura ajustada
  
        // Cálculo de posicionamento para três imagens por linha
        const imagesPerRow = 3;
        const rowNumber = Math.floor(index / imagesPerRow);
        const columnNumber = index % imagesPerRow;
  
        const positionX = 10 + (imageWidth + 10) * columnNumber; // 10 é a margem entre imagens
        const positionY = 20 + (imageHeight + 20) * rowNumber; // 20 é a margem vertical entre imagens
        
        pdf.addImage(dataUrl, 'JPEG', positionX, positionY, imageWidth, imageHeight);

        resolve();
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }
}
