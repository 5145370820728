import { ControlPosition, LatLngBounds, LatLngLiteral } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { IColors, ITicket, VandalismFullscreen } from 'src/app/interfaces';
import { TicketPopUpComponent } from '../ticket-pop-up/ticket-pop-up.component';
import { FilterPopUpComponent } from 'src/app/components/filter-pop-up/filter-pop-up.component';
import { Formatter } from 'src/app/utils/formatter.util';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { VandalismService } from 'src/app/services/vandalism.service';
import { GenerateDate } from 'src/app/utils/generateDate.util';

const initialFilters = {
  causeGroups: ['VANDALISMO'],
  states: [],
  clusters: [],
  directors: [],
  regionals: []
}

@Component({
  selector: 'app-fullscreen-map',
  templateUrl: './fullscreen-map.component.html',
  styleUrls: []
})
export class FullscreenMapComponent implements OnInit {

  NAMESPACE: Namespace = 'fullscreen';

  @Input() markers: ITicket[] = [];
  @Input() namespace: Namespace;
  @Input() options = {
    withPopup: true,
    withFilters: true
  }

  displayedMarkers: LatLngLiteral[];

  data: BehaviorSubject<VandalismFullscreen.Output> = new BehaviorSubject(new VandalismFullscreen.Output());
  selectedItems: BehaviorSubject<VandalismFullscreen.Filters> = new BehaviorSubject(initialFilters);
  selectedDates: BehaviorSubject<{ startDate: Date, endDate: Date }>;

  loading: boolean = true;
  error: boolean = false;
  colors: IColors;

  mapConfig = {
    zoom: 4, // Google Maps zoom
    zoomControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    iconUrl: '/assets/maps/red-circle.svg',
    center: { // Posição inicial do mapa no centro do Brasil.
      lat: -15.8517, // Latitude
      lng: -48.5799 // Longitude
    }
  }

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private filterService: FilterService,
    private vandalismService: VandalismService
  ) {

  }

  ngOnInit(): void {
    let defaultActiveFilters = {
      startDate: '',
      endDate: '',
      states: '',
      directors: '',
      clusters: '',
      regionals: '',
    };

    const filters: VandalismFullscreen.Filters = { ...initialFilters };
    const dates: { startDate: Date, endDate: Date } = { startDate: null, endDate: null };

    this.filterService.getFiltersObservable({ namespace: this.namespace })
      .subscribe((activeFilters: Object) => {
        for (let key in activeFilters) {
          if (defaultActiveFilters[key] !== undefined) {
            if (key !== 'startDate' && key !== 'endDate') {
              filters[key] = activeFilters[key] === '' ? [] : activeFilters[key].split(',');
            } else {
              // TODO: Warning
              const [day, month, year] = activeFilters[key].split('/');
              dates[key] = new Date(`${month}/${day}/${year}`);
            }
            defaultActiveFilters[key] = activeFilters[key];
          }
        }
    });

    this.selectedDates = new BehaviorSubject(dates);
    this.selectedItems.next(filters);

    this.filterService.setDefaultFilters<VandalismFullscreen.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<VandalismFullscreen.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => {
        this.fetchData(activeFilters)
      });
  }

  ngOnDestroy(): void {
    this.filterService.clearNamespace({ namespace: this.NAMESPACE });
    this.data.unsubscribe();
    this.selectedDates.unsubscribe();
    this.selectedItems.unsubscribe();
  }

  getRangeDate() {
    const { startDate, endDate } = GenerateDate.getDates();
    return {
      startDate,
      endDate,
    }
  }

  fetchData(activeFilters: VandalismFullscreen.Params) {
    return this.vandalismService.getVandalismFullscreenData(activeFilters)
      .subscribe((response) => {
        this.loading = false;
        this.error = false;
        this.data.next(new VandalismFullscreen.Output(response));
      }, (error) => {
        this.loading = false;
        this.error = true;
      });
  }

  onChange(event: string) {
    const [key, values] = event.split(':');
    this.filterService.updateFilters<VandalismFullscreen.Params>({
      namespace: this.NAMESPACE,
      key,
      values,
    });
  }

  createCustomWindowed() {
    const svgImage = document.createElement("img");
    svgImage.src = "assets/maps/fullscreen_desativado.svg";
    svgImage.width = 22;
    svgImage.height = 22;
    const controlButton = document.createElement("button");
    controlButton.type = "button"
    controlButton.title = "Desativar o fullscreen do mapa";
    controlButton.setAttribute('class', 'control-button');
    controlButton.appendChild(svgImage);
    controlButton.addEventListener('click', () => this.closeModal());
    return controlButton;
  }

  treatTooltip(marker: ITicket) {
    const id = marker.ticketId;
    const date = Formatter.formatReportedDate(marker.reportedDate);
    const operation = marker.operational ?? ' ';
    return `ID: ${id}\nData: ${date}\nOperação: ${operation}`
  }

  openTicketPopUp(ticket: ITicket) {
    const modalRef = this.modalService.open(TicketPopUpComponent, { size: 'lg' });
    modalRef.componentInstance.modalData = ticket;
  }

  openFiltersPopUp() {
    this.modalService.open(FilterPopUpComponent, { size: 'lg' });
  }

  onMapReady(map: { controls: HTMLDivElement[][]; }) {
    const customControlDiv = document.createElement('div');
    const customFullScreenControl = this.createCustomWindowed();
    customControlDiv.appendChild(customFullScreenControl);
    map.controls[ControlPosition.TOP_RIGHT].push(customControlDiv);
  }

  closeModal() {
    this.activeModal.close();
  }
}
