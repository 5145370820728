<div class="modal-header mb-2">
  <h4 class="modal-title">Importar Usuários</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('cancel')"></button>
</div>

<div class="modal-body">
  <!-- Mensagem de instrução -->
  <p class="mb-2">
    Para importar os usuários, siga os passos abaixo: baixe o modelo, preencha-o com os dados necessários e, em seguida, selecione o arquivo para carregá-lo.
  </p>

  <div class="d-flex flex-row align-items-center">
    <!-- Botão para baixar o modelo -->
    <button type="button" class="btn btn-secondary mb-3" (click)="downloadTemplate()">
      Baixar Modelo
    </button>

    <!-- Input para selecionar o arquivo -->
    <input id="file" (change)="onFileSelected($event)" type="file" class="file-input mb-3" />
  </div>

  <!-- Indicador de carregamento -->
  <div *ngIf="loading" class="text-center pt-4">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <!-- Resultados da importação -->
  <div class="pt-4" *ngIf="!loading && response">
    <h5>Resultados da Importação:</h5>
    <ul>
      <li><strong>Importados:</strong> {{ response.imported }}</li>
      <li><strong>Erros:</strong> {{ response.errors }}</li>
      <li><strong>Mensagens de Erro:</strong> 
        <ul>
          <li *ngFor="let message of response.errorMessages">{{ message }}</li>
        </ul>
      </li>
      <li><strong>E-mails Existentes no Banco de Dados:</strong> 
        <ul>
          <li *ngFor="let email of response.existingDatabaseEmails">{{ email }}</li>
        </ul>
      </li>
      <li><strong>Duplicados no Banco de Dados:</strong> {{ response.databaseDuplicates }}</li>
      <li><strong>E-mails Duplicados na Tabela:</strong> 
        <ul>
          <li *ngFor="let email of response.tableDuplicatedEmails">{{ email }}</li>
        </ul>
      </li>
      <li><strong>Duplicados na Tabela:</strong> {{ response.tableDuplicates }}</li>
      <li><strong>Data da Importação:</strong> {{ response.importDate }}</li>
    </ul>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" [disabled]="loading" (click)="importUsers()">Importar</button>
</div>
