import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { INotification, IAlert } from 'src/app/interfaces';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent implements OnInit {

  notifications: INotification[] = [
    {
      date: new Date(),
      caption: 'Extração de dados',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true
    },
    {
      date: new Date(),
      caption: 'Cálculos Datadiff',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true,
    },
    {
      date: new Date(),
      caption: 'Cálculos de rondas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true,
    },
    {
      date: new Date(),
      caption: 'Cálculos de alertas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: false,
    },
    {
      date: new Date(),
      caption: 'Cálculos de rondas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true,
    },
    {
      date: new Date(),
      caption: 'Cálculos de alertas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: false,
    },
    {
      date: new Date(),
      caption: 'Cálculos de rondas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true,
    },
    {
      date: new Date(),
      caption: 'Cálculos de alertas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: false,
    },
    {
      date: new Date(),
      caption: 'Cálculos de rondas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true,
    },
    {
      date: new Date(),
      caption: 'Cálculos de alertas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: false,
    },
    {
      date: new Date(),
      caption: 'Cálculos de rondas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true,
    },
    {
      date: new Date(),
      caption: 'Cálculos de alertas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: false,
    },
    {
      date: new Date(),
      caption: 'Cálculos de rondas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: true,
    },
    {
      date: new Date(),
      caption: 'Cálculos de alertas',
      content: 'Informações detalhadas sobre o estado e o bem-estar do sistema de saúde',
      isSuccess: false,
    },
  ];

  alerts: IAlert[] = [
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'São Paulo',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'São Paulo',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Bateria',
      state: 'SP',
      city: 'Campinas',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'RJ',
      city: 'Rio de Janeiro',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'Diadema',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Bateria',
      state: 'RJ',
      city: 'Niterói',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'São Paulo',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'São Paulo',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Bateria',
      state: 'SP',
      city: 'São Paulo',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'RJ',
      city: 'Rio de Janeiro',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'Diadema',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Bateria',
      state: 'RJ',
      city: 'Niterói',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'São Paulo',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Vandalismo',
      state: 'SP',
      city: 'São Paulo',
    },
    {
      date: new Date().toLocaleDateString(),
      cause: 'Bateria',
      state: 'SP',
      city: 'São Paulo',
    },
  ];
  
  constructor(private authService: AuthService) {}

  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  ngOnInit(): void {
  }

}
