import { CauseOption } from "src/app/interfaces/enums/CauseOption";

export const mapCauseOption = (cause: string): CauseOption | null => {
  const mapper: { [key: string]: CauseOption } = {
    'VANDALISMO': CauseOption['VANDALISMO'],
    'PEAD': CauseOption['PEAD'],
    'OBRAS': CauseOption['OBRAS'],
    'TROCA DE POSTE': CauseOption['TROCA DE POSTE'],
    'CURTO CIRCUITO': CauseOption['CURTO-CIRCUITO'],
    'CARGA ALTA': CauseOption['CARGA ALTA'],
    'ACIDENTE DE TRANSITO': CauseOption['ACIDENTE DE TRANSITO'],
    'ATIVACAO DE LINK': CauseOption['ATIVACAO DE LINK'],
    'BACKBONE': CauseOption['BACKBONE'],
    'CABO': CauseOption['CABO'],
    'COM AGUA': CauseOption['COM AGUA'],
    'CURTO-CIRCUITO': CauseOption['CURTO-CIRCUITO'],
    'DANO PARCIAL': CauseOption['DANO PARCIAL'],
    'DESVIO DE FIBRA': CauseOption['DESVIO DE FIBRA'],
    'EXIGENCIAS LEGAIS': CauseOption['EXIGENCIAS LEGAIS'],
    'FALHA DA CONTRATADA': CauseOption['FALHA DA CONTRATADA'],
    'FIBRA': CauseOption['FIBRA'],
    'FONTE': CauseOption['FONTE'],
    'IMPLANTACAO': CauseOption['IMPLANTACAO'],
    'INCENDIO': CauseOption['INCENDIO'],
    'INSPECAO VISUAL': CauseOption['INSPECAO VISUAL'],
    'LEAKAGE': CauseOption['LEAKAGE'],
    'LIMPEZA MASSIVA DE RUIDO': CauseOption['LIMPEZA MASSIVA DE RUIDO'],
    'LINHA DE PIPA': CauseOption['LINHA DE PIPA'],
    'MAU CONTATO': CauseOption['MAU CONTATO'],
    'MODULO': CauseOption['MODULO'],
    'NIVEIS FORA DO PADRAO': CauseOption['NIVEIS FORA DO PADRAO'],
    'OUTRAS OPERADORAS': CauseOption['OUTRAS OPERADORAS'],
    'PASSIVO OPTICO': CauseOption['PASSIVO OPTICO'],
    'PODA DE ARVORE': CauseOption['PODA DE ARVORE'],
    'QUEBRADO': CauseOption['QUEBRADO'],
    'QUEDA DE ARVORE': CauseOption['QUEDA DE ARVORE'],
    'QUEDA DE ENERGIA': CauseOption['QUEDA DE ENERGIA'],
    'QUEIMADO': CauseOption['QUEIMADO'],
    'REDE BAIXA': CauseOption['REDE BAIXA'],
    'REDE ENERGIZADA': CauseOption['REDE ENERGIZADA'],
    'ROEDOR-INSETO': CauseOption['ROEDOR-INSETO'],
    'ROMPIDO': CauseOption['ROMPIDO'],
    'SWEEP-ALINHAMENTO': CauseOption['SWEEP-ALINHAMENTO'],
    'TAP': CauseOption['TAP']
  };

  return mapper[cause] || null;
}
