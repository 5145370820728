import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { STATUS_CODES } from 'http';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IColors, VandalismRoute } from 'src/app/interfaces';
import { RequestResult, RequestStatus, RequestType } from 'src/app/interfaces/RequestContext';
import { AnalyticalService } from 'src/app/services/analytical.service';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { ThemeService } from 'src/app/services/theme.service';
import { VandalismService } from 'src/app/services/vandalism.service';
import { Formatter } from 'src/app/utils/formatter.util';
import { icons } from 'src/app/utils/icons';

@Component({
  selector: 'app-rota',
  templateUrl: './rota.component.html',
  styleUrls: []
})
export class RotaComponent implements OnInit {
  NAMESPACE: Namespace = 'route'
  data: BehaviorSubject<VandalismRoute.Output> = new BehaviorSubject(new VandalismRoute.Output());
  activeFilters: BehaviorSubject<VandalismRoute.Params> = new BehaviorSubject({
    routeId: '',
    directors: '',
    states: '',
    cities: '',
    families: ''
  });
  selectedItems: BehaviorSubject<VandalismRoute.Filters> = new BehaviorSubject(VandalismRoute.filterInitialState);
  
  loading: boolean = false;
  error: boolean = false;
  submit: boolean = true;
  submitContent: string = 'Selecione uma rota'
  colors: IColors;
  icons = icons
  requestWrapperConfig = {
    cardStyle: 'widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary card',
    cardChart: 'card-chart route-map',
    cardTable: 'card-chart'
  }

  constructor(
    private vandalismService: VandalismService,
    private themeService: ThemeService,
    private filterService: FilterService,
    private snackBar: MatSnackBar,
    private analyticalService: AnalyticalService
  ) {
    this.colors = this.themeService.getColors();
  
    const defaultActiveFilters: VandalismRoute.Params = {
      directors: '',
      states: '',
      cities: '',
      routeId: '',
      families: 'EMERGENCIAL,RESIDENCIAL'
    }

    this.filterService.setDefaultFilters<VandalismRoute.Params>({
      namespace: this.NAMESPACE,
      defaultFilters: defaultActiveFilters
    });

    this.filterService.getFiltersObservable<VandalismRoute.Params>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((activeFilters) => this.fetchData(activeFilters));
  }

  ngOnInit(): void { }

  fetchData(activeFilters: VandalismRoute.Params) {

    return this.vandalismService.getVandalismRouteData(activeFilters)
      .subscribe((response) => {
        const isRouteSelected = activeFilters.routeId === '';
        this.loading = false;
        this.error = false;
        this.submit = isRouteSelected;
        this.activeFilters.next(activeFilters);

        const { dropdownList, selectedList } = this.updateData(this.activeFilters);
        let newSelectedList = selectedList;
  
        if (!isRouteSelected) {
          newSelectedList = {
            directors: response.filters.directors,
            states: response.filters.states,
            cities: response.filters.cities,
            families: response.filters.families
          }
        }

        this.data.next(new VandalismRoute.Output({
          filters: response.filters,
          dropdownList: { ...dropdownList, routes: response.filters.routes },
          selectedList: newSelectedList,
          activeFilters,
          routeMap: response.routeMap,
          routeTable: response.routeTable
        }));
      }, (error) => {
        this.loading = false;
        this.submit = false;
        this.error = true;
      });
  }
  
  updateData(ac) {
    this.activeFilters = ac;
      
    const { directors, states, cities, families } = this.activeFilters.value;

    const filteredTickets = this.analyticalService.filterTicketsForRoutes({
      families: families,
      states: states,
      directors: directors,
      cities: cities,
      routeId: ''
    });

    const { dropdownList, selectedList } = this.analyticalService.getFiltersForRoutes({
      directors,
      states,
      cities,
      families,
      routeId: ''
    }, filteredTickets);
    
    return {
      dropdownList,
      selectedList
    }
  }

  getRouteName(dropdown: VandalismRoute.Dropdown) {
    return dropdown?.routes.map((r) => r.routeName).filter((value) => value !== null) ?? [];
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  isRouteSelected() {    
    return !(this.activeFilters.value.routeId === '');
  }

  onChange(event: string) {
    const [key, values] = event.split(':');

    let newValues = values;

    if (key === 'routeId' ) {
      const existRoute = this.data.value.dropdownList.routes.find(({ routeName }) => routeName === values);

      if (existRoute) {
        newValues = existRoute.routeId.toString();
      }
    }

    this.filterService.updateFilters<VandalismRoute.Params>({
      namespace: this.NAMESPACE,
      key,
      values: newValues,
    });
  }

  createRouteToa() {
    const { routeId, families } = this.activeFilters.value;

    const route: VandalismRoute.Route = this.data.value?.getRouteMap()?.expected
    if (!route) return;

    const response = this.vandalismService.createVandalismRoute({
      routeId: routeId,
      family: families,
      mapLink: this.buildGoogleMapsUrl(route),
      date: Formatter.formatDataPickerDate(new Date())
    });

    response.subscribe((r) => {
      this.openPopUpResponse({
        status: r.status,
        message: r.message,
        isError: !(RequestStatus.CREATED === r.status),
        styleClass: RequestStatus.CREATED === r.status ? 'success-bar-container' : 'failure-bar-container'
      });
    });
  }

  exportExcel(): void {
    const directors = this.selectedItems.value.directors.join(',');
    this.vandalismService.generateRoundReport({ directory: directors });
  }

  openMaps(): void {
    const route: VandalismRoute.Route = this.data.value?.getRouteMap()?.expected
    if (!route) return;
    const url = this.buildGoogleMapsUrl(route);
    window.open(url, '_blank');
  }

  buildGoogleMapsUrl(route: VandalismRoute.Route): string {
    let mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${route.origin.lat},${route.origin.lng}&destination=${route.destination.lat},${route.destination.lng}&travelmode=driving`;

    if (route.stops.length > 0) {
      const waypoints = route.stops.map((stop) => `${stop.lat},${stop.lng}`).join('|');
      mapUrl += `&waypoints=${waypoints}`;
    }

    return mapUrl;
  }
}
