import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImageVerificationNsp } from '../interfaces/dtos/ImageValidation';
import { ImageAnalytical } from '../interfaces/dtos/ImageAnalytical';

@Injectable({
  providedIn: 'root'
})

export class ImageVerificationService {

  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  getImages(input: ImageVerificationNsp.Params) {
    const url = `${this.baseUrl}/vandalism/image/validation`
    const output = this.httpClient.post<ImageVerificationNsp.Response>(url, input);
    return output;
  }

  getAnalyticalImage(input: ImageAnalytical.Params) {
    const url = `${this.baseUrl}/vandalism/image/validation/analytical`
    const output = this.httpClient.post<ImageAnalytical.Response>(url, input);
    return output;
  }

  getTicketsById(sequenceId: string) {
    const params = new HttpParams().set('sequenceId', sequenceId);
    const url = `${this.baseUrl}/vandalism/analytical/tickets/search`
    return this.httpClient.get(url, { params });
  }
}
