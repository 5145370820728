<div class="container-mapa-operacoes">

  <div *ngIf="initialLoad"  class="loading-screen">
    <div class="loading-animation">
      <div class="spinner-container">        
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>

  <!-- * HEADER FILTER PAGE -->

  <div 
    class="container-header-page full-screen" 
    [class.bg-transition]="isDivVisible" 
    #headerFilter
  >
    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()"
        alt="logo da claro"
      >
    </div>

    <div 
      class="container-header-filters col-10" 
      [@slideInOut]="isDivVisible ? 'in' : 'out'"
    >
      <div class="container-filters col-12 p-0">        
        <div class="col-2 col-xl-3">              
          <app-filter-default 
            key="family"
            filterTitle="família"
            [icon]="icons.building"
            [dropdownList]="dropdownFilters.value.family"
            (event)="onChange($event)"
          >
          </app-filter-default>
        </div>

        <div class="col-xl-4 col-xxl-3">
          <app-filter-default
            key="state"
            filterTitle="estado"
            [icon]="icons.building"
            [dropdownList]="dropdownFilters.value.states"
            (event)="onChange($event)"
          >
          </app-filter-default>          
        </div>

        <div class="col-2">
          <app-card-default
            [icon]="icons.danger"
            [cardTitle]="'N° de atividades pendentes'"
            [cardValue]="activitiesPending"
          ></app-card-default>

          <div class="container-new-search-input">
            <input 
              type="text" 
              class="new-search-input" 
              placeholder="Id da atividade"
              [(ngModel)]="searchTerm"             
              (input)="onSearch($event.target.value)" 
            >            
            <i 
              [ngClass]="searchTerm ? 'fa fa-times' : 'fa fa-search'"
              (click)="cleanInput()"
            ></i>
          </div>
        </div>

        <div class="col-2">
          <app-card-default
            [icon]="icons.clock"
            [cardTitle]="'N° de atividades iniciadas'"
            [cardValue]="activitiesStarted"
          ></app-card-default>
        </div> 
      </div><!-- *CONTAINER TOP HEADER -->
      <div class="col-2 search-containe">          
         
      </div>        
      <!-- <app-filter-default
        key="group"
        filterTitle="grupo"
        [icon]="icons.calendar"
        [dropdownList]="data.value.dropdownList.group"
        [selectedList]="data.value.selectedList.value.group"
        (event)="onChange($event)"
      >
      </app-filter-default>         -->      
    </div>

    <div class="col-1 icon-back-home" (click)="closeModal()">
      <img [src]="isDivVisible ? iconBackHomeWhite : iconBackHome" @fade-inout alt="back home">
    </div>
  </div>

  <!-- * LEGENDA MAPA OPERAÇÕES -->

  <div class="container-legend">
    <div class="legend-hidden">
      <i 
        [ngClass]="isLegendVisible  ? 'fa fa-chevron-left' : 'fa fa-chevron-right'"
        (click)="showLegend()"
        @fade-inout        
      ></i>
    </div>   

    <div *ngIf="isLegendVisible" class="legend-content" @fade-inout>
      <h2 class="title-legend">operacional</h2> 

      <div class="container-content-legend">
        <div class="content-legend">
          <img [src]="activityIcons.iconResStart" alt="" class="img-legend">          
          <span class="name-legend">residencial</span>
        </div>
        <div class="content-legend">
          <img [src]="activityIcons.iconEmpStart" alt="" class="img-legend">          
          <span class="name-legend">empresarial</span>
        </div>
        <div class="content-legend">
          <img [src]="activityIcons.iconActivitiesNot" alt="" class="img-legend">          
          <span class="name-legend">Sem Operacional</span>
        </div>
      </div>

      <h2 class="title-legend">status da atividade</h2> 

      <div class="container-content-legend">
        <div class="content-legend">
          <img [src]="activityIcons.iconResStart" alt="" class="img-legend">          
          <img [src]="activityIcons.iconEmpStart" alt="" class="img-legend">          
          <span class="name-legend">iniciada</span>
        </div>
        <div class="content-legend">
          <img [src]="activityIcons.iconResStop" alt="" class="img-legend">          
          <img [src]="activityIcons.iconEmpStop" alt="" class="img-legend">          
          <span class="name-legend">não iniciada</span>
        </div>
        <div class="content-legend">
          <img [src]="activityIcons.iconResRoute" alt="" class="img-legend">          
          <img [src]="activityIcons.iconEmpRoute" alt="" class="img-legend">          
          <span class="name-legend">em rota</span>
        </div>
      </div>

      <h2 class="title-legend">técnico</h2> 

      <div class="container-content-legend">
        <div class="content-legend">
          <img [src]="technicalIcons.iconTecInservice" alt="" class="img-legend">          
          <span class="name-legend">em serviço</span>
        </div>
        <div class="content-legend">
          <img [src]="technicalIcons.iconTecRefe" alt="" class="img-legend">          
          <span class="name-legend">Almoço</span>
        </div>                        
        <div class="content-legend">
          <img [src]="technicalIcons.iconTecAssistence" alt="" class="img-legend">          
          <span class="name-legend">apoio</span>
        </div>        
        <div class="content-legend">
          <img [src]="technicalIcons.iconTecOutservice" alt="" class="img-legend">          
          <span class="name-legend">fora de serviço</span>
        </div>        
        <div class="content-legend">
          <img [src]="technicalIcons.iconTecRoute" alt="" class="img-legend">          
          <span class="name-legend">em rota</span>
        </div>
        <!-- <div class="content-legend">
          <img [src]="mapConfig.iconTecPosition" alt="" class="img-legend">          
          <span class="name-legend">ultimas posições</span>
        </div> -->
      </div>
    </div>
  </div>

  <agm-map
    #agmMap  
    style="width: 100%; height: 100%;"
    [latitude]="mapConfig.latitude"
    [longitude]="mapConfig.longitude"
    [zoom]="mapConfig.zoom"
    [mapTypeId]="'roadmap'"
    [styles]="mapStyles"    
    [disableDefaultUI]="false"
    [zoomControl]="true"
    [maxZoom]="20"
    [minZoom]="4"
    [zoomControl]="mapConfig.zoomControl"
    [streetViewControl]="mapConfig.streetViewControl"
    [fullscreenControl]="mapConfig.fullscreenControl"
    (mapClick)="deselectActivity()"
    (mapReady)="onMapReady($event)"
  >    
    <ng-container *ngFor="let activity of (activities | async); let i = index; trackBy: trackByActivityId">
      <!-- * MARKER ATIVIDADE -->
      <agm-marker 
        *ngIf="activity.latitude && activity.longitude" 
        [title]="activity.activityId.toString()"
        [latitude]="activity.latitude" 
        [longitude]="activity.longitude" 
        [iconUrl]="getIconActivities(activity)"
        [clickable]="true"
        (markerClick)="selectActivity(activity, i)" 
      >          

        <!-- * TOOLTIP ATIVIDADE -->
        <agm-info-window [isOpen]="showInfoWindow[i]" [disableAutoPan]="true">        
          <div class="container-tootip-operations">
            <h2 class="title-tooltip">
              {{activity.xaProdCat1 ?? 'Sem Operacional'}} - {{activity.activityId}}
            </h2>
            <div class="container-contents">
              <div class="content-key">
                <span>id atividade</span>
                <span>status</span>
                <span>tipo de atividade</span>
                <span>id tecnico</span>
                <span>Nº REC/RAL</span>
              </div>
              <div class="content-value">
                <span>{{activity.activityId}}</span>
                <span>{{getStatusActivities(activity)}}</span>
                <span>{{activity.activityType}}</span>                
                <span>{{activity.resourceId}}</span>                
                <span>{{activity.xaOriginSystemTicket ?? 'Sem Número'}}</span>
              </div>              
            </div>
          </div>              
        </agm-info-window>
      </agm-marker>

      <!-- * MARKER TECNICO -->
      <agm-marker 
        *ngIf="activity.lat && activity.lng" 
        [latitude]="activity.lat" 
        [longitude]="activity.lng" 
        [iconUrl]="getIconTechnicals(activity)"
        (markerClick)="onMarkerClick(activity.resourceId, i)"
        >        

        <!-- * TOOLTIP TECNICO -->
        <agm-info-window [isOpen]="showInfoWindow[i] || showInfoWindowAssistance[i]" [disableAutoPan]="true">        
          <div class="container-tootip-operations">
            <h2 class="title-tooltip">{{activity.resourceId}}</h2>
            <h4 
              *ngIf="techniciansAlert != ''" 
              class="alert-resourceid"
            >
              <img [src]="technicalIcons.iconTecOutservice" alt="">
              {{ techniciansAlert }}
            </h4>
            <div class="container-contents">
              <div class="content-key">
                <span>id atividade</span>
                <span>status</span>                                
                <span>Tipo da atividade</span>                                
              </div>
              <div class="content-value">
                <span>{{activity.activityId}}</span>
                <span>{{getStatusActivities(activity)}}</span> 
                <span>{{activity.activityType}}</span>                          
              </div>
            </div>
            <div *ngIf="activity.activityType === 'assistance'">
              <button class="btn-default-small" (click)="onMarkerClickAssistence(activity.resourceId, activity.xaMainActivity, i)">Rota de Assistência</button>
            </div>
          </div>              
        </agm-info-window>
      </agm-marker>        
    </ng-container>    
  </agm-map>      

  <!-- Modal de posição não encontrada -->
  <div class="container-modal-position" *ngIf="isErrorModalVisible" (click)="closeErrorModal()" @fade-inout>
    <div class="modal-content" @fade-inout>           
      <p>{{ errorMessage }}</p>
      <button class="btn-default" (click)="closeErrorModal()">Fechar</button>
    </div>
  </div>

</div>