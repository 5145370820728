import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { mountRequestResult, RequestResult } from "src/app/interfaces/RequestContext";
import { AuthService } from "src/app/services/auth.service";
import { IpAddressService } from "src/app/services/ip-address.service";
import { PublisherService } from "src/app/services/publisher.service";

@Component({
  selector: 'app-export-users-modal',
  templateUrl: './export-users-modal.component.html',
  styleUrls: ['./export-users-modal.component.scss']
})
export class ExportUsersModalComponent {

  exportForm: FormGroup;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private publisherService: PublisherService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private ipAddressService: IpAddressService
  ) {
    this.exportForm = this.fb.group({
      fileType: ['XLSX', Validators.required]
    });
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  exportUsers() {
    this.isLoading = true;

    if (this.exportForm.valid) {
      const formValues = this.exportForm.value;
      const { fullName: fullname, username, email, company: { code: companyCode } } = this.authService.getCurrentUser();
      const { ipAddress: requesterIp } = this.ipAddressService.getStoredIpAddress();
      const { fileType } = formValues;

      const input = {
        fullname,
        username,
        email,
        companyCode,
        requesterIp,
        fileType
      }

      this.publisherService.exportDocument(input).subscribe(
        (response) => {
          this.isLoading = false;
          const requestResult: RequestResult = mountRequestResult({
            request: { ...response, status: 200 },
            message: response.message,
            expectedStatus: 200
          });
          this.openPopUpResponse(requestResult);
        },
        (error) => {
          this.isLoading = false;
          const requestResult: RequestResult = mountRequestResult({
            request: { ...error },
            message: error.error.message,
            expectedStatus: 200
          });
          this.openPopUpResponse(requestResult);
        }
      )
    }
  }
}
