<div class="modal-header">
  <h5 class="modal-title">Exportar relatório de usuários</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('cancel')"></button>
</div>

<div class="modal-body">
  <form [formGroup]="exportForm">
    <div class="mb-3">
      <label for="fileType" class="form-label">Tipo de arquivo</label>
      <select id="fileType" formControlName="fileType" class="form-select">
        <option value="PDF">PDF</option>
        <option value="XLSX">Excel (XLSX)</option>
      </select>
    </div>
  </form>

  <div *ngIf="isLoading" class="text-center mt-3">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Carregando...</span>
    </div>
    <p class="mt-2">Exportando, por favor aguarde...</p>
  </div>
</div>

<div class="modal-footer">
  <button
      class="btn btn-primary"
      [disabled]="exportForm.invalid"
      (click)="exportUsers()"
    >
      Exportar
    </button>
</div>