<div class="modal-container">
  <!-- Header -->
  <div class="modal-header">
    <h2 class="modal-title" style="font-size: 1.2em">POLÍTICA DE PRIVACIDADE E CONDIÇÕES DE USO</h2>
  </div>

  <!--body-->
  <div class="modal-policy">
    <div class="policy" [innerHTML]="policy"></div>
  </div>
  
  <!-- footer -->
  <div class="modal-body">
    <div class="modal-footer">
      <form class="modal-checkbox">
        <input type="checkbox" [checked] = "buttonState" (click)="toggleButton()">
        <label for="checkbox" class="checkbox-label">Clique aqui para aceitar os termos</label>
      </form>
      <button class="btn btn-primary" [disabled]="!buttonState" (click)="savePolicy()">Salvar</button>
    </div>
  </div>
</div>
