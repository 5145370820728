import { ITicket } from "../models";

export namespace VandalismFullscreen {

  export interface Filters {
    causeGroups: string[];
    states: string[];
    clusters: string[];
    directors: string[];
    regionals: string[];
  }

  export interface Params {
    startDate: string;
    endDate: string;
    states: string;
    directors: string;
    clusters: string;
    regionals: string;
  }

  export interface Response {
    filters: Filters;
    ticketsForMap: ITicket[];
  }

  export const filterInitialState = {
    causeGroups: [],
    regionals: [],
    directors: [],
    clusters: [],
    states: []
  }

  export class Output {
    filters: Filters = filterInitialState
    ticketsForMap: ITicket[] = [];
  
    constructor(response?: Response) {
      if (response) {
        this.filters = response.filters;
        this.ticketsForMap = response.ticketsForMap;
      }
    }
  
    getFilters() {
      return this.filters;
    }
  
    getTicketsForMap() {
      return this.ticketsForMap;
    }
  } 
}