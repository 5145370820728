import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITheme } from 'src/app/interfaces';
import { CreateThemePopUpComponent } from './components/create-theme-pop-up/create-theme-pop-up.component';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: []
})
export class PreferencesComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
  ) { }

  themesDefault: ITheme[] = [
    {
      id: 1,
      title: 'tema claro',
      lastChange: '09/12/2023 16:12',
      primaryColor: 'red',
      secondaryColor: 'black',
      isSelected: false,
      isDefault: true,
      logo: '',
    },
    {
      id: 2,
      title: 'tema escuro',
      lastChange: '09/12/2023 15:26',
      primaryColor: 'red',
      secondaryColor: 'white',
      isSelected: true,
      isDefault: true,
      logo: '',
    }
  ];

  themesCustom: ITheme[] = [
    {
      id: 3,
      title: 'tema claro',
      lastChange: '09/12/2023 16:12',
      primaryColor: 'red',
      secondaryColor: 'black',
      isSelected: false,
      isDefault: true,
      logo: '',
    },
    {
      id: 4, 
      title: 'tema escuro',
      lastChange: '09/12/2023 15:26',
      primaryColor: 'red',
      secondaryColor: 'white',
      isSelected: false,
      isDefault: true,
      logo: '',
    },
    {
      id: 5,
      title: 'tema claro',
      lastChange: '09/12/2023 16:12',
      primaryColor: 'red',
      secondaryColor: 'black',
      isSelected: false,
      isDefault: true,
      logo: '',
    },
  ];

  ngOnInit(): void {
  }

  addTheme() {
    this.modalService.open(CreateThemePopUpComponent, { windowClass: 'custom-modal-size' })
  }
}
