import { AnalyticalChart } from "../models/IAnalyticalChart";
import { initialAnalyticalChart } from "src/app/utils/constants";

export namespace AnalyticalHistogram {

  export type ViewScreen = 'histogram';

  export interface InputParams {
    years: string,
    months: string,
    days: string,
    causes: string,
    regionals: string,
    directors: string,
    states: string,
    subClusters: string,
    clusters: string,
    cities: string,
    families: string,
    nets: string
  }

  export interface Response {
    analyticalMonthByYears: AnalyticalChart,
    analyticalWeekdayByYears: AnalyticalChart,
    analyticalHourRangeByYears: AnalyticalChart,
    analyticalHourByYears: AnalyticalChart
  }

  export class Output {
    
    public analyticalMonthByYears = initialAnalyticalChart
    public analyticalWeekdayByYears = initialAnalyticalChart
    public analyticalHourRangeByYears = initialAnalyticalChart
    public analyticalHourByYears = initialAnalyticalChart
  
    constructor(response?: Response) {
      if (response) {
        this.analyticalMonthByYears = response.analyticalMonthByYears;
        this.analyticalWeekdayByYears = response.analyticalWeekdayByYears;
        this.analyticalHourRangeByYears = response.analyticalHourRangeByYears;
        this.analyticalHourByYears = response.analyticalHourByYears;
      }
    }
  }
}