import { getDateUtils, getDefaultFilters, initialValueChartInput } from "src/app/utils/interfaces/constants";
import { analyticalDropdownList, analyticalSelectedList } from "src/app/utils/constants";
import { BehaviorSubject } from "rxjs";
import { ITicket } from "../models";
import { months } from "src/app/utils/charts/constants";
import { CauseOption } from "../enums/CauseOption";

const { currentMonth, currentYear } = getDateUtils();

export const defaultActiveFilters = getDefaultFilters({
  month: currentMonth,
  year: currentYear,
  causeGroup: `${CauseOption.VANDALISMO},${CauseOption.PEAD}`
});

export namespace VandalismDetail {

  type FilterButton = Array<{ show: string, value: string }>;

  export interface Dropdown {
    year: FilterButton,
    day: FilterButton,
    month: FilterButton,
    family: FilterButton
    net: FilterButton,
    regional: FilterButton,
    group: FilterButton,
    state: string[],
    cluster: string[],
    subcluster: string[],
    city: string[],
    causeGroup: string[],    
  }

  export interface Selected {
    year: Set<string>,
    day: Set<string>,
    month: Set<string>,
    family: Set<string>
    net: Set<string>,
    regional: Set<string>,
    group: Set<string>,
    state: string[],
    cluster: string[],
    subcluster: string[],
    city: string[],
    causeGroup: string[],
  }

  export interface InputParams {
    year: string,
    day: string,
    month: string,
    family: string
    net: string,
    regional: string,
    state: string,
    group: string,
    cluster: string,
    subcluster: string,
    city: string,
    causeGroup: string,
    isPead: boolean,
    isAccumulated: boolean
  }

  export interface OutputParams {
    dropdownList: Dropdown;
    selectedList: BehaviorSubject<Selected>;
    activeFilters: VandalismDetail.InputParams;
    tickets: ITicket[];
  }

  // TODO: Remover isso;
  export const initialAnalyticalSelectedList = { ...analyticalSelectedList, causeGroups: new Set(['VANDALISMO', 'PEAD'])}

  // TODO: Remover isso;
  export const initialStateActiveFilters = defaultActiveFilters;

  export class Output {
    dropdownList: Dropdown = analyticalDropdownList;
    selectedList: BehaviorSubject<Selected> = new BehaviorSubject<Selected>(initialAnalyticalSelectedList);
    activeFilters: VandalismDetail.InputParams  = initialStateActiveFilters;
    tickets: ITicket[] = []

    comparisonCallsByState = initialValueChartInput
    comparisonCallsByMonth = initialValueChartInput;

    constructor(response?: OutputParams) {
      if (response) {
        this.dropdownList = response.dropdownList;
        this.activeFilters = response.activeFilters;
        this.tickets = response.tickets;
        this.selectedList = this.removePeadSelectedList(response.selectedList);
    
        this.comparisonCallsByState = this.generateComparisonCallsByState();
        this.comparisonCallsByMonth = this.generateComparisonCallsByMonth();
      }
    }

    removePeadSelectedList(selectedList) {
      return new BehaviorSubject({
        ...selectedList.value,
        causeGroup: selectedList.value.causeGroup.filter((cause) => cause !== 'PEAD')
      });
    }
  
    private generateComparisonCallsByState() {
      const stateCounts = this.tickets.reduce((acc, ticket) => {
        const key = ticket.state;

        if (!acc[key]) acc[key] = { peads: 0, tickets: 0 };

        if (ticket.causeGroup === "PEAD") {
          acc[key].peads += ticket.quantity;
        } else {
          acc[key].tickets += ticket.quantity;
        }
        return acc;
    
      }, {} as { [key: string]: { peads: number; tickets: number } });
  
      const sortedStates = Object.entries(stateCounts).sort((a, b) => {
        return (b[1].peads + b[1].tickets) - (a[1].peads + a[1].tickets);
      }).slice(0, 15);
  
      const labels = sortedStates.map(state => state[0]);
      const dataset: number[][] = [
        sortedStates.map(state => state[1].tickets),
        sortedStates.map(state => state[1].peads)
      ];

      return { labels, dataset };
    }
  
    private generateComparisonCallsByMonth() {
      const monthCounts = this.tickets.reduce((acc, ticket) => {
        const date = new Date(ticket.reportedDate);
        const monthYearKey = `${date.getMonth() + 1}/${date.getFullYear()}`;
  
        if (!acc[monthYearKey]) {
          acc[monthYearKey] = { peads: 0, tickets: 0 };
        }
  
        if (ticket.causeGroup === CauseOption.PEAD) {
          acc[monthYearKey].peads += ticket.quantity;
        } else {
          acc[monthYearKey].tickets += ticket.quantity;
        }
  
        return acc;
      }, {} as { [key: string]: { peads: number; tickets: number } });
  
      const sortedMonthYears = Object.entries(monthCounts)
        .sort((a, b) => {
          const [monthA, yearA] = a[0].split('/');
          const [monthB, yearB] = b[0].split('/');
          return yearA === yearB ? parseInt(monthA) - parseInt(monthB) : parseInt(yearA) - parseInt(yearB);
        })
        .map(([key, value]) => {
          const [month, year] = key.split('/');
          const formattedMonth = `${months[month]}/${year}`;
          return { key: formattedMonth, value };
        });

      const labels = sortedMonthYears.map(entry => entry.key);
      const dataset: number[][] = [
        sortedMonthYears.map(entry => entry.value.tickets),
        sortedMonthYears.map(entry => entry.value.peads)
      ];

      return { labels, dataset };
    }
  } 
}