<div class="card-chart chart-small-geral col-12 col-md-12 col-xxl-12">

  <app-request-wrapper
    class="chart-small-body-analytic"
    [isLoading]="loading"
    [isError]="error"
  >

    <div class="button-group">
      <button 
        class="toggle-btn"
        [class.active]="currentState.includes('EMPRESARIAL')"
        (click)="setState('EMPRESARIAL')"
      >
        Empresarial
      </button>
      <button 
        class="toggle-btn"
        [class.active]="currentState.includes('RESIDENCIAL')"
        (click)="setState('RESIDENCIAL')"
      >
        Residencial
      </button>
    </div>
        
    <div class="container-legends">
      <div class="legend-container" *ngFor="let legend of barAnalytic.legends; let i = index">
        <span class="symbol-legend"
          [ngStyle]="{ 'background-color': this.barAnalytic.colors[i] }"
        >
        </span>
        <span class="label-charts" style="font-size: smaller;">
          {{ legend }}
        </span>
      </div>
    </div>
  
    <div class="scrollable-container-x card-chart-body card-chart-body-vertical chart-small-body-analytic">
      <div 
        class="d-flex"
        style="height: 100%; position: relative;"
        [ngStyle]="{ 'width': barWidthProportion }"
      >
        <canvas
          baseChart
          id="myChart"
          height="90"
          width="100"
          [datasets]="barAnalytic.datasets"
          [labels]="barAnalytic.labels"
          [options]="options"
          [legend]="false"
          [chartType]="type"
        >
        </canvas>
      </div>
      <div id="chartjs-tooltip" style="position: absolute; background-color: rgba(0, 0, 0, 0.7); color: white; padding: 10px; border-radius: 3px; display: none;"></div>
    </div>
  </app-request-wrapper>
</div>
