<div
  class="row mx-auto"
  style="margin-bottom: 2vh;"
>
  <div class="col-4 pb-1">
    <app-analytic-bar-default 
      key="regional"
      [data]="data.value.analyticalVolumeByRegional"
      [colors]="colors"
      [loading]="loading"
      [error]="error"
      (filterClick)="onChange($event)" 
    >
    </app-analytic-bar-default>
  </div>

  <div class="col-8">
    <app-analytic-bar-default
      key="group"
      [data]="data.value.analyticalVolumeByGroup"      
      [colors]="colors"
      [loading]="loading"
      [error]="error"
      (filterClick)="onChange($event)"
    >
    </app-analytic-bar-default>    
  </div>
</div>

<div
  class="row mx-auto"
  style="margin-bottom: 2vh;"
>
  <div class="col-12">
    <div class="row">
      <div class="col-6">
        <app-analytic-bar-horizontal
          key="cluster"
          [data]="data.value.analyticalVolumeByCluster"
          [colors]="colors"    
          [loading]="loading"
          [error]="error" 
          [isLabelUppercase]="true"
          [height]="12"
          (filterClick)="onChange($event)"
        >
        </app-analytic-bar-horizontal>
      </div>
      <div class="col-6">
        <app-analytic-bar-horizontal
          key="city"
          [data]="data.value.analyticalVolumeBySubCluster"
          [colors]="colors"     
          [loading]="loading"
          [error]="error"     
          [isLabelUppercase]="false"
          [format]="formatCityName"
          [height]="12"
          (filterClick)="onChange($event)"
        >
        </app-analytic-bar-horizontal>
      </div>
    </div>
  </div> 
</div>