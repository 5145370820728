import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faUserCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { getPermissionOptions } from 'src/app/interfaces';
import { caseValidator, numberValidator, specialCharacterValidator } from 'src/app/utils/inputValidators';
import { states } from 'src/app/utils/constants';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RequestResult, mountRequestResult, RequestStatus } from 'src/app/interfaces/RequestContext';

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: []
})
export class CreateUserModalComponent implements OnInit {

  form: FormGroup;
  faUser=faUserCircle;
  faTimesCircle=faTimesCircle;
  permissionOptions = getPermissionOptions();
  stateOptions=states;

  showPassword: boolean = false;
  password: string = '';
  showConfirmationPassword: boolean = false;
  confirmationPassword: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private snackBar: MatSnackBar,
    ) {
      this.form = this.formBuilder.group({
        fullname: ['', [Validators.required, Validators.minLength(3)]],
        username: ['', [Validators.required, Validators.minLength(4)]],
        password: new FormControl('', Validators.compose([
          Validators.required,
          caseValidator,
          specialCharacterValidator,
          numberValidator,
          Validators.minLength(8),
        ])),
        confirmationPassword: ['', [Validators.required, Validators.minLength(8)]],
        email: ['', [Validators.required, Validators.email]],
        state: [[], [Validators.required]],
        permissions: [[], Validators.required],
        role: ['USER', Validators.required]    
      });
  }

  ngOnInit(): void {
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }
  
  passwordsMatchValidator(control: AbstractControl) {
    const newPassword = control.get('password').value;
    const confirmationPassword = control.get('confirmationPassword').value;
    if (newPassword !== confirmationPassword) return true
    return false
  }

  getFormControls() {
    return this.form.controls;
  }
 
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmationPassword() {
    this.showConfirmationPassword = !this.showConfirmationPassword;
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    const { fullname, username, password, email, role, permissions, state } = this.getFormControls();
    const input = {
      fullName: fullname.value,
      username: username.value,
      email: email.value,
      password: password.value,
      role: role.value ?? 'USER',
      state: state.value,
      permissions: permissions.value
    }
    this.userService.create({ input }).subscribe(
      () => {
        const requestResult: RequestResult = mountRequestResult({
          request: { status: 200, message: 'Usuário criado com sucesso!' },
          expectedStatus: RequestStatus.OK
        });
        this.openPopUpResponse(requestResult);
        this.activeModal.close('created');
      },
      (error) => {
        const requestResult: RequestResult = mountRequestResult({
          request: { status: 500, message: 'Erro ao criar o usuário, tente novamente mais tarde!' },
          expectedStatus: RequestStatus.OK
        });
        this.openPopUpResponse(requestResult);
      }
    );
  }
}
