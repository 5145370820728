<div class="container-filter-mobile">
  <div class="col-lg-4 col-md-2 col-12">
    <app-data-picker-default></app-data-picker-default>
  </div>
  
  <div class="col-lg-2 col-md-2 col-12">
    <app-cause-select></app-cause-select>
  </div>

  <div class="col-lg-2 col-md-2 col-12">
    <app-regional-select></app-regional-select>
  </div>

  <div class="col-lg-2 col-md-2 col-12">
    <app-group-select></app-group-select>
  </div>

  <div class="col-lg-2 col-md-2 col-12">
    <app-state-select></app-state-select>
  </div>
</div>