<div 
  class="d-flex legends"
  style="width: 100%; height: 100%; position: relative; display: flex; align-items: center; flex-direction: column;"
>
  <div class="container-legends">
    <div
      class="legend-container"
      *ngFor="let label of data.legends; let i = index"
    >
      <span class="symbol-legend"
        [ngStyle]="{ 'background-color': this.colors[i] ?? 'black' }"
      >
      </span>
      <span class="label-charts">
        {{ label }}
      </span>
    </div>
  </div>

  <div style="width: 100%; height: 100%">
    <canvas
      baseChart
      height="30"
      width="95"
      [datasets]="barChartData.datasets"
      [labels]="barChartData.labels"
      [options]="options"
      [chartType]="type"
      [legend]="false"
    >
    </canvas>
  </div>
</div>