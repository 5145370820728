<div class="modal-header">
  <h4 class="modal-title">
    <i class="bi bi-person-circle"></i> Logs de Atividade: <span class="fw-bold">({{ userInfo.username }})</span>
  </h4>  
</div>

<div class="modal-body custom-card-body">

  <div *ngIf="loading" class="text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div *ngIf="error" class="alert alert-danger" role="alert">
    Ocorreu um erro ao carregar os logs.
  </div>

  <div *ngIf="!hasLogs" class="alert alert-danger" role="alert">
    Nenhum log encontrado para este usuário.
  </div>

  <div *ngIf="!loading && !error && hasLogs">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Data</th>
          <th>Ação</th>
          <th>Página de Origem</th>
          <th>Página de Destino</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let log of this.data.value.data">
          <td>{{ log.date | date:'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{ log.operation }}</td>
          <td>{{ log.fromPage }}</td>
          <td>{{ log.toPage }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="close()"
  >Close
  </button>
</div>
