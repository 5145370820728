import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VandalismService } from 'src/app/services/vandalism.service';
import { ZoomImagePopUpComponent } from '../zoom-image-pop-up/zoom-image-pop-up.component';
import { VandalismTicketPopup } from 'src/app/interfaces';
import { IDisplayItem } from 'src/app/interfaces/models/IDisplayItem'
import { Formatter } from 'src/app/utils/formatter.util';
import { PdfGeneratorService } from 'src/app/services/pdf-generator.service';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RequestResult, RequestStatus } from 'src/app/interfaces/RequestContext';
import { AuthService } from 'src/app/services/auth.service';
import { ActivityLogServiceService } from 'src/app/services/activity-log-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-pop-up',
  templateUrl: './ticket-pop-up.component.html',
  styleUrls: []
})
export class TicketPopUpComponent implements OnInit {

  @Input() modalData: { ticketId: number, lat: number, lng: number };
  displayData: IDisplayItem[] = [];
  photos: SafeUrl[] = [];
  sharpnessValidations = [null, null, null, null, null];
  disabledButton: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loading = true;
  imagemUrl;

  constructor(
    private vandalismService: VandalismService,
    private pdfService: PdfGeneratorService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private logService: ActivityLogServiceService
  ) {
    this.imagemUrl = environment.imagemUrl
  }
  
  ngOnInit() {  
    this.getTicketPopup(this.modalData.ticketId, this.modalData.lat, this.modalData.lng);  
  }

  mounthDisplayData(displayData: VandalismTicketPopup.DisplayDataPopup) {
    this.displayData = [
      { label: 'Id da Atividade', value: displayData.ticketId },
      { label: 'Task Id Incident', value: displayData.taskIdIncident },
      { label: 'Ticket no Sistema de Origem', value: displayData.ticketSystemOrigin },
      { label: 'Id do técnico', value: displayData.technicianId },
      { label: 'Endereço', value: displayData.address },
      { label: 'Data Reportada', value: displayData.reportedDate },
      { label: 'Data da rota', value: displayData.routeDate },
      { label: 'Operacional', value: displayData.operational },
      { label: 'Causa', value: displayData.cause },
      { label: 'Status', value: displayData.status },
    ];

    if (displayData.isPead) {
      this.displayData.push({ label: 'Quantidade', value: displayData.quantity });
    }
  }
  treatLegacyTicket(ticket: any, key: any) {
    const isLegacy = this.modalData.ticketId === 0
    return isLegacy ? '' :  ticket[key];
  }

  getFormatted(parts: string[]) {
    const treatParts = parts.filter(part => part !== null && part !== undefined);
    return treatParts.length > 1 ?  treatParts.join(' - ') : treatParts;
  }
  
  getPhotos(ticketId: number) {
    if (!ticketId) {
      this.loading = false;
      return;
    }

    this.loading = true;

    for (let i = 1; i <= 5 ; i++) {
      const endpoint = `${this.imagemUrl}/fotosClaro/${ticketId}-${i}.jpg`;
      this.photos.push(endpoint);
    }

    this.loading = false;
  }

  // getTicketPopup(ticketId: number, lat: number, lng: number) {
  //   if (!ticketId) {
  //     this.loading = false;
  //     return;
  //   }
    
  //   this.vandalismService.getTicketPopup({ ticketId, lat, lng })
  //     .subscribe((response: {
  //       ticket: VandalismTicketPopup.DisplayDataPopup,
  //       sharpnessValidations: boolean[]
  //     }) => {
  //       this.sharpnessValidations = response.sharpnessValidations;
  //       this.mounthDisplayData(response.ticket);
  //       this.getPhotos(this.modalData.ticketId);
  //       console.log(this.mounthDisplayData);
  //     },
  //     (error) => {
  //       console.error('Ocorreu um problema ao buscar as informações do ticket: ', error)
  //     })
  // }

  getTicketPopup(ticketId: number, lat: number, lng: number) {
    if (!ticketId) {
      this.loading = false;
      return;
    }
    
    this.vandalismService.getTicketPopup({ ticketId, lat, lng })
      .subscribe((response: {
        ticket: VandalismTicketPopup.DisplayDataPopup,
        sharpnessValidations: boolean[]
      }) => {
        this.sharpnessValidations = response.sharpnessValidations;
        this.mounthDisplayData(response.ticket);
        this.getPhotos(this.modalData.ticketId);
      },
      (error) => {
        console.error('Ocorreu um problema ao buscar as informações do ticket: ', error)
      });
  }

  openPopUpResponse(requestResult: RequestResult) {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [requestResult.styleClass]
    }
    this.snackBar.open(requestResult.message, closeButtonMessage, snackBarSettings);
  }

  sendPhotoManualReport = (imageIndex: number, validation: boolean) => {
    const { ticketId } = this.modalData;
    const response = this.vandalismService.sendPhotoManualReport({
      ticketId,
      imageIndex: imageIndex + 1,
      validation
    });

    response.subscribe((r) => {
      this.openPopUpResponse({
        status: r.status,
        message: r.message,
        isError: !(RequestStatus.OK === r.status),
        styleClass: RequestStatus.OK === r.status ? 'success-bar-container' : 'failure-bar-container'
      });

      const { userId, companyCode } = this.authService.logCredentials();
      this.logService.createImageFeedbackLog({ userId, companyCode });
    }); 
  }

  isAdmin() {
    return this.authService.isUserVirtsel();
  }

  treatDate(reportedDate: string, withHour: boolean) {
    return Formatter.formatReportedDate(reportedDate, withHour);
  }

  openZoomImageModal(url: SafeUrl) {
    const modalRef = this.modalService.open(ZoomImagePopUpComponent, { size: 'xl' });
    modalRef.componentInstance.modalData = { src: url };
  }

  async generatePdf() {
    const ticketId = this.displayData[0].value;
    await this.pdfService.generatePdf(this.displayData, this.photos, ticketId);
  }

  closeModal() {
    this.activeModal.close();
  }
}
