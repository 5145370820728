export const groupsMapped = {
  'Grupo Rio e ES':'RJ-ES',
  'Grupo Nordeste':'NE',
  'Grupo SC':'SC',
  'Grupo PR':'PR',
  'Grupo SP Interior':'SPI',
  'Grupo Minas Gerais':'MG',
  'Grupo RS':'RS',
  'Grupo SP Capital':'SPC',
  'Grupo Norte':'NO',
  'Grupo Centro-Oeste':'CO',
  'Grupo Bahia/Sergipe':'BA-SE'
}

export const InverseGroupsMapped = {
  'RJ-ES': 'GRUPO RIO E ES',
  'NE': 'GRUPO NORDESTE',
  'SC': 'GRUPO SC',
  'PR': 'GRUPO PR',
  'SPI': 'GRUPO SP INTERIOR',
  'MG': 'GRUPO MINAS GERAIS',
  'RS': 'GRUPO RS',
  'SPC': 'GRUPO SP CAPITAL',
  'NO': 'GRUPO NORTE',
  'CO': 'GRUPO CENTRO-OESTE',
  'BA-SE': 'GRUPO BAHIA/SERGIPE'
}

export const directorsByRegional = {
  'REGIONAL LESTE': [
    'GRUPO BAHIA/SERGIPE',
    'GRUPO CENTRO-OESTE',
    'GRUPO MINAS GERAIS',
    'GRUPO NORDESTE',
    'GRUPO NORTE',
    'GRUPO RIO E ES'
  ],
  'REGIONAL SÃO PAULO': [
    'GRUPO SP CAPITAL',
    'GRUPO SP INTERIOR',
  ],
  'REGIONAL SUL': [
    'GRUPO PR',
    'GRUPO RS',
    'GRUPO SC'
  ]
}

export const inversedDirectorsByRegional = {
  'GRUPO BAHIA/SERGIPE': 'REGIONAL LESTE',
  'GRUPO CENTRO-OESTE': 'REGIONAL LESTE',
  'GRUPO MINAS GERAIS': 'REGIONAL LESTE',
  'GRUPO NORDESTE': 'REGIONAL LESTE',
  'GRUPO NORTE': 'REGIONAL LESTE',
  'GRUPO RIO E ES': 'REGIONAL LESTE',
  'GRUPO SP CAPITAL': 'REGIONAL SÃO PAULO',
  'GRUPO SP INTERIOR': 'REGIONAL SÃO PAULO',
  'GRUPO PR': 'REGIONAL SUL',
  'GRUPO RS': 'REGIONAL SUL',
  'GRUPO SC': 'REGIONAL SUL'
}

export const inverseMontsNames = {
  'JAN': '1',
  'FEV': '2',
  'MAR': '3',
  'ABR': '4',
  'MAI': '5',
  'JUN': '6',
  'JUL': '7',
  'AGO': '8',
  'SET': '9',
  'OUT': '10',
  'NOV': '11',
  'DEZ': '12',
};