<!-- TODO: Remover o style -->
<div class="fullscreen-modal">

  <div *ngIf="options.withFilters" class="row fullscreen-filter  d-none d-lg-flex" style="margin-left: 2vw;">

    <div class="col-lg-3 col-md-3">
      <app-data-picker-default
        [dates]="selectedDates?.value"
        (event)="onChange($event)"
      >
      </app-data-picker-default>
    </div>

    <div class="col-lg-2 col-md-2 select">
      <app-select-default
        key="causeGroups"
        title="Causas"
        [searchFilter]="true"
        [isSingleSelection]="true"
        [dropdownList]="data.value?.getFilters().causeGroups"
        [selectedItems]="selectedItems?.value.causeGroups"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2 select">
      <app-select-default
        key="regionals"
        title="Regionais"
        [searchFilter]="true"
        [dropdownList]="data.value?.getFilters().regionals"
        [selectedItems]="selectedItems?.value.regionals"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2 select">
      <app-select-default
        key="directors"
        title="Diretorias"
        [searchFilter]="true"
        [dropdownList]="data.value?.getFilters().directors"
        [selectedItems]="selectedItems?.value.directors"
        [itemsShowLimit]="1"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2 select">
      <app-select-default
        key="clusters"
        title="Clusters"
        [searchFilter]="true"
        [dropdownList]="data.value?.getFilters().clusters"
        [selectedItems]="selectedItems?.value.clusters"
        [itemsShowLimit]="2"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>

    <div class="col-lg-2 col-md-2 select">
      <app-select-default
        key="states"
        title="Estados"
        [searchFilter]="true"
        [dropdownList]="data.value?.getFilters().states"
        [selectedItems]="selectedItems?.value.states"
        [itemsShowLimit]="3"
        (event)="onChange($event)"
      >
      </app-select-default>
    </div>
	</div> <!-- selects filters -->

  <div class="row fullscreen-filter-mobile d-flex d-sm-none">
    <button
      class="btn-default"
      (click)="openFiltersPopUp()"
    >
      Filtros
      <span class="fa fa-sliders"></span>
    </button>
  </div>

  <agm-map
    style="width: 100%; height: 100%;"
    [latitude]="mapConfig.center.lat"
    [longitude]="mapConfig.center.lng"
    [zoom]="mapConfig.zoom"
    [disableDefaultUI]="false"
    [zoomControl]="mapConfig.zoomControl"
    [streetViewControl]="mapConfig.streetViewControl"
    [fullscreenControl]="mapConfig.fullscreenControl"
    (mapReady)="onMapReady($event)"
    >
    <agm-marker
      *ngFor="let m of data.value?.getTicketsForMap(); let i = index"
      (markerClick)="options.withPopup ? openTicketPopUp(m) : null"
      [latitude]="m.lat"
      [longitude]="m.lng"
      [label]="m.label"
      [iconUrl]="mapConfig.iconUrl"
      [title]="treatTooltip(m)"
    >
    </agm-marker>
  </agm-map>
</div>
