import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { ThemeService } from 'src/app/services/theme.service';
import { IColors } from '../interfaces';

export interface ExportToExcel {
  data: any[],
  title: string,
  date: string,
  filename: string
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  EXCEL_OPTIONS = {
    TYPE: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    EXTENSION: '.xlsx'
  }

  colors: IColors;
  version = '1.0';
  
  constructor(private themeService: ThemeService) {
    this.colors = this.themeService.getColors();
  }

  async exportToExcel({ data, date, filename, title }: ExportToExcel): Promise<void> {
    // Criar um novo workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    // Estilizar e adicionar o título
    const titleRow = worksheet.addRow([title]);

    titleRow.eachCell({ includeEmpty: false }, cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFA500' },
      };
    });
  
    titleRow.alignment = { horizontal: 'center' };
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      bold: true,
    };
  
    worksheet.mergeCells('A1:G1');

    // Adicionar a data e versão na segunda linha
    const subTitleRow = worksheet.addRow(["", "", "", "", "", `Data: ${date}`, `Versão: ${this.version}`]);
    subTitleRow.alignment = { horizontal: 'right' };
    worksheet.mergeCells('A2:D2');

    // Adicionar espaço extra
    worksheet.addRow([]);

    // Adicionar cabeçalhos das colunas
    const headerRow = worksheet.addRow(Object.keys(data[0]));
    headerRow.eachCell((cell) => {
      cell.font = { 
        name: 'Calibri',
        size: 12,
        bold: true,
        color: { argb: 'FFFFFFFF' }
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF001B44' },
      };
    });

    // Caso no futuro se as linhas precisarem serem estilizadas
    data.forEach((d) => {
      const row = worksheet.addRow(Object.values(d));
    });

    // Aplicar largura automática nas colunas
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxLength) { maxLength = cellLength; }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });

    // Gravar o arquivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, filename);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.EXCEL_OPTIONS.TYPE});
    FileSaver.saveAs(data, fileName + this.EXCEL_OPTIONS.EXTENSION);
  }
}
