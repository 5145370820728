<div
  class="widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary card"
>
  <div class="widget-chat-wrapper-outer">
    <div
      class="widget-chart-content"
      style="display: flex; justify-content: center; align-items: center;">
      <div
        class="widget-title opacity-5 text-uppercase"
        style="text-align: center; margin-bottom: 0.3em; margin-right: 1rem; font-size: 0.6rem;"
      >
        Score
      </div>
      <div class="progress-circle-wrapper" style="min-width: 55px;">
        <div class="round-progress-text">
          <span>{{scoreNumber}}%</span>
          <round-progress
            [current]="scoreNumber"
            [max]="100"
            [color]="scoreColor"
            [background]="'#e3e3e3'"
            [radius]="88"
            [stroke]="11"
            [semicircle]="false"
            [rounded]="true"
            [clockwise]="false"
            [responsive]="true"
            [duration]="1400"
            [animation]="'easeInOutQuart'"
            [animationDelay]="0"
          >
          </round-progress>
        </div>
      </div>
    </div>
  </div>
</div>
