import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ActivityLogServiceService } from './services/activity-log-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserInfoPopUpComponent } from './components/user-info-pop-up/user-info-pop-up.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserField, UserService } from './services/user.service';
import { IpAddressService } from './services/ip-address.service';
import { PolicyPopUpComponent } from './components/policy-pop-up/policy-pop-up.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {

  private currentUrl = '/home';
  private previousUrl = '';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private logService: ActivityLogServiceService,
    private router: Router,
    private modalService: NgbModal,
    private ipAddressService: IpAddressService
  ) {
    this.initLogs();
    this.ipAddressService.initIpAddress()
  }

  ngOnDestroy() {
    this.logService.stopLogInterval();
  }

  initLogs() {
    this.logService.startLogInterval();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;        
        if (this.authService.isLoggedIn()) {
          const { userId, companyCode } = this.authService.logCredentials();
          this.logService.createNavigateLog({ userId, companyCode, fromPage: this.previousUrl, toPage: this.currentUrl });
        }
      });
  };
}
