import { Component, OnInit } from '@angular/core';
import { faClosedCaptioning } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-pop-up-default',
  templateUrl: './error-pop-up.component.html',
  styleUrls: []
})
export class ErrorPopUpComponent implements OnInit {

  statusCode: number;
  faClosedCaptioning = faClosedCaptioning;

  messages = {
    '0': 'Erro Desconhecido.',
    '400': 'Usuário não encontrado',
    '404': 'Parece que seu usuário ou senha estão incorretos.',
    '500': 'Erro Interno do Servidor.'
  }
  
  constructor(public activeModal: NgbActiveModal) { }
  
  ngOnInit(): void {}

  close(result: any) {
    this.activeModal.close(result);
  }
}
