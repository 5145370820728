import { Component, Input, OnInit } from '@angular/core';
import { IPointMap } from 'src/app/interfaces';

@Component({
  selector: 'app-route-table',
  templateUrl: './route-table.component.html',
  styleUrls: []
})
export class RouteTableComponent implements OnInit {

  @Input() title: string;
  @Input() data: IPointMap[];

  constructor() { }

  generateLetterPoint(number: number): string {
    const letters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (number > 26) return "";
    return letters[number - 1];
  }

  ngOnInit(): void {
  }
}
