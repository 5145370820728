import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestResult } from 'src/app/interfaces/RequestContext';


@Component({
  selector: 'app-request-response-pop-up',
  templateUrl: './request-response-pop-up.component.html',
  styleUrls: ['./request-response-pop-up.component.scss']
})
export class RequestResponsePopUpComponent implements OnInit {

  @Input() requestResult: RequestResult;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  close() {
    this.activeModal.close();
  }
}
