<div class="container-mapa-full-screen">

  <!-- * TELA DE LOADING *ngIf="initialLoad" -->
  <div *ngIf="initialLoad"  class="loading-screen">
    <div class="loading-animation">
      <div class="spinner-container">        
        <div class="spinner"></div>
      </div>
      <p class="loading-title">Carregando...</p>
    </div>
  </div>

  <!-- * HEADER FILTER PAGE -->

  <div 
    class="container-header-page full-screen" 
    [class.bg-transition]="isDivVisible" 
    #headerFilter
  >
    <div class="header-img-filter col-1">
      <img
        [src]="isDivVisible ? '../../../../assets/images/claro_logo_70.png' : '../../../../assets/images/claro_logo_50.png'"
        (click)="openHeaderFilter()"
        alt="logo da claro"
      >
    </div>    

    <div 
      class="container-header-filters col-10" 
      [@slideInOut]="isDivVisible ? 'in' : 'out'"
    >
      <div class="container-filters col-12 p-0">
        <div class="d-flex d-reverse-row">
          <div class="container-new-search-input">
            <input 
              type="text" 
              class="new-search-input" 
              placeholder="Id da atividade"
              [(ngModel)]="searchTerm"             
              (input)="onSearch($event.target.value)" 
            >            
            <i 
              [ngClass]="searchTerm ? 'fa fa-times' : 'fa fa-search'"
              (click)="cleanInput()"
            ></i>
          </div>
        </div> 
      </div><!-- *CONTAINER TOP HEADER -->            
    </div>

    <div class="col-1 icon-back-home" (click)="closeModal()">
      <img [src]="isDivVisible ? iconBackHomeWhite : iconBackHome" @fade-inout alt="back home">
    </div>
  </div>

  <!-- ! IMPLEMENTAR DEPOIS DA ENTREGA -->
  <!-- <div class="container-header-full-screen">
    <app-header-filter></app-header-filter>
  </div> -->

  <agm-map
    #agmMap  
    style="width: 100%; height: 100%;"
    [latitude]="mapCenter.lat"
    [longitude]="mapCenter.lng"
    [zoom]="mapZoom"
    [mapTypeId]="'roadmap'"
    [styles]="mapStyles"    
    [disableDefaultUI]="false"
    [zoomControl]="true"
    [maxZoom]="28"
    [minZoom]="4"
    [zoomControl]="mapConfig.zoomControl"
    [streetViewControl]="mapConfig.streetViewControl"
    [fullscreenControl]="mapConfig.fullscreenControl"  
    (mapReady)="onMapReady($event)"  
    (mapClick)="clearMapInfo()"
  >
    
    <ng-container 
      *ngFor="let activityInventory of (inventoryActivities | async); let i = index; trackBy: trackByActivityId"
    >

      <!-- MARKER ATIVIDADE -->
      <agm-marker 
        *ngIf="activityInventory.xirGeoCode && inventoryService.extractLatLng(activityInventory.xirGeoCode)"
        [latitude]="inventoryService.extractLatLng(activityInventory.xirGeoCode)?.lat" 
        [longitude]="inventoryService.extractLatLng(activityInventory.xirGeoCode)?.lng"
        [iconUrl]="mapIcons.iconInventory"
        (markerClick)="selectActivityInventory(activityInventory, i)"
      > 
        <agm-info-window [isOpen]="showTooltipActivity[i]" [disableAutoPan]="true">
          <div class="container-tootip-inventory">
            <h2 class="title-tooltip">
              preventivo - {{activityInventory.activityId}}
            </h2>
            <div class="container-contents">
              <div class="content-key">
                <span>id inventario</span>
                <span>status</span>                
                <span>id tecnico</span>                
                <div class="container-button">                  
                  <button class="new-btn-default-2" (click)="generatePdf(activityInventory,i)">Gerar relatório</button>
                </div>
              </div>
              <div class="content-value">
                <span>{{activityInventory.inventoryId}}</span>
                <span>{{activityInventory.status}}</span>                
                <span>{{activityInventory.resourceId}}</span>                
              </div>  
              <div *ngIf="loading" class="container-carousel-loading">
                <div class="carousel-loading">
                  <div class="loading-container">
                    <div class="loader">
                      <div class="ball-pulse">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div *ngIf="!loading" class="container-carousel">                
                <ngx-slick-carousel
                  class="slick-slider slick-dotted"
                  #slickModal="slick-carousel" 
                  [config]="slideConfig"
                >                  
                  <div ngxSlickItem *ngFor="let photo of photosInventory; let i = index" class="img-container">
                    <img 
                      [src]="photo"
                      alt="Image {{ i }}" 
                      (click)="openZoomImageModal(photo)"
                      class="carousel-image"
                    >
                  </div>                  
                </ngx-slick-carousel>                 
              </div> 
            </div>
          </div>     
        </agm-info-window>
      </agm-marker>   

      <!-- MARKER FOTO INVENTARIO -->
      <agm-marker 
        *ngFor="let marker of markers; let i = index"
        [latitude]="marker.lat" 
        [longitude]="marker.lng"
        [iconUrl]="mapIcons.iconPhotoInventory"
        (markerClick)="selectInventoryImages(i,marker.activityInventory.activityId)"
      >
        <agm-info-window [isOpen]="showTooltipInventory[i]" [disableAutoPan]="true">
          <div class="container-tootip-inventory">
            <h2 class="title-tooltip">
              {{marker.activityInventory.inventoryType}} - {{marker.activityInventory.inventoryId}}
            </h2>
            <div class="container-contents">
              <div class="content-key">
                <span>id atividade</span>
                <span>status</span>
                <span>tipo de atividade</span>
                <span>id tecnico</span>
                <span>modelo inventario</span>
              </div>
              <div class="content-value">
                <span>{{marker.activityInventory.activityId}}</span>
                <span>{{marker.activityInventory.status}}</span>
                <span>PREVENTIVO</span>
                <span>{{marker.activityInventory.resourceId}}</span>
                <span>{{marker.activityInventory.XIR_INV_MODEL ?? 'Sem Modelo'}}</span>
              </div>

              <div *ngIf="loading" class="container-carousel-loading">
                <div class="carousel-loading">
                  <div class="loading-container">
                    <div class="loader">
                      <div class="ball-pulse">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div *ngIf="!loading" class="container-carousel">                
                <ngx-slick-carousel
                  class="slick-slider slick-dotted"
                  #slickModal="slick-carousel" 
                  [config]="slideConfig"
                >                  
                  <div ngxSlickItem *ngFor="let photo of photosInventory; let i = index" class="img-container">
                    <img 
                      [src]="photo"
                      alt="Image {{ i }}" 
                      (click)="openZoomImageModal(photo)"
                      class="carousel-image"
                    >
                  </div>                  
                </ngx-slick-carousel>                 
              </div>            
            </div>
          </div>
        </agm-info-window>
      </agm-marker>

      <!-- * MARKER TECNICO -->
      <agm-marker 
        *ngIf="isTechnician" 
        [latitude]="technicianPosition.lat" 
        [longitude]="technicianPosition.lng" 
        [iconUrl]="technicianIcons.iconTecInservice"        
      >                
        <!-- <agm-info-window [isOpen]="showInfoWindow[i]" [disableAutoPan]="true">        
          <div class="container-tootip-operations">
            <h2 class="title-tooltip">{{activity.resourceId}}</h2>
            <h4 
              *ngIf="techniciansAlert != ''" 
              class="alert-resourceid"
            >
              <img [src]="technicalIcons.iconTecOutservice" alt="">
              {{ techniciansAlert }}
            </h4>
            <div class="container-contents">
              <div class="content-key">
                <span>id atividade</span>
                <span>status</span>                                
                <span>Tipo da atividade</span>                                
              </div>
              <div class="content-value">
                <span>{{activity.activityId}}</span>
                <span>{{getStatusActivities(activity)}}</span> 
                <span>{{activity.activityType}}</span>                          
              </div>
            </div>
            <div *ngIf="activity.activityType === 'assistance'">
              <button class="btn-default-small" (click)="onMarkerClickAssistence(activity.resourceId, activity.xaMainActivity, i)">Rota de Assistência</button>
            </div>
          </div>              
        </agm-info-window> -->
      </agm-marker> 
    </ng-container>

  </agm-map>
</div>

<!-- MODAL PARA IMAGAENS DE ZOOM -->
<app-modal-zoom-image
  [isOpen]="isModalOpen"
  [imageUrl]="selectedImage"
  (closeModal)="closeZoomImageModal()"
>  
</app-modal-zoom-image>