import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faBrush, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-theme-pop-up',
  templateUrl: './create-theme-pop-up.component.html',
  styleUrls: []
})
export class CreateThemePopUpComponent implements OnInit {

  form: FormGroup;
  faBrush=faBrush;
  faTimesCircle=faTimesCircle;
  logoPreview: string | ArrayBuffer | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
  ) {
    this.form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3)]],
      primaryColor: ['', Validators.required],
      secondaryColor: ['', Validators.required],
      logo: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  getFormControls() {
    return this.form.controls;
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const base64 = reader.result as string;
        this.logoPreview = base64;
        this.form.patchValue({ logo: base64 });
      };
      reader.readAsDataURL(file);
    }
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    const formControls = this.getFormControls();
    const { title, primaryColor, secondaryColor, logo } = formControls;
    console.log(`
      Title: ${title.value},
      Cor Primária: ${primaryColor.value},
      Cor Secundária: ${secondaryColor.value},
      Logo: ${logo.value}
    `);
  }
}
