<!-- TODO: Remover style inline -->
<div>
  <div>
    <div class="row h-20 selects-row">
  
      <div class="col-lg-4 col-md-4">
        <app-data-picker-default
          [dates]="getRangeDate()"
          (event)="onChange($event)"
        >
        </app-data-picker-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="causes"
          title="Causas"
          [dropdownList]="filters.causes"
          [selectedItems]="['VANDALISMO']"
          [itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>

      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="families"
          title="Família"
          [dropdownList]="filters.families"
          [selectedItems]="[]"
          [itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>
  
      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="regionals"
          title="Regionais"
          [dropdownList]="this.data.value.filters?.dropdown?.regionals"
          [selectedItems]="this.data.value.filters?.selected?.regionals"
          [itemsShowLimit]="1"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>
  
      <div class="col-lg-2 col-md-2">
        <app-select-default
          key="directors"
          title="Diretorias"
          [limit]="1"
          [dropdownList]="this.data.value.filters?.dropdown?.directors"
          [selectedItems]="this.data.value.filters?.selected?.directors"
          [itemsShowLimit]="1"
          (event)="onChange($event)"
        >
        </app-select-default>
      </div>
  
      <div class="col-lg-2 col-md-2"></div>
  
      <div class="col-lg-2 col-md-2"></div>
    </div> <!-- selects filters -->

    <!-- TODO: Componentizar -->
    <div class="row" style="margin-bottom: 2vh">
      <div class="views-container">
        
        <button
          class="view-button"
          (click)="setView('analyticalTicketsByGroup')"
          [class.active]="view.value === 'analyticalTicketsByGroup'"
        > Atividades por diretoria
        </button>

        <button
          class="view-button"
          (click)="setView('analyticalPhotosByTechnical')"
          [class.active]="view.value === 'analyticalPhotosByTechnical'"
        >Atividades por técnico
        </button>

        <button
         class="view-button"
          (click)="setView('analyticalTicketsByYear')"
          [class.active]="view.value === 'analyticalTicketsByYear'"

        >Atividades por mês 
        </button>

        <button
          class="view-button"
          (click)="setView('analyticalPhotosByValidation')"
          [class.active]="view.value === 'analyticalPhotosByValidation'"

        >Fotos por validação 
        </button>
      </div>
    </div>

    <div class="row">
      <app-request-wrapper
        [class]="requestWrapperConfig.chart"
        [isLoading]="loading"
        [isError]="error"
      >
        <div class="card-chart chart-full col-12 col-md-12 col-xxl-12" style="height: 68vh">

          <div class="card-chart-header chart-full-header">
            <p style="font-size: large; font-weight: bold; font-family: 'roboto'; margin-top: 10px;">
              {{ data.value[this.view.value].title }}
            </p>
          </div>
          <hr>

          <div style="display: flex; align-items: center; justify-content: center;">
            <!-- Acessando o índice com a diretiva index do ngFor -->
            <ng-container *ngFor="let legend of data.value[this.view.value].legends; let i = index" | async>
              <span
                style="
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin-right: 5px;
                  margin-left: 5px;
                "
                [ngStyle]="{'background-color': colors[i] }"
              >
              </span>
              <span style="font-size: 0.9em; flex-wrap: nowrap;">
                {{ legend }}
              </span>
            </ng-container>
          </div>

          <div class="card-chart-body chart-full-body " style="width: 100%; height: 90%;">
            <div 
              class="d-flex"
              style="
                width: 100%;
                height: 95%;
                position: relative;
              "
              [ngStyle]="{ 'width': barWidthProportion }"
            >
              <canvas
                baseChart
                height="90"
                width="100"
                [datasets]="barChartData.datasets"
                [labels]="barChartData.labels"
                [options]="options"
                [legend]="false"
                [chartType]="'bar'"
              >
              </canvas>
            </div>
          </div>
        </div>
      </app-request-wrapper>
    </div>

    <div class="row" *ngIf="showTable.value" style="margin-top: 4vh;">
      <div class="div-container">
        <table class="image-verification-table">
          <tr class="image-verification-row">
            <!-- TODO: Componentizar -->
            <th class="image-verification-header">
              Id da atividade
            </th>

            <th class="image-verification-header">
              Id do incidente
            </th>

            <th class="image-verification-header">
              Número da imagem
            </th>

            <th class="image-verification-header">
              Id do Técnico
            </th>
          </tr>
  
          <tr *ngFor="let ticket of ticketsTable.value; let i = index" class="image-verification-row">
            <!-- TODO: Componentizar -->
            <td 
              class="image-verification-body image-verification-body-column"
              (click)="openTicketPopUp(ticket[0])"
            >
              {{ ticket[0] ?? '' }}
            </td>
  
            <td class="image-verification-body">
              {{ ticket[1] ?? '' }}
            </td>

            <td class="image-verification-body">
              {{ ticket[2] ?? '' }}
            </td>
        
            <td class="image-verification-body">
              {{ ticket[3] ?? '' }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>