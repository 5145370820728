<div class="card-chart chart-small-geral col-12 col-md-12 col-xxl-12">
  <div class="card-chart-header chart-small-header-geral">
    <div>
      <app-title-default [content]="title"></app-title-default>
    </div>
  </div>
  <hr>
  <div class="card-chart-body chart-small-body-geral">
    <!-- TODO: Passar o style. -->
    <div 
      class="d-flex"
      style="width: 100%; height: 100%; position: relative;"
    >
      <canvas
        id="myChart"
        baseChart
        height="100"
        width="100"
        [data]="data.dataset"
        [labels]="data.labels"
        [options]="options"
        [legend]="false"
        [chartType]="type"
        [colors]="backgroundColor"
        >
      </canvas>
    </div>
    <div id="chartjs-tooltip" style="position: absolute; background-color: rgba(0, 0, 0, 0.7); color: white; padding: 10px; border-radius: 3px; display: none;"></div>
  </div>
</div>
