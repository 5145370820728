import { Component, OnInit } from '@angular/core';
import { MapaOperacoesComponent } from '../mapa-operacoes.component'; 
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mapa-operacoes-proxy',
  template: `<div></div>`
})
export class MapaOperacoesProxyComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.openMapModal()
  }

  openMapModal() {
    this.modalService.open(MapaOperacoesComponent)
  }
}
