<div class="card-chart" style="height:100%">
  <div class="card-chart-header">
    <app-title-default [content]="title">
    </app-title-default>
  </div>

  <div 
    class="alert-table card-chart-body table-responsive"
  >
    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center text-nowrap">Data</th>
          <th class="text-center text-nowrap">Causa</th>
          <th class="text-center text-nowrap">Estado</th>
          <th class="text-center text-nowrap">Cidade</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let alert of data">
          <td class="text-center">{{ alert.date }}</td>
          <td class="text-center">{{ alert.cause }}</td>
          <td class="text-center">{{ alert.state }}</td>
          <td class="text-center">{{ alert.city }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>