import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-confirmation-password',
  templateUrl: './confirmation-password.component.html',
  styleUrls: ['./confirmation-password.component.scss']
})
export class ConfirmationPasswordComponent implements OnInit {

  token: string | null = null;
  confirmationMessage: string = '';
  confirmationSubmessage: string = '';
  confirmationSuccess: boolean = false;
  isLoading: boolean = true;
  publisherUrl;

  constructor(private route: ActivatedRoute, private http: HttpClient) {
    this.publisherUrl = environment.publisherUrl;
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.confirmPassword();
  }

  confirmPassword(): void {
    this.isLoading = true;

    if (this.token) {
      this.http.get(`${this.publisherUrl}/publisher/confirmResetPassword/${this.token}`)
        .subscribe(
          (response) => {
            this.confirmationSuccess = true;
            this.confirmationMessage = 'Senha atualizada com sucesso!';
            this.confirmationSubmessage = 'Sua senha foi atualizada com sucesso. Você pode agora fazer login com sua nova senha.';
            this.isLoading = false;
          },
          (error) => {
            this.confirmationSuccess = false;
            this.confirmationMessage = 'Falha ao atualizar a senha.';
            this.confirmationSubmessage = 'Por favor, tente novamente mais tarde.';
            this.isLoading = false;
          }
        );
    }
  }
}
