import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { IColors } from 'src/app/interfaces';
import { ThemeService } from 'src/app/services/theme.service';
import { AnalyticalHistogram } from 'src/app/interfaces/dtos/AnalyticalHistogram';
import { FilterService, Namespace } from 'src/app/services/filter.service';
import { environment } from 'src/environments/environment';
import { catchError, debounceTime, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-histogram-vision',
  templateUrl: './histogram-vision.component.html',
  styleUrls: []
})
export class HistogramVisionComponent implements OnInit {
  
  NAMESPACE: Namespace = 'analytical';
  data: BehaviorSubject<AnalyticalHistogram.Output> = new BehaviorSubject(new AnalyticalHistogram.Output);
  loading: boolean = true;
  error: boolean = false;
  onChange: any;
  barColors: string[];
  colors: string[];
  primaryColor: string = '#ff0000';
  secondaryColor: string = '#000000';
  
  // PROPRIEDADES PARA CALCULO DA PAGINA
  isMobile: boolean;
  heightImg = 70;
  heightPage: number;
  heightContainer: number;
  heightRows: number;
  heightCard: number;
  heightCardFull: number;
  
  constructor(
    private themeService: ThemeService,
    private filterService: FilterService,
    private httpService: HttpClient
  ) {
    this.barColors = this.themeService.generateColorGradient(this.primaryColor, this.secondaryColor, 4);
  
    this.filterService.getFiltersObservable<AnalyticalHistogram.InputParams>({
      namespace: this.NAMESPACE
    })
      .pipe(debounceTime(1000))
      .subscribe((af) => this.updateData(af));
  }
  
  ngOnInit(): void { 
  }

  updateData(activeFilters) {
    this.loading = true;
    const baseUrl = environment.baseUrl;
    const mappedFilters = {
      "years": activeFilters.year,
      "months": activeFilters.month,
      "days": activeFilters.day,
      "causes": activeFilters.causeGroup,
      "regionals": activeFilters.regional,
      "directors": activeFilters.group,
      "states": activeFilters.state,
      "subClusters": activeFilters.subcluster,
      "clusters": activeFilters.cluster,
      "cities": activeFilters.city,
      "families": activeFilters.family,
      "nets": activeFilters.net
    }
    this.httpService
      .post<AnalyticalHistogram.Response>(`${baseUrl}/vandalism/analytical/histogram`, mappedFilters)
      .pipe(
        catchError(() => {
          this.loading = false;
          this.error = true;
          return of(null);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response) => {
        this.loading = false;
        this.data.next(new AnalyticalHistogram.Output(response))
      })
  }

}
