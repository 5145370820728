import { initialAnalyticalChart } from "src/app/utils/constants";
import { AnalyticalChart } from "../models/IAnalyticalChart";

export namespace AnalyticalRegionalComparison {

  export type ViewScreen = 'regional-comparison';

  export interface InputParams {
    years: string,
    months: string,
    days: string,
    causes: string,
    regionals: string,
    directors: string,
    states: string,
    subClusters: string,
    clusters: string,
    cities: string,
    families: string,
    nets: string
  }

  export interface Response {
    analyticalVolumeByRegional: AnalyticalChart,
    analyticalVolumeByGroup: AnalyticalChart,
    analyticalVolumeByCluster: AnalyticalChart,
    analyticalVolumeBySubCluster: AnalyticalChart,
  }

  export class Output {
    
    public analyticalVolumeByRegional = initialAnalyticalChart
    public analyticalVolumeByGroup = initialAnalyticalChart
    public analyticalVolumeByCluster = initialAnalyticalChart
    public analyticalVolumeBySubCluster = initialAnalyticalChart
  
    constructor(response?: Response) {
      if (response) {
        this.analyticalVolumeByRegional = response.analyticalVolumeByRegional;
        this.analyticalVolumeByGroup = response.analyticalVolumeByGroup;
        this.analyticalVolumeByCluster = response.analyticalVolumeByCluster;
        this.analyticalVolumeBySubCluster = response.analyticalVolumeBySubCluster;
      }
    }
  }
}