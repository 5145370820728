<div class="widget-chart widget-chart2 text-start mb-1 card-btm-border card-shadow-primary border-primary card">
  <div class="widget-chat-wrapper-outer">
    <div class="widget-chart-content">
      <div class="title-card opacity-5 text-uppercase" style="text-align: center;">{{ cardTitle }}</div>
      <div class="widget-numbers mt-2 fsize-3 mb-0 w-100">
        <div class="widget-chart-flex align-items-center">
          <span class="opacity-10 pe-2">
            <fa-icon [icon]="icon"></fa-icon>
          </span>
          <span 
            class="value-card" 
            style="max-width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          >
            {{ cardValue }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
