<div class="row">
  <div class="header-page d-none d-lg-block">
		<div class="row h-20 selects-row" style="margin-left: 2vw">
			<div class="col-lg-2 col-md-2">
        <app-select-default 
				 	key="families"
					title="Família"
          [icon]="icons.building"
          [dropdownList]="data.value?.dropdownList?.families"
          [selectedItems]="data.value?.selectedList?.families"
					[itemsShowLimit]="2"
          (event)="onChange($event)"
        >
        </app-select-default>
			</div>
	
			<div class="col-lg-2 col-md-2">
				<app-select-default
					key="directors"
					title="Diretorias"
					[searchFilter]="true"
					[dropdownList]="data.value?.dropdownList?.directors"
					[selectedItems]="data.value?.selectedList?.directors"
					(event)="onChange($event)"  
				>
				</app-select-default>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-select-default
					key="states"
					title="Estados"
					[searchFilter]="true"
					[dropdownList]="data.value?.dropdownList?.states"
					[selectedItems]="data.value?.selectedList?.states"
					(event)="onChange($event)"  
				>
				</app-select-default>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-select-default
					key="cities"
					title="Cidades"
					[searchFilter]="true"
					[dropdownList]="data.value?.dropdownList?.cities"
					[selectedItems]="data.value?.selectedList?.cities"
					(event)="onChange($event)"  
				>
				</app-select-default>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-select-default
					key="routeId"
					title="Rotas"
					[searchFilter]="true"
					[dropdownList]="getRouteName(data.value?.dropdownList)"
					[itemsShowLimit]="2"
          isSingleSelection="true"
					(event)="onChange($event)"
				>
				</app-select-default>
			</div>
		</div>

		<div class="row cards-row px-0">
		
			<div class="col-lg-1 col-md-1"></div>
			
			<div class="col-lg-2 col-md-2">
				<app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
					<app-card-default
						[icon]="icons.clock"
						[cardTitle]="'Início'"
						[cardValue]="data.value?.getRouteCard(isRouteSelected())?.expected.start"
						[isLargeFont]="false"
					>
					</app-card-default>
				</app-request-wrapper>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
					<app-card-default
						[icon]="icons.clock"
						[cardTitle]="'Final'"
						[cardValue]="data.value?.getRouteCard(isRouteSelected())?.expected.end"
						[isLargeFont]="false"
					>
					</app-card-default>
				</app-request-wrapper>
			</div>

			<div class="col-lg-3 col-md-3">
				<div style="max-height: 100%; max-width: 100%; padding: 1%">
					<app-request-wrapper
						[class]="requestWrapperConfig.cardStyle"
						[isLoading]="loading"
						[isError]="error"
					>
						<app-card-score
							[scoreNumber]="data.value?.getRouteMap()?.score"
						>
						</app-card-score>
					</app-request-wrapper>
				</div>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
					<app-card-default
						[icon]="icons.clock"
						[cardTitle]="'Início'"
						[cardValue]="'Informação indisponível'"
						[isLargeFont]="false"
					>
					</app-card-default>
				</app-request-wrapper>
			</div>

			<div class="col-lg-2 col-md-2">
				<app-request-wrapper
					[class]="requestWrapperConfig.cardStyle"
					[isLoading]="loading"
					[isError]="error"
				>
					<app-card-default
						[icon]="icons.clock"
						[cardTitle]="'Final'"
						[cardValue]="'Informação indisponível'"
						[isLargeFont]="false"
					>
					</app-card-default>
				</app-request-wrapper>
			</div>
		</div>

		<div class="row h-10 selects-row" style="margin-left: 2vw">
			<div
				class="col-lg-3 col-md-3"
				style="
					padding: 5px;
					display: flex;
				"
			>
				<button
					class="styled-button red-button"
					(click)="createRouteToa()"
					style="margin-right: 10px;" 
				>
					Enviar para o Toa
				</button>

				<button 
					class="styled-button gray-button"
					(click)="openMaps()"
				>
					Enviar para o Mapa
				</button>
			</div>
		</div>
	</div>

	<div class="row mx-auto px-0" style="height: 100vh;">
		<div class="row" style="margin-bottom: 0.5vh;">
			<div class="col-lg-12 col-md-12">
        <app-request-wrapper
          [class]="requestWrapperConfig.cardChart"
          [isLoading]="loading"
          [isError]="error"
          [isCustomContent]="submit"
          [customContent]="submitContent"
        >
				<div class="col-12">
						<app-route-map
              [title]="'MAPA DE RONDA ESPERADA/REALIZADA'"
              [data]="data.value?.getRouteMap()"
            >
						</app-route-map>
          </div>
        </app-request-wrapper>
			</div>
		</div>

		<div class="row  mx-auto px-0">
			<div class="row" style="padding: 26px">
				<div class="col-lg-12 col-md-12" *ngIf="!submit && !error">
					<app-route-table
						[title]="'TABELA DE RONDA ESPERADA'"
						[data]="data.value?.getRouteTable()"
					>
					</app-route-table>
				</div>
			</div>
		</div>
	</div>
</div>
