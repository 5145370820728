import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorPopUpComponent } from 'src/app/pages/auth/login/components/error-pop-up/error-pop-up.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PolicyPopUpComponent } from 'src/app/components/policy-pop-up/policy-pop-up.component';
import { UserInfoPopUpComponent } from 'src/app/components/user-info-pop-up/user-info-pop-up.component';
import { UserField, UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  
  form: FormGroup;
  showPassword: boolean = false;
  password: string = '';
  loading: boolean;
  submitted: boolean;
  error: boolean;
  returnUrl: string;

  slideConfig2 = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    speed: 500,
    dots: true,
  };
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private userService: UserService
    ) {
      this.form = this.formBuilder.group({
        username: ['', [Validators.required, Validators.minLength(4)]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        companyCode: ['', [Validators.required, Validators.minLength(3)]]
      });
    
    if (this.authService.getCurrentUser()) { 
      this.router.navigate(['/vandalismo/analitico']);
    }
  }
  
  onInputChange(event: string) {
    // Ação quando o valor do input é alterado
    // Você pode implementar lógica adicional aqui, se necessário
  }
  
  open(statusCode: number) {
    const modalRef = this.modalService.open(ErrorPopUpComponent, {
      windowClass: 'test'   
    });

    modalRef.componentInstance.statusCode = statusCode;
  }
  
  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/vandalismo/analitico';
  }
  
  getUsername() {
    return this.form.controls.username.value
  }
  
  getPassword() {
    return this.form.controls.password.value
  }

  getCode() {
    return this.form.controls.companyCode.value
  }
  
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  
  onSubmit(e) {
    e.preventDefault();

    this.submitted = true;

    if (this.form.invalid) {
      this.error = true;
      return;
    }

    const credentials = {
      username: this.getUsername(),
      password: this.getPassword(),
      companyCode: this.getCode(),
    }

    this.loading = true;
    this.authService
      .login(credentials)
      .pipe(first())
      .subscribe(
        (data) => { 
          this.router.navigate([this.returnUrl]);
          this.checkPendingChanges();
          this.openPopUpResponse();
        },
        (error) => {
          this.open(error.status);
          this.error = true;
          this.loading = false;
        });
  }

  checkPendingChanges() {
    if (this.authService.isLoggedIn()) {
      const { id } = this.authService.getCurrentUser();

      this.userService.getUserInfo(id).subscribe((response) => {
        if (response.data !== null) {
          const fields: UserField[] = response.data;
          const modalRef = this.modalService.open(UserInfoPopUpComponent);
          modalRef.componentInstance.fields = fields;    
        }    
      });

      if (!this.authService.getCurrentUser().policy) {
        this.userService.getPolicy().subscribe((response) => {
          if (response.data !== null) {
            const modalRef = this.modalService.open(PolicyPopUpComponent, { backdrop: 'static' });
            modalRef.componentInstance.policy = response.data;
          }
        })
      }
    }
  }

  openPopUpResponse() {
    const closeButtonMessage = 'Fechar';
    const snackBarSettings: MatSnackBarConfig = {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-bar-container'
    }
    this.snackBar.open('Ao acessar o FIDERE, você sabe e aceita que sua navegação pelo sistema será monitorada para fins de garantir a segurança das informações.', closeButtonMessage, snackBarSettings);
  }
}