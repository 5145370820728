import { Component, OnInit, Input } from '@angular/core';
import { LegendItem, LegendType } from 'src/app/interfaces/models/ILegendItem';

@Component({
  selector: 'app-legend-item',
  templateUrl: './legend-item.component.html',
  styleUrls: []
})
export class LegendItemComponent<T> implements OnInit {

  @Input() legend: LegendItem<T>;

  private mappedType: Record<LegendType, string> = {
    [LegendType.CIRCLE]: 'circle-symbol-legend',
    [LegendType.TRIANGLE]:'triangle-symbol-legend'
  }

  private mappedColor: Record<string, string> = {
    'red': 'red-background-color',
    'black': 'black-background-color',
    'green': 'green-background-color'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
