import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-response-pop-up',
  templateUrl: './response-pop-up.component.html',
  styleUrls: []
})
export class ResponsePopUpComponent implements OnInit {

  @Input() isSuccess: boolean;

  success = {
    icon: 'fa fa-check',
    title: 'Senha trocada com sucesso',
    class: 'icon-box-success'
  }

  error = {
    icon: 'fa fa-times',
    title: 'Erro ao realizar a solicitação',
    class: 'icon-box'
  }

  constructor(public activeModal: NgbActiveModal) { }

  close(result: any) {
    this.activeModal.close(result);
  }

  ngOnInit(): void {
  }
}
