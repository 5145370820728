import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { months } from 'src/app/utils/constants';

@Component({
  selector: 'app-ocurrences-comparision-by-years-bar',
  templateUrl: './ocurrences-comparision-by-years-bar.component.html',
  styleUrls: []
})
export class OcurrencesComparisionByYearsBarComponent implements OnInit, OnChanges {

  
  @Input() data: { datasets: number[][], legends: string[] };

  colors: string[];

  barChartData = { datasets: [], labels: [], legends: [] };

  type: ChartType = 'bar';
  options: ChartOptions = {
    layout: {
      padding: { top: 20 }
    },
    tooltips: {
      enabled: false
    },
    scales: {      
      xAxes: [{
        gridLines:{
          display:false
        },             
      }],
      yAxes: [
        {          
          position: 'left',
          ticks:{
            maxTicksLimit:6,
            display:false
          },
          gridLines:{
            display: false,
          }
        },     
      ]
    },     
    plugins: {
      datalabels:{
        display: true,
        padding: 0,
        color: '#555',
        align: 'end',
        anchor: 'end',
        font: {
          weight: 'bold',
          size: 10
        }
      },
    },
  }

  constructor() { }
  
  ngOnInit(): void {
    this.updateColors();
    this.createBarChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateColors();
    this.createBarChart();
  }

  updateColors() {
    this.colors = this.generateColorGradient('#ff0000', '#000000', this.data.datasets.length + 1);
  }

  generateColorGradient(startColor, endColor, steps) {
    function hexToRgb(hex) {
      let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  
    function rgbToHex(r, g, b) {
      return "#" + [r, g, b].map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      }).join('');
    }
  
    let startRgb = hexToRgb(startColor);
    let endRgb = hexToRgb(endColor);
    let colorArray = [];
  
    for (let i = 0; i < steps; i++) {
      let r = Math.round(startRgb.r + (endRgb.r - startRgb.r) * (i / (steps - 1)));
      let g = Math.round(startRgb.g + (endRgb.g - startRgb.g) * (i / (steps - 1)));
      let b = Math.round(startRgb.b + (endRgb.b - startRgb.b) * (i / (steps - 1)));
      colorArray.push(rgbToHex(r, g, b));
    }
  
    return colorArray;
  }
  
  createBarChart(){
    const bars = this.data.datasets.map((dataset, index) => {

      return {
        data: dataset ?? [],
        type: 'bar',
        backgroundColor: this.colors[index],
        borderColor: this.colors[index],
      };
    });
    
    this.barChartData = { datasets: bars, labels: months, legends: this.data.legends };    
  }; 
}
